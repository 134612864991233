import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const getDistributedRentFunction = createAsyncThunk(
  "token/sell",
  async (id, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getDistributedRent(id);
      return response.data.getRent;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPendingRent = createAsyncThunk(
  "/getPendingRent",
  async (_, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getPendingRent();
      return response.data.getRent;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributedRentdetailsFunction = createAsyncThunk(
  "token/getDistributedRentdetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getDistributedRentdetails();
      return response.data.getRentDetails;
    } catch (err) {
      alert("slice failed");
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClimedRent = createAsyncThunk(
  "token/getClaimedRent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getClaimedRent();
      return response.data.getRentDetails;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);


const initialState = {
  rentDetails: [],
  userPendingRent:[],
  rentCompletedPayment:[],
  claimedRent:[],
  error: "",
  loading: false,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: {
    [getDistributedRentFunction.pending]: (state) => {
      state.loading = true;
    },
    [getDistributedRentFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentDetails = action.payload;
    },
    [getDistributedRentFunction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getPendingRent.pending]: (state) => {
      state.loading = true;
    },
    [getPendingRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.userPendingRent = action.payload;
    },
    [getPendingRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getDistributedRentdetailsFunction.pending]: (state) => {
      state.loading = true;
    },
    [getDistributedRentdetailsFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentCompletedPayment = action.payload;
    },
    [getDistributedRentdetailsFunction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getClimedRent.pending]: (state) => {
      state.loading = true;
    },
    [getClimedRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.claimedRent = action.payload;
    },
    [getClimedRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default tokenSlice.reducer;
