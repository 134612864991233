import React, { useEffect, useState } from "react";
import { Spinner, UserSideBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getDistributedRentFunction } from "../redux/features/rentSlice";
import DistributedRentDetails from "../components/DistributedRentDetails";

const Documents = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    var Id = JSON.parse(window.localStorage.getItem("profile")).id;

    dispatch(getDistributedRentFunction(Id));
  }, [dispatch]);

  const [year, setYear] = useState("2022");
  const [filteredRent, setFilteredRent] = useState([]);

  const selectedDateChange = (e) => {
    let yearDate = e.target.value;

    setFilteredRent([]);

    for (let i = 0; i < rentDetails.length; i++) {
      let date = new Date(rentDetails[i].createdAt);
      const dateYear = date.getFullYear();

      if (dateYear == yearDate) {
        setFilteredRent((filteredRent) => [...filteredRent, rentDetails[i]]);
      }
    }
    setYear(year);
  };

  const { rentDetails, loading } = useSelector((state) => state.rent);
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                My <strong>Documents</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />

            <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
              <select
                onChange={selectedDateChange}
                className="w-72 border text-black p-3 2xl:text-xl"
                style={{ float: "right", margin: "5px" }}
              >
                <option selected disabled>
                  {" "}
                  Select Year For Rent Report
                </option>
                <option value="2022"> 2022</option>
                <option value="2023"> 2023</option>
                <option value="2024"> 2024</option>
              </select>

              <div id="tabContentExample">
                <div className="table-layout tableBox">
                  <table className="table-fixed w-full">
                    <thead>
                      <tr className="bg-blue-900">
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Order
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Date
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Status
                        </th>
                        <th className="text-white 1xl:text-md xl:text:md text-md p-3">
                          Total Rent
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Transaction Hash
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3"></th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3"></th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3"></th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3"></th>

                      </tr>
                    </thead>
                    <tbody>
                      {rentDetails.length === 0 && <h1>No Orders found</h1>}

                      {filteredRent &&
                        filteredRent.map((item) => (
                          <DistributedRentDetails key={item.id} item={item} />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documents;
