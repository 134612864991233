import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createEmail, editEmail } from "../../redux/features/emailSlice";
import { Spinner } from "../../components";
import { Editor } from "@tinymce/tinymce-react";
const initialState = {
  subject: "",
  content: "",
  type: "",
};
const { REACT_APP_TINYMCE_API } = process.env;

const NewEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const { emailId } = useParams();
  const [emailData, setEmailData] = useState(initialState);
  const { error, loading, emails } = useSelector((state) => ({
    ...state.email,
  }));

  const content = emailData?.content ? emailData?.content : "Write something.";

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailData.content = text;
    const formdata = { ...emailData };
    if (emailId) {
      dispatch(editEmail({ emailId, formdata, navigate, toast }));
    } else {
      dispatch(createEmail({ formdata, navigate, toast, dispatch }));
    }
  };

  useEffect(() => {
    let singleEmail;
    if (emailId) {
      singleEmail = emails?.find(
        (email) => parseInt(email.id) === parseInt(emailId)
      );
      setText(singleEmail?.content);
      setEmailData({ ...singleEmail });
    }
  }, [emailId, emails]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div></div>

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    onChange={onInputChange}
                    value={emailData.subject}
                    required
                  />
                </div>

                <div className="formInput">
                  <label>Type</label>
                  <select
                    name="type"
                    id="type"
                    onChange={onInputChange}
                    value={emailData.type}
                    required
                  >
                    <option value="">Select</option>
                    <option value="welcomeemail">Welcome Email</option>
                    <option value="forgotpassword">Forgot password </option>
                    <option value="promotional">Promotional Email</option>
                    <option value="rentdistribution">
                      Rent Distribution Email
                    </option>
                    <option value="Successpayment">
                      Success Payment Email
                    </option>
                    <option value="agreementsignature">
                      Agreement Signature Email
                    </option>
                    <option value="newproperty"> New Property Email </option>
                    <option value="orderreceipt"> Order Receipt </option>
                    <option value="refundorderpayment"> Refund Order Payment </option>
                    <option value="rentgeneration"> Rent Generation Emails</option>
                  </select>
                </div>

                <div className="formInput">
                  <label>Content</label>
                  <textarea
                    name="content"
                    id="content"
                    rows={5}
                    cols={40}
                    type="text"
                    value={emailData.content}
                    style={{ display: "none" }}
                  />
                  <Editor
                    apiKey={REACT_APP_TINYMCE_API}
                    init={{
                      selector: "textarea",
                      height: 500,
                      menubar: false,
                      plugins: [
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    }}
                    initialValue={content}
                    outputFormat="text"
                    onEditorChange={(newText) => setText(newText)}
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Update</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewEmail;
