import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../faqSidebar.css";
import { getFaqById } from "../../../../redux/features/faqSlice";

const BlockChain = () => {
  const navigate = useNavigate();
  const { faqId } = useParams();
  const dispatch = useDispatch();
  const { faqs, faq } = useSelector((state) => ({
    ...state.faq,
  }));
  useEffect(() => {
    dispatch(getFaqById(faqId));
  }, [dispatch, faqId, faqs]);

  const { next, previous, getFaq } = faq;

  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>{getFaq?.[0]?.question}</h2>
            <div className="content-inner">
              <section
                dangerouslySetInnerHTML={{ __html: getFaq?.[0]?.answer }}
                className="SearchResult-body"
              />
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate(`/faq/${previous}`)}
                    className="button-custom"
                  >
                    <p>Previous FAQ</p>
                    <i className="fas fa-arrow-left"></i>
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate(`/faq/${next}`)}
                    className="button-custom left-btn-last"
                  >
                    <p>Next FAQ</p>
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlockChain;
