import React from "react";
import { Link } from "react-router-dom";

const SimpleHeader = () => {
  return (
    <div className="bg-black w-full z-50">
      <div className="container mx-auto">
        <div className="flex justify-between items-center border-b-0 border-gray-100 py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start 2xl:w-96">
            <Link to="/">
              <img
                className="h-16 w-auto sm:h-16"
                src="assets/images/register-logo.png"
                alt=""
              />
            </Link>
          </div>
          <div className="Menu-translator md:flex space-x-10 2xl:order-3 xl:order-3 lg:order-3 md:order-3 sm:order-3 order-3">
            <div id="google_translate_element"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleHeader;
