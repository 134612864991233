import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createProperty,
  updateProperty,
  getProperty,
  deletePropertyImage,
} from "../../redux/features/propertySlice";
import { Spinner } from "../../components";
import { Editor } from "@tinymce/tinymce-react";

const AddProperty = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [file, setFile] = useState("");
  const [showImg, setshowImg] = useState(true);
  const [text, setText] = useState("");
  const [Neighbour, setNeighbour] = useState("");
  const [isPropertyProcessing, setisPropertyProcessing] = useState(false);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };

  const { REACT_APP_TINYMCE_API } = process.env;

  const { error, loading, property } = useSelector((state) => ({
    ...state.property,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    setisPropertyProcessing(true);
    dispatch(createProperty({ formData, navigate, toast }));
  };

  const deleteImages = async (id) => {
    dispatch(deletePropertyImage(id));
    navigate(`/admin/properties/${id}`);
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const updateImgShow = (e) => {
    setFile(e.target.files[0]);
    setshowImg(false);
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="bottom">
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>General Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="left">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw-ETMC23xkCA0zFh6AKf_T5tM1JksNCtSTQ&usqp=CAU"
                    }
                    alt=""
                  />
                </div>
                <div className="right editprofile">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="formInput">
                      <div>
                        <label>
                          + Add Main Image
                          <br />
                          <input
                            type="file"
                            name="PropertyAvatar"
                            accept="image/png , image/jpeg, image/webp"
                            style={{ display: "none" }}
                            onChange={updateImgShow}
                          />
                        </label>
                        <br />

                        <label>
                          + Add Images
                          <br />
                          <input
                            type="file"
                            name="PropertyImage"
                            multiple
                            onChange={onSelectFile}
                            accept="image/png , image/jpeg, image/webp"
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                      <div className="images uploaded-images">
                        {selectedImages &&
                          selectedImages.map((image) => {
                            return (
                              <div key={image} className="image">
                                <img src={image} alt="upload" />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="formHeading">
                      <p>PROPERTY BACKGROUND: </p>
                    </div>

                    <div className="formInput">
                      <label>Property Address</label>
                      <input
                        type="text"
                        placeholder="Property Address"
                        name="PropertyAddress"
                        id="PropertyAddress" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Property Type</label>
                      <input
                        type="text"
                        placeholder="Property Type"
                        name="PropertyType"
                        id="PropertyType" required
                      />
                    </div>
                    <div className="formInput">
                      <label>
                        ANNUAL INCOME (without capital appreciation)
                      </label>
                      <input
                        type="number"
                        placeholder="Annual Income"
                        name="AnnualIncome"
                        id="AnnualIncome"
                        step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Neighbourhood</label>
                      <input
                        type="text"
                        placeholder="Neighbourhood"
                        name="Neighbourhood"
                        id="Neighbourhood" required
                      />
                    </div>
                    <div className="formInput">
                      <label>CAPITAL APPRECIATION (average estimated)</label>
                      <input
                        type="number"
                        placeholder="CapitalAppreciation"
                        name="CapitalAppreciation"
                        id="CapitalAppreciation"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Construction Year</label>
                      <input
                        type="number"
                        placeholder="Construction Year"
                        name="ConstructionYear"
                        id="ConstructionYear"
                        step="any" required
                        min="0"
                      />
                    </div>
                    <div className="formInput">
                      <label>Total Investment (in US $)</label>
                      <input
                        type="number"
                        placeholder="Total Price"
                        name="TotalPrice"
                        id="TotalPrice"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Size (in sqm)</label>
                      <input
                        type="number"
                        placeholder="Size"
                        name="LotSize"
                        id="LotSize"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>
                        Total <span translate="no"> SPACIOS BRICK </span>{" "}
                        <span translate="no"> Tokens </span>
                        issues
                      </label>
                      <input
                        type="number"
                        placeholder="Total Tokens"
                        name="TotalTokens"
                        id="TotalTokens"
                        min="0" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Lease Contract</label>
                      <input
                        type="text"
                        placeholder="Lease Contract"
                        name="LeaseContract"
                        id="LeaseContract"
                        min="0" required
                      />
                    </div>

                    <div className="formInput">
                      <label>
                        Passive Cash Flow per 1{" "}
                        <span translate="no"> Token </span> (annually)
                      </label>
                      <input
                        type="number"
                        placeholder="Passive Cash Flow"
                        name="PassiveCashFlow"
                        id="PassiveCashFlow"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Territorial Taxes</label>
                      <input
                        type="number"
                        placeholder="Territorial Taxes"
                        name="TerritorialTaxes"
                        id="TerritorialTaxes"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>
                        Capital Gain Incorporated in 1{" "}
                        <span translate="no"> Token </span> (annually)
                      </label>
                      <input
                        type="number"
                        placeholder="Capital Gain Incorporated"
                        name="CapitalGainIncorporated"
                        id="CapitalGainIncorporated"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>kitchen</label>
                      <input
                        type="number"
                        placeholder="kitchen"
                        name="kitchen"
                        id="kitchen"
                        min="0" required
                      />
                    </div>

                    <div className="formInput">
                      <label>
                        Max <span translate="no"> Token </span>
                      </label>
                      <input
                        type="number"
                        placeholder="Max Token"
                        name="MaxToken"
                        id="MaxToken"
                        min="0" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Bathroom</label>
                      <input
                        type="number"
                        placeholder="Bathroom"
                        name="Bathroom"
                        id="Bathroom"
                        min="0" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Common expenses (tenant expense in US $)</label>
                      <input
                        type="number"
                        placeholder="Common Expenses"
                        name="CommonExpenses"
                        id="CommonExpenses"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Balcony</label>
                      <input
                        type="number"
                        placeholder="Balcony"
                        name="Livingroom"
                        id="Livingroom"
                        min="0"  required
                      />
                    </div>
                    <div className="formInput">
                      <label>Common expenses (tenant expense in CLP)</label>
                      <input
                        type="number"
                        placeholder="Common Expenses"
                        name="CommonExpensesClp"
                        id="CommonExpensesClp"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Bedroom</label>
                      <input
                        type="number"
                        placeholder="Bedroom"
                        name="Bedroom"
                        id="Bedroom"
                        min="0"
                      />
                    </div>
                    <div className="formInput">
                      <label>Total Investment (in CLP)</label>
                      <input
                        type="number"
                        placeholder="Total Price"
                        name="TotalPriceClp"
                        id="TotalPriceClp"
                        min="0" step="any"
                      />
                    </div>

                    <div className="formHeading">
                      <p>NUMBERS: </p>
                    </div>
                    <div className="formInput">
                      <label>Gross Rent (US $)</label>
                      <input
                        type="number"
                        placeholder="Gross Rent"
                        name="GrossRent"
                        id="GrossRent"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Underlying Asset Price (CLP)</label>
                      <input
                        type="number"
                        placeholder="Underlying Asset Price"
                        name="UnderlyingAssetPriceClp"
                        id="UnderlyingAssetPriceClp"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Gross Rent (CLP)</label>
                      <input
                        type="number"
                        placeholder="Gross Rent"
                        name="GrossRentClp"
                        id="GrossRentClp"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Initial Maintenance Reserve (US $)</label>
                      <input
                        type="number"
                        placeholder="Initial Maintenance Reserve"
                        name="InitialMaintenanceReserve"
                        id="InitialMaintenanceReserve"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Property Taxes (US $)</label>
                      <input
                        type="number"
                        placeholder="Property Taxes"
                        name="PropertyTaxes"
                        id="PropertyTaxes"
                        min="0" step="any" required
                      />
                    </div>

                    <div className="formInput">
                      <label>Initial Maintenance Reserve (CLP)</label>
                      <input
                        type="number"
                        placeholder="Initial Maintenance Reserve"
                        name="InitialMaintenanceReserveClp"
                        id="InitialMaintenanceReserveClp"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Property Taxes (CLP)</label>
                      <input
                        type="number"
                        placeholder="Property Taxes"
                        name="PropertyTaxesClp"
                        id="PropertyTaxesClp"
                        min="0" step="any" required
                      />
                    </div>
                    <div className="formInput">
                      <label>Insurance (US $)</label>
                      <input
                        type="number"
                        placeholder="Insurance"
                        name="Insurance"
                        id="Insurance"
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Underlying Asset Price (US $)</label>
                      <input
                        type="number"
                        placeholder="Underlying Asset Price"
                        name="UnderlyingAssetPrice"
                        id="UnderlyingAssetPrice"
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Insurance (CLP)</label>
                      <input
                        type="number"
                        placeholder="Insurance"
                        name="InsuranceClp"
                        id="InsuranceClp"
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Details Property </label>
                      <textarea
                        rows={10}
                        cols={75}
                        type="text"
                        placeholder="Details Property"
                        name="DetailsProperty"
                        id="DetailsProperty"
                        style={{ display: "none" }}
                        value={text} 
                      />
                      <Editor
                        apiKey={REACT_APP_TINYMCE_API}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menubar: false,
                          plugins: [
                            "a11ychecker",
                            "advlist",
                            "advcode",
                            "advtable",
                            "autolink",
                            "checklist",
                            "export",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "powerpaste",
                            "fullscreen",
                            "formatpainter",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        }}
                        outputFormat="text"
                        onEditorChange={(newText) => setText(newText)}
                      />
                    </div>
                    <div className="formInput">
                      <label>Neighbour</label>
                      <textarea
                        rows={10}
                        cols={75}
                        type="text"
                        placeholder="Neighbour"
                        name="Neighbour"
                        id="Neighbour"
                        value={Neighbour}
                        style={{ display: "none" }}
                      />
                      <Editor
                        apiKey={REACT_APP_TINYMCE_API}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menubar: false,
                          plugins: [
                            "a11ychecker",
                            "advlist",
                            "advcode",
                            "advtable",
                            "autolink",
                            "checklist",
                            "export",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "powerpaste",
                            "fullscreen",
                            "formatpainter",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        }}
                        outputFormat="text"
                        onEditorChange={(newText) => setNeighbour(newText)}
                      />
                    </div>
                    <div className="formInput">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <button className="btnsubmit">Create Property</button>
                    )}
                    </div>
                  </form>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AddProperty;
