import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchFee } from "../../redux/features/feeSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { formatPercentage, formatNumber } from "../../utils/Formatter";

const Fees = () => {
  const dispatch = useDispatch();

  const { fees } = useSelector((state) => ({
    ...state.fee,
  }));

  const FeeColumns = [
    {
      field: "feeName",
      headerName: "Fee Name",
      width: 250,
    },
    {
      field: "percent",
      headerName: "Percent",
      width: 250,
      valueGetter: (params) =>
        `${formatPercentage(params?.row?.percent)}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/fee/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchFee());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={fees ? fees : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={FeeColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Fees;
