import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../components/";
import { createCheckout } from "../redux/api";
import { PAYMENT_OPTION, TERMSCONDITION } from "../constants/Messages";
import Web3 from "web3";
import { formatNumber } from "../utils/Formatter";
import Loader from "../components/admin/Loader";
import { fetchCart } from "../redux/features/cartSlice";
import metaMaskLogo from "../assets/images/metamask-logo.png";

const initialState = {
  FirstName: "",
  LastName: "",
  Email: "",
  Phone: "",
  BillingCountry: "",
  BillingCity: "",
  BillingStreet: "",
  BillingState: "",
  BillingPostalCode: "",
};
const { REACT_APP_ADMMIN_WALLET_ADDRESS } = process.env;
const Checkout = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [accountAddress, setAccountAddress] = useState("");
  const [card, setCard] = useState(false);
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    email,
    billingCountry,
    billingCity,
    billingStreet,
    billingState,
    phone,
    billingPostalCode,
  } = formValue;

  const { user, loading, error } = useSelector((state) => ({ ...state.auth }));

  const handleChange = (event) => {
    if (event.target.checked) {
    } else {
    }
    setIsSubscribed((current) => !current);
  };

  useEffect(() => {
    if (user && user.role !== 1) {
      dispatch(fetchCart());
    }
    if (!user?.isDocsVerified) {
      toast.error("You can not buy tokens because your documents are not verified yet, If verified please login again!");
    }
    const waddress = localStorage.getItem("accountAddress");
    setAccountAddress(waddress);
  }, [dispatch,user]);

  useEffect(() => {
    if (user) {
      setFormValue({ ...user });
    }
  }, [user, dispatch]);

  const { carts, cartTotal, cartQuantity } = useSelector((state) => state.cart);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const checkOutOrder = async (carts) => {
    if (!isSubscribed) {
      return toast.error(TERMSCONDITION);
    }
    if (!window.walletAddress) {
      return toast.error("Please connect Metamask wallet first.");
    }
    if (window.networkChain != "Polygon" && window.networkChain != "Ethereum") {
      return toast.error("Please connect valid crypto network .");
    }
    setisProcessing(true);
    let cartQuantity = carts.reduce((acc, obj) => {
      let a = obj.Tokens;
      return acc + a;
    }, 0);

    let pac = cartTotal.toFixed(2) * 1e6;
    window.TOKENCONTRACT.methods
      .transfer(REACT_APP_ADMMIN_WALLET_ADDRESS, pac.toString())
      .send({ from: window.walletAddress })
      .then((d) => {
        setisProcessing(false);
        toast.success(
          "Payment has been processed succussfuly, updating the records..."
        );
        const userAddress = d?.from;
        const adminAddress = d?.to;
        const transStatus = d?.status;
        const transHash = d?.transactionHash;
        const rawData = JSON.stringify(d);

        let data = {
          carts: carts,
          cartTotal: cartTotal.toFixed(2),
          cartQuantity: cartQuantity,
          userAddress: userAddress,
          adminAddress: adminAddress,
          transStatus: transStatus,
          transHash: transHash,
          rawData: rawData,
          billingCountry: billingCountry,
          billingState: billingState,
          billingStreet: billingStreet,
          billingCity: billingCity,
          billingPostalCode: billingPostalCode,
          networkChain: window.networkChain,
        };

        createCheckout(data)
          .then((response) => {
            toast.success(response.data.message);
            dispatch(fetchCart());
            navigate("/orders");

          })
          .catch((error) => {
            setisProcessing(false);
            console.log(error);
            return toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        toast.error("Transaction has been failed");
        setisProcessing(false);
        console.log(error);
      });
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="container mx-auto">
      <section id="blockchain-main">
        {isProcessing ? (<> <Loader /></>) : (<></>)}
        {/* */}
        <div className=" mx-auto">
          <div className="section-content flex md:flex-row sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col">
            <div className="container mx-auto">
              <form className="bg-white rounded  pt-6 mt-10 pb-8 mb-4">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                    <div className="heading custom px-2">
                      <h1 className="text-2xl md:text-3xl font-medium mb-2">
                        Billing Details.
                      </h1>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          First Name
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="FirstName"
                          name="firstName"
                          onChange={onInputChange}
                          value={firstName || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Last Name
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="LastName"
                          name="lastName"
                          onChange={onInputChange}
                          value={lastName || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Email Address
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="email"
                          id="Email"
                          name="email"
                          onChange={onInputChange}
                          value={email || ""}
                        />
                      </div>

                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Phone
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="Phone"
                          name="phone"
                          onChange={onInputChange}
                          value={phone || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Country
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="BillingCountry"
                          name="billingCountry"
                          onChange={onInputChange}
                          value={billingCountry || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Billing State
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="BillingState"
                          name="billingState"
                          onChange={onInputChange}
                          value={billingState || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Street Address
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="BillingStreet"
                          name="billingStreet"
                          onChange={onInputChange}
                          value={billingStreet || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          Town City
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="BillingCity"
                          name="billingCity"
                          onChange={onInputChange}
                          value={billingCity || ""}
                        />
                      </div>
                      <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="username"
                        >
                          PostCode Zip
                        </label>
                        <input
                          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          id="BillingPostalCode"
                          name="billingPostalCode"
                          onChange={onInputChange}
                          value={billingPostalCode || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                    <div className="heading custom px-2">
                      <h1 className="text-2xl md:text-3xl font-medium mb-2">
                        Additional Information.
                      </h1>
                    </div>
                    <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="username"
                      >
                        Order Notes( Optional)
                      </label>
                      <textarea
                        className="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="Notes about your order"
                        defaultValue={""}
                      />
                    </div>
                    
                    <a className="metamask-wallet-text" href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="-blank">
                      <p>You Need To have an active Metamask Digital <br /> wallet to buy your Spacios Brick
                        <span translate="no"> Tokens </span>
                      </p>
                      <img src={metaMaskLogo} alt="Metamsk" />
                    </a>
                  </div>
                </div>
              </form>
              <div className="">
                <div className=" 2xl:pt-15 xl:pt-10 lg:pt-7 md:pt-8 sm:pt-6">
                  <div className="main-wrapper">
                    <div className="cart-table">
                      <table className="w-100" cellPadding={0} cellSpacing={0}>
                        <tbody>
                          <tr>
                            <td>
                              <table
                                className="w-100 inner-table"
                                cellPadding={0}
                                cellSpacing={0}
                              >
                                <thead>
                                  <tr>
                                    <th className="first-child">Product</th>
                                    <th>SubTotal</th>
                                  </tr>
                                </thead>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                className="w-100 inner-table box-shadow-table"
                                cellPadding={0}
                                cellSpacing={0}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      {carts.map((s) => {
                                        return (
                                          <div className="first-child">
                                            {s?.Card_Detail?.PropertyAddress}
                                          </div>
                                        );
                                      })}
                                    </td>

                                    <td>
                                      ${formatNumber(cartTotal)} for{" "}
                                      {formatNumber(cartQuantity)} <span translate="no"> tokens </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                className="w-100 inner-table border-bottom-cus"
                                cellPadding={0}
                                cellSpacing={0}
                              >
                                <thead>
                                  {/* <tr>
                                                                        <th className="first-child">
                                                                            Credit Card Fees
                                    </th>
                                                                        <th>$3.02</th>
                                                                    </tr> */}
                                </thead>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                className="w-100 inner-table"
                                cellPadding={0}
                                cellSpacing={0}
                              >
                                <thead>
                                  <tr>
                                    <th className="first-child total-font">
                                      Total
                                    </th>
                                    <th className="total-font">
                                      ${formatNumber(cartTotal)}
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mx-auto">
                <div className=" 2xl:pt-15 xl:pt-10 lg:pt-7 md:pt-8 sm:pt-6">
                  <div className="main-wrapper bg-box">
                    <div className="flex flex-wrap">
                      <div className="w-full xl:w-1/1 md:w-1/1 lg:w-1/1 flex items-center  md:my-4">
                        <div className="form-check"></div>
                        <div className="img-icon">
                          <img
                            className="img-icon-img"
                            alt="description"
                            src="assets/images/icons-1.png"
                          />
                        </div>
                        <div className="img-icon">
                          <img
                            className="img-icon-img"
                            alt="description"
                            src="assets/images/icons-2.png"
                          />
                        </div>
                        <div className="img-icon">
                          <img
                            className="img-icon-img"
                            alt="description"
                            src="assets/images/icons-1.png"
                          />
                        </div>
                        <div className="img-icon">
                          <img
                            className="img-icon-img"
                            alt="description"
                            src="assets/images/icons-2.png"
                          />
                        </div>
                      </div>
                      <div
                        className="w-full xl:w-1/1 md:w-1/1 lg:w-1/1   md:mt-4"
                        style={{ display: card ? "block" : "none" }}
                      >
                        <div className="bg-wrapper-form py-4 px-12 rounded">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                              <h5>Pay securely using your credit card.</h5>
                            </div>
                            <div className="w-full md:w-1/1 lg:w-1/1 px-2 mb-4">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="username"
                              >
                                CARD NUMBER *
                              </label>
                              <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username"
                                type="text"
                                placeholder="•••• •••• •••• ••••"
                              />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="username"
                              >
                                Expiration (MM/YY)
                              </label>
                              <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username"
                                type="text"
                                placeholder="MM / YY"
                              />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/2 px-2 mb-4">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="username"
                              >
                                Card Security Code
                              </label>
                              <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username"
                                type="text"
                                placeholder="CSC"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full xl:w-1/1 md:w-1/1 lg:w-1/1 flex items-center  md:mt-4 px-3">
                        <span className="hr-custom" />
                        <p>
                          Your personal data will be used to process your order,
                          support your experience throughout this website, and
                          for other purposes described in our
                          <Link
                            to="/termscondition"
                            style={{ textDecoration: "underline" }}
                          >
                            Terms Condition &amp; Privacy Policy
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="flex  flex-wrap justify-end">
                      <div className="w-full xl:w-1/1 md:w-1/1 lg:w-1/1 w-max   md:mt-4">
                        <div className="form-check bg-blue-max">
                          <input
                            className="form-check-input  rounded-full h-4 w-4 border border-gray-300 bg-white transition duration-200 mt-1 align-top bg-no-repeat bg-center "
                            type="checkbox"
                            value={isSubscribed}
                            onChange={handleChange}
                            name="subscribe"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label inline-block text-right "
                            htmlFor="flexRadioDefault1"
                            style={{ marginLeft: "10px" }}
                          >
                            I HAVE READ AND AGREE TO THE WEBSITE{" "}
                            <Link
                              to="/termscondition"
                              style={{ textDecoration: "underline" }}
                            >
                              Terms Condition &amp; Privacy Policy
                            </Link>
                            {"    "} {"    "} *
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="btn-cart text-right mt-4">
                      {user.isDocsVerified ? (
                        <button
                          className="continue-shop"
                          onClick={() => checkOutOrder(carts)}
                        >
                          Place Orders{" "}
                          {isProcessing ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            ""
                          )}
                        </button>
                      ) : (
                        "You can not buy tokens because your documents are not verified yet, If verified please login again!"
                      )}
                    </div>
                    <div
                      className="btn-cart text-right mt-4 continue-shop"
                      style={{ fontSize: "1px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
