import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createLearn = createAsyncThunk(
  "learn/createLearn",
  async (
    { updatedLearndata, dispatch, navigate, toast },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.createLearn(updatedLearndata);
      toast.success("Learn Added Successfully");
      navigate("/admin/learn");
      dispatch(fetchLearn);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchLearn = createAsyncThunk(
  "learn/fetchLearn",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchLearn();
      return response.data.getLearnBlogs;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLearn = createAsyncThunk(
  "learn/deleteLearn",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteLearn(id);
      // toast.success("Learn Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editLearn = createAsyncThunk(
  "learn/editLearn",
  async (
    { learnId, updatedLearndata, toast, navigate },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.editLearn(updatedLearndata, learnId);
      toast.success("Learn Updated Successfully");
      navigate("/admin/learn");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getLearn = createAsyncThunk(
  "learn/getLearn",
  async (learnId, { rejectWithValue }) => {
    try {
      const response = await api.getLearn(learnId);
      return response.data.getLearnBlogs;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const learnSlice = createSlice({
  name: "learn",
  initialState: {
    learn: {},
    learns: [],
    error: "",
    loading: false,
  },
  reducers: {
    setImage: (state, action) => {
      state.learn = { ...state.learn, imageFile: action.payload.name };
    }
  },
  extraReducers: {
    [createLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [createLearn.fulfilled]: (state, action) => {
      state.loading = false;
      state.learns = [action.payload];
    },
    [createLearn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchLearn.fulfilled]: (state, action) => {
      state.loading = false;
      state.learns = action.payload;
    },
    [fetchLearn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteLearn.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.learns = state.learns.filter((item) => item.id !== id);
      }
    },
    [deleteLearn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [editLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [editLearn.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.learns = state.learns.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editLearn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [getLearn.fulfilled]: (state, action) => {
      state.loading = false;
      state.learn = action.payload;
    },
    [getLearn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default learnSlice.reducer;
