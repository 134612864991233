import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createProperty = createAsyncThunk(
  "property/createProperty",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createProperty(formData);
      if (response.status == 201) {
        toast.success("Property Added Successfully");
        navigate("/admin/properties");
        return response.data;
      } else {
        toast.error("Something wrong try again");
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProperties = createAsyncThunk(
  "property/getProperties",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getProperties();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPropertiesByStatus = createAsyncThunk(
  "property/getPropertiesByStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getPropertiesByStatus();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProperty = createAsyncThunk(
  "property/getProperty",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getProperty(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSellPropertyToken = createAsyncThunk(
  "property/getSellPropertyToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUserSellTokens();
      return response.data.getUsers;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getPropertyData = createAsyncThunk(
  "property/getPropertyData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getPropertyData(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "property/deleteProperty",
  async ({ id, Swal }, { rejectWithValue }) => {
    try {
      const response = await api.deleteProperty(id);
      Swal.fire("Deleted!", "", "success");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePropertyImage = createAsyncThunk(
  "property/deleteImages",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.deletePropertyImages(id);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProperty = createAsyncThunk(
  "property/updateProperty",

  async ({ propertyId, formData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.updateProperty(formData, propertyId);

      navigate("/admin/properties");
      toast.success("Property Updated Successfully");
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePropertyStatus = createAsyncThunk(
  "tour/updatePropertyStatus",
  async ({ id, status, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.updatePropertyStatus(status, id);
      toast.success("Property status Updated Successfully");
      navigate("/");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState: {
    propertyDelete: null,
    property: null,
    properties: [],
    propertiesStatus: [],
    error: "",
    loading: false,
  },
  reducers: {
    setProperty: (state, action) => {
      state.property = action.payload;
    },
  },
  extraReducers: {
    [createProperty.pending]: (state, action) => {
      state.loading = true;
    },
    [createProperty.fulfilled]: (state, action) => {
      state.loading = false;
      state.properties = [action.payload];
    },
    [createProperty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProperties.pending]: (state, action) => {
      state.loading = true;
    },
    [getProperties.fulfilled]: (state, action) => {
      state.loading = false;
      state.properties = action.payload;
    },
    [getProperties.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getPropertiesByStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getPropertiesByStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertiesStatus = action.payload;
    },
    [getPropertiesByStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProperty.pending]: (state, action) => {
      state.loading = true;
    },
    [getProperty.fulfilled]: (state, action) => {
      state.loading = false;
      state.property = action.payload;
    },
    [getProperty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getSellPropertyToken.pending]: (state, action) => {
      state.loading = true;
    },
    [getSellPropertyToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.sellPropertyToken = action.payload;
    },
    [getSellPropertyToken.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteProperty.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteProperty.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.properties = state.properties.filter((item) => item.id !== id);
      }
    },
    [deleteProperty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateProperty.pending]: (state, action) => {
      state.loading = true;
    },
    [updateProperty.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.properties = state.properties.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [updateProperty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updatePropertyStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePropertyStatus.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.properties = state.properties.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [updatePropertyStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getPropertyData.pending]: (state, action) => {
      state.loading = true;
    },
    [getPropertyData.fulfilled]: (state, action) => {
      state.loading = false;
      state.property = action.payload;
    },
    [updatePropertyStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deletePropertyImage.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePropertyImage.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyDelete = action.payload;
    },
    [deletePropertyImage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setProperty } = propertySlice.actions;

export default propertySlice.reducer;
