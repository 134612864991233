import React from "react";
const ResiDential = () => {
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>Is the RealT platform only for residential property?</h2>
            <div className="content-inner">
              <p></p>
            </div>
            <div className="heading-text">
              <h4>
                What is a{" "}
                <span translate="no">
                  {" "}
                  <span translate="no"> tokens </span>{" "}
                </span>
                ?
              </h4>
              <p>
                RealT has chosen residential family properties to start due to
                their attractiveness for international investors.
              </p>
              <p>
                With tokenization, real estate investments can be made
                affordable to nearly anyone. A single{" "}
                <span translate="no"> token </span> for RealT properties costs
                between $50 – $150 per <span translate="no"> token </span>,
                which are the lowest investment minimums the real estate
                industry is able to offer. Traditional pen+paper competitors to
                RealT have $5,000-10,000 investment minimums.
              </p>
              <p>
                Real estate is also a notoriously illiquid industry. By turning
                real estate into digital <span translate="no"> tokens </span>,
                real estate can be accessed by far larger numbers of potential
                buyers, both due to fractionalization of the{" "}
                <span translate="no"> tokens </span> and the potential reach of
                internet-based markets. The larger market participants that
                digital <span translate="no"> tokens </span> are able to reach
                increases the liquidity of real estate. Additionally, financial
                applications on <span translate="no"> Ethereum</span> (and xDai)
                offer solutions to accessing liquidity for valuable tokenized
                assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResiDential;
