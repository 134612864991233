import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/pages/new/new.scss";
import {
  createSocial,
  editSocial,
  socialById,
} from "../../redux/features/socialSlice";
import { Spinner } from "../../components";
import validator from "validator";

const initialState = {
  Link: "",
  Title: "",
};

const EditSocial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socialId } = useParams();
  const [socialData, setSocialData] = useState(initialState);
  const { error, loading, social } = useSelector((state) => ({
    ...state.social,
  }));

  const [validURLMessage, setValidURLMessage] = useState("");

  const urlPatternValidation = (URL) => {
    const regex = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
    return regex.test(URL);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setSocialData({ ...socialData, [name]: value });
  };

  const onURLChange = (e) => {
    const { value } = e.target;
    if (validator.isURL(value)) {
      setValidURLMessage("");
    } else {
      setValidURLMessage("Please enter a Valid URL");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = { ...socialData };
    dispatch(editSocial({ socialId, formData, navigate, toast, dispatch }));

  };

  useEffect(() => {
    setSocialData({ ...social });
  }, [social, socialId]);

  useEffect(() => {
    dispatch(socialById(socialId));
  }, []);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div></div>

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label>Title</label>
                  <input
                    type="text"
                    name="Title"
                    id="Title"
                    onChange={onInputChange}
                    required
                    value={socialData.Title}

                  />
                </div>
                <div className="formInput">
                  <label>Link</label>
                  <input
                    name="Link"
                    id="Link"
                    type="text"
                    onChange={onURLChange}
                    required
                    value={socialData.Link}
                  />
                  <span style={{ color: "red" }}>{validURLMessage}</span>

                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Update</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditSocial;
