import { configureStore } from "@reduxjs/toolkit";

import AuthReducer from "./features/authSlice";
import propertySlice from "./features/propertySlice";
import TeamReducer from "./features/teamSlice";
import LearnReducer from "./features/learnSlice";
import BlogReducer from "./features/blogSlice";
import FaqReducer from "./features/faqSlice";
import cartReducer from "./features/cartSlice";
import orderReducer from "./features/orderSlice";
import subscribeReducer from "./features/subscribeSlice";
import socialReducer from "./features/socialSlice";
import emailReducer from "./features/emailSlice";
import feeReducer from "./features/feeSlice";
import termReducer from "./features/termsSlice";
import dashboardReducer from "./features/dashboardSlice";
import tokenReducer from "./features/tokenSlice";
import rentReducer from "./features/rentSlice";

export default configureStore({
  reducer: {
    auth: AuthReducer,
    property: propertySlice,
    team: TeamReducer,
    learn: LearnReducer,
    blog: BlogReducer,
    faq: FaqReducer,
    cart: cartReducer,
    order: orderReducer,
    subscribe: subscribeReducer,
    social: socialReducer,
    email: emailReducer,
    fee: feeReducer,
    term: termReducer,
    dashboard: dashboardReducer,
    token: tokenReducer,
    rent: rentReducer,
  },
});
