import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { getPropertyData } from "../redux/features/propertySlice";
import { Spinner } from "../components";
import {
  twoPercentage,
  EightPercent,
  montlyProperty,
  montlyPropertyClp,
} from "../utils/PropertyDetailCalculation";
import { formatNumber } from "../utils/Formatter";

const PropertyDetails = () => {
  const [tab, setTab] = useState("background");
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getPropertyData(id));
    }
  }, [id, dispatch]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { REACT_APP_BASE_API_URL, REACT_APP_ETHERSCAN_URL,REACT_APP_POLYGONSCAN_URL } = process.env;

  const { property, loading } = useSelector((state) => ({ ...state.property }));

  const data = property?.DetailsProperty;
  const neighbour = property?.Neighbour;
  let userInfo = localStorage.getItem("profile");

  const activeClass = "hover:bg-blue-700 bg-blue-800";
  const inActiveClass = "hover:bg-gray-800 bg-black";

  property?.PropertyImages_Detail &&
    property?.PropertyImages_Detail.map((item, index) => (
      <>
        <div key={index}>
          <img alt="" src={`${REACT_APP_BASE_API_URL}${item.propertyImage}`} />
        </div>
      </>
    ));

  const settings = {
    customPaging: function (i) {
      return (
        <a href="/#">
          <img
            alt="souvenir"
            src={`${REACT_APP_BASE_API_URL}${property?.PropertyAvatar}`}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleTabChange = (activeTab) => {
    setTab(activeTab);
  };
  const a = parseFloat(property?.AnnualIncome);
  const b = parseFloat(property?.CapitalAppreciation);
  let ai = formatNumber(property?.AnnualIncome);
  let ca = formatNumber(property?.CapitalAppreciation);
  let total = formatNumber(a + b);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <>
        <section id="inner-banner" className="bg-gray-700 h-132">
          <div className="container mx-auto">
            <div className="inner-flex">
              <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
                <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-sm text-sm font-bold text-white mb-5">
                  Property <strong className="font-light">Details</strong>
                </h2>
              </div>
              <div className="right-image">
                <img
                  src="../assets/images/banner2.png"
                  alt=""
                  className="h-full w-full"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="property" className="py-12 bg-gray-100 pb-0">
          <div className="Property-available mb-5">
            <div className="container mx-auto">
              <div className="2xl:w-full">
                <div className="bg-black p-2 mb-2 2xl:text-2xl xl:text-sm lg:text-sm md:text-2xl sm:text-sm text-sm text-left text-white">
                  {" "}
                  {property?.PropertyAddress}{" "}
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="md:w-1/2 w-full md:text-left text-center p-5">
                  <h4 className="md:text-2xl font-bold m-0">
                    Total Investment:{" "}
                  </h4>
                </div>
                <div className="md:w-1/2 w-full md:text-right text-center p-5 ">
                  <h4 className="md:text-2xl font-bold m-0">
                    View Smart Contracts:{" "}
                    {property?.blockchainNetwork=="Ethereum" ? (<>
                      <a className="bg-blue-800 text-white p-3 text-center align-middle text-sm rounded-full font-normal"
                        href={`${REACT_APP_ETHERSCAN_URL}/${property?.SmartContractAddress}`}
                        target="-blank">
                        ETH
                      </a>
                    </>):(property?.blockchainNetwork=="Polygon" ? (
                      <a className="bg-blue-800 text-white p-3 text-center align-middle text-sm rounded-full font-normal"
                        href={`${REACT_APP_POLYGONSCAN_URL}/${property?.SmartContractAddress}`}
                        target="-blank">
                        Polygon
                      </a>):("") ) 
                    }
                  </h4>
                </div>
              </div>
              <div className="text-center">
                <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-sm text-sm font-bold text-black">
                  US$ {formatNumber(property?.TotalPrice * 1)}
                </h2>
                <h5 className="md:text-2xl text-sm font-bold text-black">
                  (CL$ {formatNumber(property?.TotalPriceClp * 1)}-
                  approximately)
                </h5>
              </div>
              <div className="slider-new">
                <Slider {...settings}>
                  {property?.PropertyImages_Detail &&
                    property?.PropertyImages_Detail.map((item, index) => (
                      <>
                        <div key={index}>
                          <img
                            src={`${REACT_APP_BASE_API_URL}${item.propertyImage}`}
                            alt=""
                          />
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
              <div className="text-center">
                {userInfo ? (
                  <></>
                ) : (
                  <Link
                    className="bg-blue-800 text-white font-bold text-sm hover:bg-blue-500 p-2 mt-12 max-w-xs rounded-full m-auto w-full block"
                    to="/login"
                  >
                    Register/Sign In
                  </Link>
                )}
              </div>
            </div>
            <div className="custom-nav-tabs mt-12">
              <div className="container mx-auto">
                <div className="mb-4">
                  <ul
                    className="flex flex-wrap -mb-px text-sm font-medium text-center"
                    id="myTab"
                    data-tabs-toggle="#myTabContent"
                    role="tablist"
                  >
                    <li className="mr-2" role="presentation">
                      <button
                        id="background"
                        className={`font-text-base px-12 py-2 border-b border-blue-200  cursor-pointer w-full mb-5 rounded-full text-center text-gray-200 md:text-2xl text-base ${tab === "background" ? activeClass : inActiveClass
                        }`}
                        data-tabs-target="#background-tab"
                        type="button"
                        role="tab"
                        value="background"
                        aria-controls="background"
                        aria-selected="false"
                        onClick={(e) => handleTabChange("background")}
                      >
                        Antecedentes
                      </button>
                    </li>
                    <li className="mr-2" role="presentation">
                      <button
                        id="number"
                        className={`font-text-base px-12 py-2 border-b border-blue-200 cursor-pointer w-full mb-5 rounded-full text-center text-gray-200 md:text-2xl text-base ${tab === "number" ? activeClass : inActiveClass
                        }`}
                        data-tabs-target="#number-tab"
                        type="button"
                        role="tab"
                        value="number"
                        aria-controls="number"
                        aria-selected="false"
                        onClick={(e) => handleTabChange("number")}
                      >
                        Numbers
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="myTabContent">
                {tab === "background" && (
                  <div
                    id="background-tab"
                    className=""
                    role="tabpanel"
                    aria-labelledby="background"
                  >
                    <div className="container mx-auto">
                      <div className="2xl:w-full">
                        <div className="bg-black p-2 mb-2 2xl:text-2xl xl:text-sm lg:text-sm md:text-2xl sm:text-sm text-sm text-left text-white">
                          {" "}
                          Property{" "}
                          <span className="text-gray-200">Background</span>
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap mt-5 gap-10 md:flex-nowrap flex-wrap pb-12">
                        <div className="md:w-1/2 w-full">
                          <table className="table-auto w-full">
                            <tbody>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-normal mb-0 ">
                                    ANNUAL INCOME
                                  </h6>
                                  <p className="text-black m-0">
                                    without capital appreciation{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-blue-700 font-medium md:text-sm text-sm">
                                  {ai} %
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-normal mb-0 ">
                                    CAPITAL APPRECIATION{" "}
                                  </h6>
                                  <p className="text-black m-0">
                                    average estimated{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-blue-700 font-medium md:text-sm text-sm">
                                  {ca} %
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-normal mb-0 ">
                                    TOTAL
                                  </h6>
                                </td>
                                <td className="h-20 border-b border-black text-right text-blue-700 font-medium md:text-sm text-sm">
                                  {total} %
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Total Investment
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US$ {formatNumber(property?.TotalPrice)}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Total <span translate="no"> SPACIOS </span>{" "}
                                    <span translate="no">BRICK Tokens</span>
                                    issues{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {formatNumber(property?.TotalTokens)}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    <span translate="no"> Token </span> Price
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US${" "}
                                  {formatNumber(

                                    property?.TotalPrice /
                                    property?.TotalTokens

                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Passive Cash Flow per 1 <span translate="no"> Token </span> (annually)
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US${" "}
                                  {formatNumber(
                                    property?.PassiveCashFlow
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Capital Gain Incorporated in 1 <span translate="no"> Token </span>
                                    (annually)
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US${" "}
                                  {formatNumber(
                                    property?.CapitalGainIncorporated
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    TOTAL (annually)
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US${" "}
                                  {formatNumber(
                                    parseFloat(property?.PassiveCashFlow) +
                                    parseFloat(
                                      property?.CapitalGainIncorporated
                                    )
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="md:w-1/2 w-full">
                          <table className="table-auto w-full">
                            <tbody>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Property type{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {property?.PropertyType}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Neighborhood
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {property?.Neighbourhood}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Construction Year{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {property?.ConstructionYear}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Size
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {property?.LotSize} sq mts /{" "}
                                  {property?.LotSize * 10.763} sq ft
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Lease Contract{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  {property?.LeaseContract}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Common expenses (tenant expense)
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US${" "}
                                  {formatNumber(property?.CommonExpenses)} /
                                  CL${" "}
                                  {formatNumber(
                                    property?.CommonExpensesClp
                                  )}{" "}
                                  (approx.)
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <p className="text-black m-0 font-bold">
                                    Territorial taxes{" "}
                                  </p>
                                </td>
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm">
                                  US$ {property?.TerritorialTaxes}
                                </td>
                              </tr>
                              <tr>
                                <td className="h-30 border-b border-black md:text-sm text-sm" />
                                <td className="h-20 border-b border-black text-right text-black font-bold md:text-sm text-sm" />
                              </tr>
                              <tr>
                                <td
                                  className="h-30 md:text-sm text-sm"
                                  colSpan={2}
                                >
                                  <div className="flex justify-between m-auto my-3">
                                    <div className="first-shap flex items-center">
                                      <img
                                        className="md:w-20"
                                        src="/assets/images/icon1.png"
                                        alt=""
                                      />
                                      <span className="md:ml-7 md:mr-12">
                                        {property?.Bedroom}
                                      </span>
                                    </div>
                                    <div className="first-shap flex items-center">
                                      <img
                                        className="md:w-20"
                                        alt=""
                                        src="/assets/images/icon2.png"
                                      />
                                      <span className="md:ml-7 md:mr-12">
                                        {property?.Bathroom}
                                      </span>
                                    </div>
                                    <div className="first-shap flex items-center">
                                      <img
                                        className="md:w-20"
                                        alt=""
                                        src="/assets/images/icon3.png"
                                      />
                                      <span className="md:ml-7 md:mr-12">
                                        {property?.kitchen}
                                      </span>
                                    </div>
                                    <div className="first-shap flex items-center">
                                      <img
                                        className="md:w-20"
                                        src="/assets/images/icon4.png"
                                        alt=""
                                      />
                                      <span className="md:ml-7 md:mr-12">
                                        {property?.Livingroom}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white py-16">
                      <div className="container mx-auto w-full flex flex-wrap gap-10 md:flex-nowrap flex-wrap">
                        <div className="md:w-1/2 w-full">
                          <h2 className="2xl:text-5xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-sm text-sm font-bold text-black mb-5">
                            Details of the Property
                          </h2>
                          <section
                            dangerouslySetInnerHTML={{ __html: data }}
                            className="SearchResult-body"
                          />
                        </div>
                        <div className="md:w-1/2 w-full">
                          <h2 className="2xl:text-5xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-sm text-sm font-bold text-black mb-5">
                            Map
                          </h2>
                          <div className="map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.798578545436!2d-70.76189104936039!3d-33.42849528068598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c3d23742943d%3A0x9b139a6c95be0fb!2sSta.%20Rosa%2C%20Pudahuel%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sin!4v1666873072345!5m2!1sen!2sin"
                              height={600}
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                              title="i am iframe"
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-12">
                      <div className="container mx-auto">
                        <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-sm md:text-sm mt-5">
                          Neighbourhood Details{" "}
                        </h2>
                        <section
                          dangerouslySetInnerHTML={{ __html: neighbour }}
                          className="SearchResult-body"
                        />
                      </div>
                    </div>
                    <div className="bg-white py-12 pb-0">
                      <div className="container mx-auto">
                        <div className="w-full">
                          <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-sm md:text-sm mt-5">
                            Registration offices{" "}
                          </h2>
                          <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-sm md:text-sm mt-0 mb-5">
                            Gabrielli Real InvesToken SpA.
                          </h2>
                          <p className="font-normal 2xl:text-sm xl:text-sm lg:text-sm md:text-sm">
                            Av. Providencia 1650, 7th floor, offices No 702 and
                            703, Providencia, Metropolitan Region, Santiago,
                            Chile.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tab === "number" && (
                  <>
                    <div className="container mx-auto">
                      <div className="2xl:w-full">
                        <div className="bg-black p-2 2xl:text-2xl xl:text-sm lg:text-sm md:text-2xl sm:text-sm text-sm text-left text-white">
                          {" "}
                          Property <span className="text-gray-200">Number</span>
                        </div>
                      </div>
                    </div>
                    <div className="container mx-auto">
                      <div className="w-full flex flex-wrap gap-10 md:flex-nowrap flex-wrap pb-12">
                        <div className="md:w-1/2 w-full">
                          <table className="border-separate table-auto w-full custom-spacing">
                            <tbody>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    Gross Rent
                                  </h6>
                                  <p className="text-black m-0">(annually)</p>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  US$ {formatNumber(property?.GrossRent * 1)}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  CL$ {formatNumber(property?.GrossRentClp * 1)}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    Gross Rent
                                  </h6>
                                  <p className="text-black m-0">(Monthly)</p>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  US${" "}
                                  {formatNumber(
                                    (property?.GrossRent / 12).toFixed(2)
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  CL${" "}
                                  {formatNumber(
                                    (property?.GrossRentClp / 12).toFixed(2) * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    Monthly Cost
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40" />
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40" />
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Property Management (8%){" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  –US${" "}
                                  {formatNumber(
                                    EightPercent(property?.GrossRent) * 1
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  – CL${" "}
                                  {formatNumber(
                                    EightPercent(property?.GrossRentClp * 1) * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Access to the{" "}
                                    <span translate="no"> SPACIOS </span>{" "}
                                    Platform (2%){" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  –US${" "}
                                  {formatNumber(
                                    twoPercentage(property?.GrossRent)
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  – CL${" "}
                                  {formatNumber(
                                    twoPercentage(property?.GrossRentClp * 1) *
                                    1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Property Taxes{" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  –US$ {property?.PropertyTaxes}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  CL${" "}
                                  {formatNumber(
                                    property?.PropertyTaxesClp * 1
                                  ) * 1}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-bold mb-0 ">
                                    Net Rent{" "}
                                  </h6>
                                  <p className="text-black m-0">(Monthly)</p>
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  US${" "}
                                  {formatNumber(
                                    montlyProperty(
                                      property?.GrossRent,
                                      property?.PropertyTaxes
                                    ) * 1
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  CL${" "}
                                  {formatNumber(
                                    montlyPropertyClp(
                                      property?.GrossRentClp,
                                      property?.PropertyTaxesClp
                                    ) * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-bold mb-0 ">
                                    Net Rent{" "}
                                  </h6>
                                  <p className="text-black m-0">(annually)</p>
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  US${" "}
                                  {formatNumber(
                                    montlyProperty(
                                      property?.GrossRent,
                                      property?.PropertyTaxes
                                    ) *
                                    1 *
                                    12
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  CL$
                                  {formatNumber(
                                    montlyPropertyClp(
                                      property?.GrossRentClp,
                                      property?.PropertyTaxesClp
                                    ) *
                                    1 *
                                    12
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="md:w-1/2 w-full">
                          <table className="border-separate table-auto w-full custom-spacing">
                            <tbody>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-blue-700 font-bold mb-0 ">
                                    Total Investment
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  US$ {formatNumber(property?.TotalPrice * 1)}
                                </td>
                                <td className="h-20 border border-black text-center text-blue-700 font-bold md:text-sm text-sm w-40">
                                  CL${" "}
                                  {formatNumber(property?.TotalPriceClp * 1)}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Underlying Asset Price
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  - US${" "}
                                  {formatNumber(
                                    property?.UnderlyingAssetPrice * 1
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  - CL${" "}
                                  {formatNumber(
                                    property?.UnderlyingAssetPriceClp * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Operating Expense Reimbursement (10%)
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  – US${" "}
                                  {formatNumber(
                                    (
                                      (property?.UnderlyingAssetPrice * 10) /
                                      100
                                    ).toFixed(2) * 1
                                  )}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40">
                                  - CL${" "}
                                  {formatNumber(
                                    (
                                      (property?.UnderlyingAssetPriceClp *
                                        1 *
                                        10) /
                                      100
                                    ).toFixed(2) * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Initial Maintenance Reserve
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40">
                                  - US$ {property?.InitialMaintenanceReserve}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40">
                                  - CL${" "}
                                  {formatNumber(
                                    property?.InitialMaintenanceReserveClp * 1
                                  )}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm text-left lg:pl-8">
                                  <h6 className="text-black font-bold mb-0 ">
                                    - Insurance (4 years)
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40">
                                  - US$ {property?.Insurance}
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40">
                                  - CL${" "}
                                  {formatNumber(property?.InsuranceClp * 1)}
                                </td>
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    {" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40" />
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40" />
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    {" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40" />
                                <td className="h-20 border border-black text-center text-black font-bold md:text-sm text-sm w-40" />
                              </tr>
                              <tr className="gap-10">
                                <td className="h-30 border-b border-black md:text-sm text-sm">
                                  <h6 className="text-black font-bold mb-0 ">
                                    {" "}
                                  </h6>
                                </td>
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40" />
                                <td className="h-20 border border-black text-center text-black font-normal md:text-sm text-sm w-40" />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white py-12 pb-0">
                      <div className="container mx-auto">
                        <div className="w-full">
                          <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-sm md:text-sm mt-5">
                            Registration offices{" "}
                          </h2>
                          <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-sm md:text-sm mt-0 mb-5">
                            Gabrielli Real InvesToken SpA.
                          </h2>
                          <p className="font-normal 2xl:text-sm xl:text-sm lg:text-sm md:text-sm">
                            Av. Providencia 1650, 7th floor, offices No 702 and
                            703, Providencia, Metropolitan Region, Santiago,
                            Chile.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default PropertyDetails;
