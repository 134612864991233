import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSocial } from "../../redux/features/socialSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";

const Socials = () => {
  const dispatch = useDispatch();

  const { socials } = useSelector((state) => ({
    ...state.social,
  }));

  const SocialColumns = [
    {
      field: "Title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "Link",
      headerName: "Link",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/social/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchSocial());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>
              <Link
                to={{
                  pathname: "/admin/social/new",
                }}
                className="link"
              >
                Add New Social
              </Link>
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={socials ? socials : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={SocialColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Socials;
