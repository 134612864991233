import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const fetchSubscribe = createAsyncThunk(
  "user/fetchSubscribe",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getSubscribers();
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubscriber = createAsyncThunk(
  "subscribe/deleteSubscriber",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteSubscriber(id);
      toast.success("Subscriber Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const subsscribeSlice = createSlice({
  name: "subscribe",
  initialState: {
    subscribers: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [fetchSubscribe.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchSubscribe.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscribers = action.payload;
    },
    [fetchSubscribe.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteSubscriber.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteSubscriber.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.subscribers = state.subscribers.filter((item) => item.id !== id);
      }
    },
    [deleteSubscriber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default subsscribeSlice.reducer;
