import React, { useState, useEffect } from "react";
import "./navbar.scss";
import Web3 from "web3";
import { toast } from "react-toastify";
import ABI from "../../_abi.json";
const {
  REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT,
  REACT_APP_POLYGON_USDC_TOKEN_CONTRACT,
  REACT_APP_ETHEREUM_NETWORK_ID,
  REACT_APP_POLYGON_NETWORK_ID,
  REACT_APP_ETHEREUM_RPC_URL,
  REACT_APP_POLYGON_RPC_URL
} = process.env;

const Navbar = () => {
  const [accountAddress, setAccountAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [blockchainNetwork, setblockchainNetwork] = useState("Ethereum");
  const [networkChainId, setnetworkChainId] = useState("");
  const [isEthereumChain, setisEthereumChain] = useState();
  const [isPolygonChain, setisPolygonChain] = useState();
 

  const connectMetamsk = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setAccountAddress(result[0]);
          setIsConnected(true);
          window.walletAddress = result[0];
        });
    } else {
      console.log("Metamask is not connected");
    }
  };

  const switchToEhereum = async () => {
    await window.ethereum
      .request({ method: "eth_chainId" })
      .then(async (chain) => {
        if (chain != REACT_APP_ETHEREUM_NETWORK_ID) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: REACT_APP_ETHEREUM_NETWORK_ID }],
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };

  const disConnectMetamsk = async () => {
    setAccountAddress("");
    setIsConnected(false);
    // window.walletAddress = "";
  };


  const connectContract = async () => {
    var CONTRACTADDRESS;
    new Web3(Web3.givenProvider || "http://localhost:8545");
    await window.ethereum.request({ method: "eth_chainId" }).then((chain) => {
      // 0x89 && 0x13881 For Polygon || 0x1  0x5 For Ethereum
      if (chain == REACT_APP_POLYGON_NETWORK_ID) {
        setblockchainNetwork("Polygon");
        window.networkChain = "Polygon";
        setnetworkChainId(chain);
        setisPolygonChain(true);
        setisEthereumChain(false);
        CONTRACTADDRESS = REACT_APP_POLYGON_USDC_TOKEN_CONTRACT;
      } else if (chain == REACT_APP_ETHEREUM_NETWORK_ID) {
        setblockchainNetwork("Ethereum");
        window.networkChain = "Ethereum"; 
        setnetworkChainId(chain);
        setisPolygonChain(false);
        setisEthereumChain(true);
        CONTRACTADDRESS = REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT;
      } else {
        CONTRACTADDRESS = REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT;
        setblockchainNetwork(" ");
        window.networkChain=" "
        // toast.error("Please Select Valid Crypto Network");
      }
    });

    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    const TOKENCONTRACT_ADDRESS = CONTRACTADDRESS;
    window.TOKENCONTRACT = new web3.eth.Contract(ABI, TOKENCONTRACT_ADDRESS);
  };

  useEffect(()=>{
    connectMetamsk();
    connectContract();
  },[networkChainId])


  const ChangeNetwork = async (e) => {
    if (window.ethereum) {
      try {
        var _chainId = e.target.value;
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: _chainId }], // chainId must be in hexadecimal numbers
        });
        // connectContract();
      } catch (error) {
        if (error.code === 4902) {
          if (_chainId == REACT_APP_POLYGON_NETWORK_ID) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: REACT_APP_POLYGON_NETWORK_ID,
                    rpcUrl: REACT_APP_POLYGON_RPC_URL,
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          } else if (_chainId == REACT_APP_ETHEREUM_NETWORK_ID) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: REACT_APP_ETHEREUM_NETWORK_ID,
                    rpcUrl: REACT_APP_ETHEREUM_RPC_URL,
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
        }
        console.error(error);
      }
    } else {
      toast.error("MetaMask is not installed.");
    }
  };

  window?.ethereum?.on("accountsChanged", function (accounts) {
    connectMetamsk();
  });
  window?.ethereum?.on("chainChanged", (_chainId) => setnetworkChainId(_chainId));



  
  return (
    <div className="navbar">
      <div className="wrapper float-right">
        <span>
          {isConnected ? (
            <>
              <button
                value="submit"
                className="ring-green-400 ring-1 rounded text-white bg-yellow-500 px-3 py-[10px]"
              >
                Connected
              </button>
              <span>
                <button
                  className="bg-yellow text-black font-semibold py-2 px-4 rounded inline-flex items-center"
                  onClick={() => navigator.clipboard.writeText(accountAddress)}
                >
                  {accountAddress.substring(0, 5) +
                    "....." +
                    accountAddress.substring(accountAddress.length - 5)}{" "}
                  COPY
                </button>
              </span>
            </>
          ) : (
            <button
              value="submit"
              className="ring-blue-400 ring-1 rounded text-white bg-blue-500 px-3 py-[10px] hover:bg-blue-600"
              onClick={() => connectMetamsk()}
            >
              Connect To <span translate="no">Metamask</span>
            </button>
          )}
        </span>
        <select
          className="ring-blue-400 ring-1 rounded text-white bg-blue-700 px-3 py-[10px] hover:bg-blue-600 text-md"
          onChange={(e) => ChangeNetwork(e)}
        >
          <option value={REACT_APP_ETHEREUM_NETWORK_ID}>
            <span translate="no">Ethereum</span>
          </option>
          {isPolygonChain ? (
            <>
              <option value={REACT_APP_POLYGON_NETWORK_ID} selected>
                <span translate="no">Polygon</span>
              </option>
            </>
          ) : (
            <>
              <option value={REACT_APP_POLYGON_NETWORK_ID}>
                <span translate="no">Polygon</span>
              </option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export default Navbar;
