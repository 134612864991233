import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFaq, editFaq, getFaq } from "../../redux/features/faqSlice";
import { Spinner } from "../../components";
import { Editor } from "@tinymce/tinymce-react";
const initialState = {
  question: "",
  answer: "",
};
const { REACT_APP_TINYMCE_API } = process.env;

const NewFAQ = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const { faqId } = useParams();
  const [faqData, setfaqData] = useState(initialState);
  const { error, loading, faq } = useSelector((state) => ({ ...state.faq }));

  const answer = faqData?.answer ? faqData?.answer : "Write something.";

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setfaqData({ ...faqData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text) {
      return toast.error("Please enter answare");
    }

    faqData.answer = text;
    const updatedFaqsdata = { ...faqData };
    dispatch(createFaq({ updatedFaqsdata, navigate, toast, dispatch }));

  };

  useEffect(() => {
    setfaqData({ ...faq });
  }, [faq]);

  useEffect(() => {
    dispatch(getFaq(faqId));
  }, [dispatch, faqId]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div></div>

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label>Question</label>
                  <input
                    type="text"
                    name="question"
                    id="question" required
                    onChange={onInputChange}
                  />
                </div>
                <div className="formInput">
                  <label>Answer</label>
                  <textarea
                    name="answer"
                    id="answer"
                    rows={5}
                    cols={40}
                    type="text"
                    // onChange={onInputChange}
                    style={{ display: "none" }}
                  />
                  <Editor
                    apiKey={REACT_APP_TINYMCE_API}
                    init={{
                      selector: "textarea",
                      height: 500,
                      menubar: false,
                      plugins: [
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    }}
                    outputFormat="text"
                    onEditorChange={(newText) => setText(newText)}
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Create</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewFAQ;
