import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function Protected({ children }) {
  const { user } = useSelector((state) => ({ ...state.auth }));
  return parseInt(user?.role) === 2 || parseInt(user?.rollId) === 2 ? (
    children
  ) : (
    <Navigate to="/login" />
  );
}
export default Protected;
