import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchTerm } from "../../redux/features/termsSlice";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
const Terms = () => {
  const dispatch = useDispatch();

  const { terms } = useSelector((state) => ({
    ...state.term,
  }));

  const TermColumns = [
    {
      field: "pageTitle",
      headerName: "Page Title",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 500,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/terms/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Update</div>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchTerm());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle">
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={terms ? terms : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={TermColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
