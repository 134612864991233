import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createFaq = createAsyncThunk(
  "faq/createFaq",
  async (
    { updatedFaqsdata, dispatch, navigate, toast },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.createFaq(updatedFaqsdata);
      toast.success("FAQ Added Successfully");
      navigate("/admin/faq");
      dispatch(fetchFaq);
      return response?.data?.saveFaq;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFaq = createAsyncThunk(
  "faq/fetchFaq",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchFaq();
      return response?.data?.fetchFaq;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deltedFaq = createAsyncThunk(
  "faq/deltedFaq",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deltedFaq(id);
      toast.success("Faq Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFaq = createAsyncThunk(
  "faq/editFaq",
  async ({ faqId, updatedFaqsdata, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.editFaq(updatedFaqsdata, faqId);
      toast.success("FAQ Updated Successfully");
      navigate("/admin/faq");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaqById = createAsyncThunk(
  "/faq/fetchFaqById",
  async (faqId, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getFaqById(faqId);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaq = createAsyncThunk(
  "/faq/getFaq",
  async (faqId, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getFaq(faqId);
      return response.data.faq;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faq: {},
    faqs: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [createFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [createFaq.fulfilled]: (state, action) => {
      state.loading = false;
      state.faqs = [action.payload];
    },
    [createFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchFaq.fulfilled]: (state, action) => {
      state.loading = false;
      state.faqs = action.payload;
    },
    [fetchFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFaqById.pending]: (state, action) => {
      state.loading = true;
    },
    [getFaqById.fulfilled]: (state, action) => {
      state.loading = false;
      state.faq = action.payload;
    },
    [getFaqById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deltedFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [deltedFaq.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.faqs = state.faqs.filter((item) => item.id !== id);
      }
    },
    [deltedFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [editFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [editFaq.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.faqs = state.faqs.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [getFaq.fulfilled]: (state, action) => {
      state.loading = false;
      state.faq = action.payload;
    },
    [getFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default faqSlice.reducer;
