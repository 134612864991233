import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import verifyImageFile from "../assets/images/email.png";
import { verifyMail } from "../redux/api";
const verfiyImage = {
  width: "100px",
  margin: "0 auto",
  paddingBottom: "30px",
};

const VerifyEmail = () => {
  let params = useParams();

  const { token } = params;

  useEffect(() => {
    verifyMail(token)
      .then((response) => {
        return toast.success(response.data.message);
      })
      .catch((error) => {
        return toast.error(error?.response?.data?.message);
      });
  }, [token]);

  return (
    <>
      {" "}
      <section id="register-form">
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/1 xl:w-1/1 flex flex-col justify-center bg-gray-100">
            <div className="centered-div">
              <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-2 lg:px-24">
                <div style={verfiyImage}>
                  <img src={verifyImageFile} alt="" />
                </div>
                <h2 className="text-center text-4xl font-bold text-blue-800 mb-5">
                  Your email has{" "}
                  <span className="font-normal ml-2">been verified</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyEmail;
