import React from "react";
import { Link } from "react-router-dom";


const BlogCard = ({ title, description, currentDate, tags, id }) => {

  const result = description.slice(0, 100) + '...';
  return (

    <div className="2xl:w-1/2 xl:w-1/2 xl:w-1/2 md:w-1/2 w-full">
      <div className="blog-box">
        <h2>
          {title}
        </h2>
        <h5 className="date">{currentDate}</h5>
        <p>
          <section
            className="blogs-body"
            style={{ fontSize: "20px" }}
            dangerouslySetInnerHTML={{ __html: result }}
          />


        </p>
        <Link to={`/blog/${id}`}>Read More</Link>
      </div>
    </div>

  );
};

export default BlogCard;
