import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBlogs, getBlogs } from "../../redux/features/blogSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
const Blogs = () => {
  const dispatch = useDispatch();

  const { blogs } = useSelector((state) => ({
    ...state.blog,
  }));
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this blog?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBlogs({ id, toast }));
      }
    });
  };

  const blogColumns = [
    {
      field: "title",
      headerName: "Title",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
    },
    {
      field: "tags",
      headerName: "tags",
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/blogs/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: 'flex', justifyContent: 'end' }}>
              <Link
                to={{
                  pathname: `/admin/blogs/new`,
                }}
                className="link"
              >
                Add New Blog
              </Link>
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={blogs ? blogs : []}
              getRowId={(row) => row?.id}
              columns={blogColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
