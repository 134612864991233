import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createSocial = createAsyncThunk(
  "social/createSocial",
  async ({ formData, dispatch, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createSocial(formData);
      toast.success(response?.data?.message);
      navigate("/admin/social");
      dispatch(fetchSocial);
      return response?.data?.saveSocial;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSocial = createAsyncThunk(
  "social/fetchSocial",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchSocial();
      return response.data.fetchSocial;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deltedSocial = createAsyncThunk(
  "social/deltedSocial",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deltedSocial(id);
      toast.success("Social Link Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editSocial = createAsyncThunk(
  "social/editSocial",
  async ({ socialId, formData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.editSocial(formData, socialId);
      toast.success("Social Media link updated Successfully");
      navigate("/admin/social");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const socialById = createAsyncThunk(
  "social/socialById",
  async (socialId, { rejectWithValue }) => {
    try {
      const response = await api.getSocialById(socialId);
      return response.data.socail;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "social",
  initialState: {
    social: {},
    socials: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [createSocial.pending]: (state, action) => {
      state.loading = true;
    },
    [createSocial.fulfilled]: (state, action) => {
      state.loading = false;
      state.socials = [action.payload];
    },
    [createSocial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [fetchSocial.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchSocial.fulfilled]: (state, action) => {
      state.loading = false;
      state.socials = action.payload;
    },
    [fetchSocial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deltedSocial.pending]: (state, action) => {
      state.loading = true;
    },
    [deltedSocial.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.socials = state.socials.filter((item) => item.id !== id);
      }
    },
    [deltedSocial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [editSocial.pending]: (state, action) => {
      state.loading = true;
    },
    [editSocial.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.socials = state.socials.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editSocial.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [socialById.pending]: (state, action) => {
      state.loading = true;
    },
    [socialById.fulfilled]: (state, action) => {
      state.loading = false;
      state.social = action.payload;
    },
    [socialById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default faqSlice.reducer;
