import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchCart,
  deleteCart,

} from "../redux/features/cartSlice";
import { Spinner } from "../components";
import { toast } from "react-toastify";
import { formatNumber } from "../utils/Formatter";
import { updatedCartQuantity } from "../redux/api";
import metaMaskLogo from "../assets/images/metamask-logo.png";

const Cart = () => {
  const { loading, carts, cartTotal, cartQuantity } = useSelector(
    (state) => state.cart
  );

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role !== 1) {
      dispatch(fetchCart());
    }
  }, [dispatch, user]);

  const handleRemoveFromCart = ({ id }) => {
    dispatch(deleteCart({ id, toast })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchCart());
      }
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const onInputChange = (e) => {
    e.preventDefault();
    let { id, value, max } = e.target;
    console.log("max ", max);
    let cartquantity = +value;
    let max1 = +max;

    if (cartquantity > max1) {
      toast.error("Limit exceeded, you can not add tokens now.");
      dispatch(fetchCart());
    } else {
      updatedCartQuantity(cartquantity, id).then(() => {
        toast.success("Cart has been updated.");
        dispatch(fetchCart());
      }).catch((error) => {
        dispatch(fetchCart());
        toast.error(error.response.data.message);
      });

    }
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto">
      <section id="blockchain-main">
        <div className=" mx-auto">
          <div className="section-content flex md:flex-row sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col">
            <div className="container mx-auto">
              <div className="">
                <div className=" 2xl:pt-20 xl:pt-18 lg:pt-20 md:pt-16 sm:pt-12">
                  <div className="centertext-cartpage">
                    <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="-blank">
                      <p>You Need To have an active Metamask Digital <br /> wallet to buy your Spacios Brick
                        <span translate="no"> Tokens </span>
                      </p>
                      <img src={metaMaskLogo} alt="Metamsk" />
                    </a>
                  </div>
                  <div className="btn-cart mt-3">
                    <Link to="/marketplace" className="continue-shop">
                      Continue Shopping
                    </Link>
                  </div>
                  <div className="main-wrapper">
                    <div className="cart-table tableBox">
                      {carts && carts?.length === 0 ? (
                        <p>Your cart is currently empty</p>
                      ) : (
                        <>
                          <table
                            className="w-100"
                            cellPadding={0}
                            cellSpacing={0}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <table
                                    className="w-100 inner-table"
                                    cellPadding={0}
                                    cellSpacing={0}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          style={{ width: "25%" }}
                                          className="first-child"
                                        >
                                          Product
                                        </th>
                                        <th><span translate="no"> Token </span> Price</th>

                                        <th>Quantity</th>
                                        <th>Sub-Total</th>
                                      </tr>
                                    </thead>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {carts?.map((cartItem) => (
                                    <table
                                      className="w-100 inner-table box-shadow-table"
                                      cellPadding={0}
                                      cellSpacing={0}
                                      key={cartItem.id}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ width: "25%" }}
                                            className="first-child"
                                          >
                                            <div className="image-sec-cart">
                                              <span
                                                onClick={() =>
                                                  handleRemoveFromCart(cartItem)
                                                }
                                                style={{
                                                  color: "red",
                                                  paddingRight: "10px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                X
                                              </span>
                                              <span className="image-main">
                                                <img
                                                  alt=""
                                                  src=""
                                                  className="image-resposnive"
                                                />
                                              </span>
                                              <div className="inner-anchor">
                                                <a href="/#">
                                                  {
                                                    cartItem?.Card_Detail
                                                      ?.PropertyAddress
                                                  }
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            ${formatNumber(cartItem?.TokenPrice)}
                                          </td>


                                          <td>
                                            <input
                                              className="quantity-cell"
                                              type="number"
                                              name="cartquantity"
                                              defaultValue={cartItem.Tokens}
                                              onBlur={(e) => onInputChange(e)}
                                              id={cartItem.id}
                                              min="1"
                                              max={
                                                cartItem?.Card_Detail?.MaxToken
                                              }
                                            />
                                            <p className="para-cus">
                                              Max token:{" "}
                                              {formatNumber(
                                                cartItem?.Card_Detail?.MaxToken
                                              )}
                                            </p>
                                          </td>

                                          <td>
                                            $
                                            {formatNumber(
                                              cartItem?.TokenPrice *
                                              cartItem.Tokens
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ))}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="cart-tot">
                            <h2>Cart Total</h2>
                          </div>
                          <div className="total-section">
                            <h3>Total</h3>
                            <p>${formatNumber(cartTotal)}</p>
                            <h3>Quantity</h3>
                            <p>{cartQuantity}</p>
                          </div>
                          <div className="btn-cart mrt-2 ">
                            <Link to="/checkout" className="continue-shop w-1000">
                              Proceed to Checkout
                            </Link>
                          </div> </>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cart;
