import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createBlogs,
  updateBlogs,
  getBlogsById,
} from "../../redux/features/blogSlice";
import { Editor } from "@tinymce/tinymce-react";
import { Spinner } from "../../components";

const initialState = {
  title: "",
  description: "",
  tags: "",
};

const NewBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogsId } = useParams();
  const [text, setText] = useState("");
  const [blogsData, setBlogsData] = useState(initialState);
  const { error, loading, blog } = useSelector((state) => ({ ...state.blog }));

  const { REACT_APP_TINYMCE_API } = process.env;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setBlogsData({ ...blogsData, [name]: value });
  };

  const description = blogsData?.description
    ? blogsData?.description
    : "Write something.";

  const handleSubmit = (e) => {
    e.preventDefault();
    blogsData.description = text;

    const updatedBlogsdata = { ...blogsData };
    // if (blogsId) {
    //   dispatch(updateBlogs({ blogsId, updatedBlogsdata, navigate, toast }));
    // } else {
    dispatch(createBlogs({ updatedBlogsdata, navigate, toast }));
    // }
  };

  useEffect(() => {
    setBlogsData({ ...blog });
  }, [blog]);

  useEffect(() => {
    dispatch(getBlogsById(blogsId));
  }, [dispatch, blogsId]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">

                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={onInputChange}
                    // value={blogsData.title}
                    required
                  />
                </div>
                <div className="formInput">
                  <label>Description</label>
                  <textarea
                    name="description"
                    id="description"
                    rows={10}
                    cols={70}
                    // defaultValue={blogsData.description}
                    style={{ display: "none" }}

                  ></textarea>
                  <Editor
                    apiKey={REACT_APP_TINYMCE_API}
                    init={{
                      selector: "textarea",
                      height: 500,
                      menubar: false,
                      plugins: [
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                        "link image code",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help" +
                        "undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code",
                    }}
                    initialValue={description}
                    outputFormat="text"
                    onEditorChange={(newText) => setText(newText)}
                  />
                </div>
                <div className="formInput">
                  <label>tags</label>
                  <input
                    type="text"
                    name="tags"
                    id="tags"
                    onChange={onInputChange}
                    // value={blogsData.tags}
                    required
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Create</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewBlogs;
