import { useParams, useNavigate, } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetPassword } from "../redux/features/authSlice";
import { Spinner } from "../components";
import React from "react";

const initialState = {
  password: "",
  password2: "",
};

const ResetPassword = () => {

  const [formValue, setFormValue] = useState(initialState);
  const { loading, error } = useSelector((state) => ({ ...state.auth }));
  let { userId, tokenUrl } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const {
    password,
    password2
  } = formValue;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password || !password2) {
      return toast.error("Required fields are missing");
    }
    const UserData = { ...formValue };
    dispatch(resetPassword({ userId, tokenUrl, UserData, navigate, toast }));

  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  return (
    <>
      <section id="register-form">
        <div className="w-full flex flex-wrap">
          {/* Register Section */}
          <div className="w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-center bg-gray-100">
            <div className="centered-div">
              <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-2 lg:px-24">
                <h2 className="text-center text-4xl font-bold text-blue-800 mb-5">
                  Reset<span className="font-normal ml-2">Password</span>
                </h2>
                <form
                  className="flex flex-col pt-3 md:pt-8 p-8 bg-gray-100 mt-5"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col pt-4">
                    <label htmlFor="email" className="text-lg font-normal">
                      New Password *
                    </label>
                    <input
                      type="password"
                      id="newpassword"
                      name="password"
                      value={password}
                      onChange={onInputChange}
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                  </div>
                  <div className="flex flex-col pt-4">
                    <label htmlFor="password" className="text-lg font-normal">
                      Confirm Password *
                    </label>
                    <input
                      type="password"
                      id="confirmpassword"
                      onChange={onInputChange}
                      name="password2"
                      value={password2}
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                  </div>

                  {loading ? (
                    <Spinner />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 max-w-xs rounded-full m-auto w-full"
                    />)}
                </form>
              </div>
            </div>
          </div>
          {/* Image Section */}
          <div className="lg:w-1/2 xl:w-1/2 shadow-2xl relative bg-black">
            <img
              className="object-cover w-full min-h-screen hidden lg:block xl:block  h-full"
              src="/assets/images/bg.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
