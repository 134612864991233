import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const MainFooter = () => {
  const { pathname } = useLocation();
  if (
    pathname === "/register" ||
    pathname === "/login" ||
    pathname === "/forgotpassword"
  )
    return null;
  const { REACT_APP_WEBSITE_URL } = process.env;

  return (
    <>
      <footer className="py-6 bg-white">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3">
              <div className="logo-footer py-5">
                <img
                  className="w-96"
                  alt=""
                  src={`${REACT_APP_WEBSITE_URL}/assets/images/logo-footer.png`}
                />
              </div>
            </div>
            <div className="2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 ">
              <div className="flex item-center h-full">
                <ul className="flex item-center h-full custom-font justify-end w-full">
                  <li className="flex item-center custom-font items-center">
                    <a href="/#">
                      <i
                        className="2xl:text-5xl xl:text:5xl lg:text:4xl md:text:3xl sm:text:2xl text:2xl fa fa-facebook"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="flex item-center custom-font items-center">
                    <a href="/#">
                      <i
                        className="2xl:text-5xl xl:text:5xl lg:text:4xl md:text:3xl sm:text:2xl text:2xl fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="flex item-center custom-font items-center">
                    <a href="/#">
                      <i
                        className="2xl:text-5xl xl:text:5xl lg:text:4xl md:text:3xl sm:text:2xl text:2xl fa fa-linkedin"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex md:flex-wrap sm:flex-wrap flex-wrap">
            <div className="2xl:w-1/4 xl:w-1/4 xl:w-1/4 md:w-1/2 sm:w-1/2 w-full">
              <p className="font-normal 2xl:text-xl text-gray-500 text-justify p-3">
                Real InvesToken SpA. es una empresa Proptech cuya misión
                es hacer que la inversión inmobiliaria esté al
                alcance de todos. Nada en SPACIOS plataforma debe
                interpretarse como asesoramiento de inversión, comercial, legal
                o fiscal o que constituya una oferta de servicio o asistencia
                para la inversión en activos inmobiliarios
                El listado de detalles relacionados con SPACIOS plataforma o
                cualquier contenido en SPACIOS plataforma no constituye una oferta de Real InvesToken SpA .
              </p>
            </div>
            <div className="2xl:w-1/4 xl:w-1/4 xl:w-1/4 md:w-1/2 sm:w-1/2 w-full">
              <p className="font-normal 2xl:text-xl text-gray-500 text-justify p-3">
                para vender, solicitar o hacer una oferta para participar en las oportunidades de financiamiento.
                Ninguna información o contenido disponible en SPACIOS plataforma debe interpretarse como una
                solicitud de una oferta para comprar o vender cualquier SPACIOS BRICK tokens. Cualquier decisión
                de realizar inversiones indirectas en activos inmobiliarios conlleva riesgos.
                Los resultados de rendimiento se muestran netos de todos los honorarios, {" "}
              </p>
            </div>
            <div className="2xl:w-1/4 xl:w-1/4 xl:w-1/4 md:w-1/2 sm:w-1/2 w-full">
              <p className="font-normal 2xl:text-xl text-gray-500 text-justify p-3">
                Costos y gastos asociados con los tokens SPACIOS BRICK. Si un inversor decide transferir un
                token SPACIOS BRICK a través de la empresa Real InvesToken SpA., o, en un mercado secundario;
                pueden surgir otras tarifas de procesamiento.  Esta información no
                es una oferta para invertir en tokens SPACIOS BRICK, en un fondo o en otra oportunidad de inversión y
                se proporciona únicamente a título informativo {" "}
              </p>
            </div>
            <div className="2xl:w-1/4 xl:w-1/4 xl:w-1/4 md:w-1/2 sm:w-1/2 w-full">
              <p className="font-normal 2xl:text-xl text-gray-500 text-justify p-3">
                (El desempeño pasado de los tokens no garantiza
                resultados futuros y los rendimientos de las inversiones individuales pueden variar) .
                Los rendimientos de los tokens se calculan sobre la base de los pagos de alquiler
                distribuidos a lo largo del año y de la apreciación del valor de la propiedad
                subyacente (garantía). (La apreciación del valor de la inversión inmobiliaria se
                basa en la diferencia entre el precio de compra y las tasaciones anuales de la propiedad) .{" "}
              </p>
            </div>
          </div>
          <div className="copy-right mt-3">
            <p className="font-normal xl:text-xl xl:text-xl text-black text-center p-5">
              Copyright © Gabrielli Real InvesToken SpA.&nbsp;
              {/* <a href="#">Terms &amp; Condition</a>&nbsp; &nbsp;
              <a href="#">Privacy Policy</a> */}
              <Link
                to="/termscondition"
                style={{ textDecoration: "underline" }}
              >
                {"    "}
                Terms Condition &amp; Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
