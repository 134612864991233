import React from "react";
import { Link } from "react-router-dom";
import { formatPercentage,formatNumber } from "../utils/Formatter";
const { REACT_APP_BASE_API_URL } = process.env;
 
const HomeSlider = ({id,sold,
  PropertyAddress,
  TotalPrice,
  PropertyAvatar,
  CapitalAppreciation,PassiveCashFlow,
  TotalTokens,AnnualIncome
}) => {
  return (
    <>
      <div className="slidermargin property-sldier" key={id}>
        <div className="item">
          <div className="property-className">
            <div className="availablty bg-gray-900 text-white py-3 text-center 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl text-2xl">
              {sold === "sold" ? "Sold Out" : "Available"}
            </div>
            <Link to={`/propertydetails/${id}`}>
              <div className="images">
                <img
                  alt=""
                  src={`${REACT_APP_BASE_API_URL}${PropertyAvatar}`}
                />

                <div
                  className={` ${
                    sold === "sold" ? "overlay-bg" : ""
                  }  absolute top-0 w-full h-full  flex items-center justify-center`}
                >
                  {sold === "sold" ? (
                    <>
                      <button
                        type="button"
                        className="bg-blue-800 text-white p-2 text-center align-middle text-xl w-40 rounded-full"
                      >
                        SOLD OUT
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Link>
            <div className="content-main bg-white">
              <div className="location py-5 text-center 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg text-lg">
                {PropertyAddress}{" "}
              </div>
              <div className="flex py-5">
                <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-1/2 text-center centerLine">
                  <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-xl  block font-bold w-full">Total Price</span>
                  <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg  font-light block w-full total-font">US$ {formatNumber(parseFloat(TotalPrice).toFixed(2))}</span>
                </div>
                <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-1/2 text-center">
                  <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-xl text-lg block font-bold w-full">
                    <span translate="no"> Token </span> Price</span>
                  <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-light w-full price-font">US$ {formatNumber((TotalPrice / TotalTokens).toFixed(2))}</span>
                </div>
              </div>
              {/* <div className="investment flex bg-gray-200 p-3 justify-between">
                <span className="min-investement ">
                  Min. Investment
                </span>
                <span className="min-investement-value ">
                  US$ {formatPercentage((TotalPrice / TotalTokens).toFixed(2))}
                </span>
              </div> */}
              <div className="investment-1 flex bg-gray-200 items-center p-3 justify-between">
                <span className="min-investement ">
                Annual Income <p className="text-sm">(Without capital appreciation)</p> </span> 
                
                <span className="min-investement-value ">
                  {formatPercentage((AnnualIncome)) } %
                </span>
              </div>
              <div className="investment flex items-center p-3 justify-between">
                <span className="min-investement ">
                  Annual Capital Appreciation {" "}
                  <p className="text-sm">(Estimated)</p>
                </span>
                <span className="min-investement-value ">
                  {formatNumber(parseFloat(CapitalAppreciation).toFixed(2))} %
                </span>
              </div>

              <div className="investment-1 flex bg-gray-200 items-center p-3 justify-between">
                <span className="min-investement ">
                Total Annual Income  </span> 
                <span className="min-investement-value ">
                  {formatPercentage((parseFloat(AnnualIncome)+parseFloat(CapitalAppreciation)).toFixed(2))} %
                </span>
              </div>
              <div className="investment flex items-center p-3 justify-between">
                <span className="min-investement ">
                Passive Cash Flow Per Token <p className="text-sm">(Annually)</p> </span>
                 
               
                <span className="min-investement-value ">
                  US$ {formatPercentage(PassiveCashFlow)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
