import React from "react";
import { useNavigate } from "react-router-dom";
const Tokenized = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>How does the tokenization of real estate assets work?</h2>
            <div className="content-inner">
              <p>
                Suppose we have a 35 m2 apartment that has a good annual return
                on cash flow that has a market value of US$80.000.- By
                tokenizing the property, it can be easily divided into tradable
                digital shares. For example, there will be 160 shares each
                valued at $500, or 1,600 shares with a unit value of $50. In
                this way, property ownership is fragmented and makes it easier
                for small investors to enter the real estate market effortlessly
                and without major restrictions. Various investors can own part
                of the square meter of the tokenized apartment through their
                <span translate="no"> tokens </span>. They will own a fraction
                of the mt2 and the flows of their monthly rental fee.
              </p>
              <p>&nbsp;</p>
              <p>
                &ldquo;
                <em>
                  It enables average people to become wise individual investors
                  to make profitable real estate investments and strategic
                  rental decisions through an online platform that instantly
                  aggregates real estate data
                </em>
                &rdquo; (Tokenization: The future of real estate investment?)
              </p>
              <p>&nbsp;</p>
              <p>
                <u>TECHNICAL EXPLANATION</u>: In short, real estate tokenization
                is the digitization of the value of real estate, incorporated
                into cryptographic <span translate="no"> token </span> or{" "}
                <span translate="no"> tokens </span>, which represent a value
                within the <span translate="no"> blockchain </span>. The
                issuance of these <span translate="no"> tokens </span> to the
                market is known by the name of 'security{" "}
                <span translate="no"> token </span> offering' (STO).
              </p>
            </div>
          </div>
          <div className="flex flex-wrap inner-content">
            <div className="w-full md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
              <div className="button-bottom">
                <button onClick={() => navigate(-1)} className="button-custom">
                  <p>Previous</p>
                  <i className="fas fa-arrow-left"></i>
                  Tokenization of real estate assets work
                </button>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
              <div className="button-bottom">
                <button
                  onClick={() => navigate("/faq/InvesToken/")}
                  className="button-custom left-btn-last"
                >
                  <p>Next FAQ</p>
                  <i className="fas fa-arrow-right"></i>
                  <span translate="no"> SPACIOS </span> Real InvesToken
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tokenized;
