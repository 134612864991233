import { Link } from "react-router-dom";
import React from "react";
const NotFound = () => {
  return (
    <>
      <section id="Pagenotfound" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="Property-available mb-5">
            <div className="flex flex-wrap">
              <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full p-5 text-xl flex items-center">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                  <div className="border-t border-gray-200 text-center pt-8">
                    <h1 className="2xl:text-9xl xl:text-7xl lg:text-7xl md:text-7xl text-5xl font-bold text-blue-800">
                      404
                    </h1>
                    <h1 className="2xl:text-6xl xl:text-4xl lg:text-4xl md:text-3xl text-2xl font-medium py-8">
                      <span className="text-blue-800">oops!</span> Page not
                      found
                    </h1>
                    <p className="text-2xl pb-8 px-12 font-medium">
                      Oops! The page you are looking for does not exist.
                    </p>
                    <Link to="/" className="bg-blue-800 hover:from-pink-500 hover:bg-blue-500 text-white font-semibold px-6 py-3 rounded-md mr-6 rounded-full">
                      Go Home
                    </Link>
                  </div>
                </div>
              </div>
              <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full flex justify-center">
                <div className="image h-full">
                  <img
                    src="assets/images/notfound.png"
                    className="2xl:w-2/3 xl:w-2/3 lg:w-2/3 w-full mx-auto"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
