import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { fetchPropertyEmail } from "../../redux/features/emailSlice";


const PropertyEmail = () => {
  const dispatch = useDispatch();

  const { PropertyEmail } = useSelector((state) => ({ ...state.email }));

  const userColumns = [
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 220,
      valueGetter: (params) =>
        `${params.row.emailUsers.firstName || ""}    ${params.row.emailUsers.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email Id",
      width: 220,
      valueGetter: (params) =>
        `${params.row.emailUsers.email || ""}`,
    },
    {
      field: "propertyId",
      headerName: "Property Address",
      width: 250,
      valueGetter: (params) =>
        `${params.row.emailProperities.PropertyAddress || ""}`,
    },
    {
      field: "status",
      headerName: "Email Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            <Stack spacing={1} alignItems="center">
              {params.row.status ? (
                <Stack direction="row" spacing={1}>
                  <Chip label="Success" color="success" variant="outlined" />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  <Chip label="Pending" color="error" variant="outlined" />
                </Stack>
              )}
            </Stack>
          </div>
        );
      },
    },

  ];

  useEffect(() => {
    dispatch(fetchPropertyEmail());
  }, [dispatch]);

  return (
    <>

      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={PropertyEmail ? PropertyEmail : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={userColumns}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default PropertyEmail;
