import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createCart = createAsyncThunk(
  "cart/createcart",
  async ({ product, dispatch, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createCart(product);
      toast.success("Cart Added Successfully");
      navigate("/cart");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getCarts();
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCart = createAsyncThunk(
  "cart/deleteCart",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteCart(id);
      toast.success("Cart Item deleted Successfully");
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);



const initialState = {
  carts: [],
  cartTotal: 0,
  cartQuantity: 0,
  error: "",
  loading: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: {
    [createCart.pending]: (state, action) => {
      state.loading = true;
    },
    [createCart.fulfilled]: (state, action) => {
      state.loading = false;
      state.carts = [action.payload];
    },
    [createCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchCart.pending]: (state) => {
      state.loading = true;
    },
    [fetchCart.fulfilled]: (state, action) => {
      state.loading = false;
      state.carts = action.payload.data;
      state.cartTotal = action.payload.cartTotal;
      state.cartQuantity = action.payload.cartQuantity;
    },
    [fetchCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteCart.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCart.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.carts = state.carts.filter((item) => item.id !== id);
      }
    },
    [deleteCart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    
  },
});

export default cartSlice.reducer;
