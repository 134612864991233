import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const fetchOrder = createAsyncThunk(
  "order/fetchOrder",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getOrders();
      return response.data.orders;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchRejectedOrder = createAsyncThunk(
  "order/fetchRejectedOrder",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUserRejectedOrders();
      return response.data.rejectedOrders;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPendingAggrement = createAsyncThunk(
  "order/fetchPendingAggrement",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.pendingAggrement();
      return response.data.ordersDetails;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderById = createAsyncThunk(
  "/order/getOrderById",
  async (id, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getOrder(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchOrderByAdmin = createAsyncThunk(
  "/order/fetchOrderByAdmin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchOrderByAdmin();
      return response?.data?.Order;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletedOrderByAdmin = createAsyncThunk(
  "order/deltedOrder",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.orderDeletedByAdmin(id);
      toast.success("Order Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchRentListing = createAsyncThunk(
  "/order/fetchRentListing",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchRentListing();
      return response?.data?.getRentDetails;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCompletedRent = createAsyncThunk(
  "/order/fetchCompletedRent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchCompletedRent();
      return response?.data?.getRentDetails;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  orders: [],
  rejectedOrders: [],
  ordersByAdmin: [],
  rentListing: [],
  pendingAggrements: [],
  order: "",
  orderDetail: {},
  error: "",
  loading: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderDetail: (state, action) => {
      state.orderDetail = action.payload;
    }
  },
  extraReducers: {
    [fetchOrder.pending]: (state) => {
      state.loading = true;
    },
    [fetchOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    },
    [fetchOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchRejectedOrder.pending]: (state) => {
      state.loading = true;
    },
    [fetchRejectedOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.rejectedOrders = action.payload;
    },
    [fetchRejectedOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [fetchPendingAggrement.pending]: (state) => {
      state.loading = true;
    },
    [fetchPendingAggrement.fulfilled]: (state, action) => {
      state.loading = false;
      state.pendingAggrements = action.payload;
    },
    [fetchPendingAggrement.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getOrderById.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrderById.fulfilled]: (state, action) => {
      state.loading = false;
      state.order = action.payload;
    },
    [fetchOrderByAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrderByAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.ordersByAdmin = action.payload;
    },
    [fetchOrderByAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    /**Rent */
    [fetchRentListing.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchRentListing.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentListing = action.payload;
    },
    [fetchRentListing.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    /* Rent end*/

    /**Rent Completed */
    [fetchCompletedRent.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCompletedRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentListing = action.payload;
    },
    [fetchCompletedRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    /* Rent Completed end*/
    [deletedOrderByAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [deletedOrderByAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.order = state.order.filter((item) => item.id !== id);
      }
    },
    [deletedOrderByAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setOrderDetail } = orderSlice.actions;
export default orderSlice.reducer;
