import React, { useEffect, useState } from "react";
import { getUsers, deleteUser } from "../../redux/features/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { genrateUsersReport } from "../../redux/api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Loader from "./Loader";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
const { REACT_APP_BASE_API_URL } = process.env;

const Users = () => {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => ({ ...state.auth }));
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser({ id, toast }));
    }
  };
  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const gernrateReport = () => {
    setOpen(false);
    let formData = { startData: startDate, toDate: toDate };
    genrateUsersReport(formData)
      .then((res) => {
        toast.success(res.data.message);
        if (res?.data?.path) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data?.path}`);
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlelOpen = () => {
    setOpen(true);
  };


  const userColumns = [
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 220,
      valueGetter: (params) =>
        `${params.row.firstName || ""}    ${params.row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },

    {
      field: "phone",
      headerName: "phone",
      sortable: false,
      width: 220,
      valueGetter: (params) =>
        `${"+"+params.row.phoneCode || ""}    ${params.row.phone || ""}`,
    },
    {
      field: "emailStatus",
      headerName: "Email Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.emailStatus}`}>
            <Stack spacing={1} alignItems="center">
              {params.row.emailStatus ? (
                <Stack direction="row" spacing={1}>
                  <Chip label="Verified" color="success" variant="outlined" />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  <Chip label="Unverified" color="error" variant="outlined" />
                </Stack>
              )}
            </Stack>
          </div>
        );
      },
    },
    {
      field: "userStatus",
      headerName: "Document Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.userStatus}`}>
            <Stack spacing={1} alignItems="center">
              {params.row.document_status == "Approved" ? (
                <Stack direction="row" spacing={1}>
                  <Chip label="Approved" color="success" variant="outlined" />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={params.row.document_status}
                    color="error"
                    variant="outlined"
                  />
                </Stack>
              )}
            </Stack>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/users/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate Property Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="formCustom">
                <lable>Select From Data</lable>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="p-3 rounded text-md form-control  border  focus:ring-1" />
              </div>
              <div className="formCustom">
                <lable>Select To Data</lable>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} className="p-3 rounded text-md form-control border  focus:ring-1" />
              </div>
              <div className="mt-4">
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>

            </Button>
            <div className="moadl-footer">
              <button className="btnCancel" onClick={handleClose}>Cancel</button>
              <button className="btnReport" onClick={gernrateReport}>Generate Report</button>
            </div>


          </DialogActions>
        </Dialog>
      </div>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>
              <button onClick={handlelOpen} className="link" > Generate Report </button>
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={users ? users : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={userColumns}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default Users;
