import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deltedEmail, fetchEmail } from "../../redux/features/emailSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";

const Emails = () => {
  const dispatch = useDispatch();

  const { emails } = useSelector((state) => ({
    ...state.email,
  }));

  const emailColumns = [
    {
      field: "subject",
      headerName: "Subject",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 400,

    },
    {
      field: "type",
      headerName: "Email Type",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/email/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchEmail());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle">

            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={emails ? emails : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={emailColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Emails;
