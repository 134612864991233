import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSellTokenFunction } from "../../redux/features/tokenSlice";
import { updateSellTokenStatus, updateTransferredUSDC, genrateSaleTokenReport } from "../../redux/api";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { formatNumber,formatNumberNew } from "../../utils/Formatter";
import Button from "@mui/material/Button";
import Loader from "./Loader";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import moment from "moment";
const { REACT_APP_BASE_API_URL } = process.env;

const SellTokenList = () => {
  const [isProcessing, setisProcessing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { tokenDetails } = useSelector((state) => ({
    ...state.token,
  }));

  const handlePaymentStatus = async (id, paymentStatus) => {
    paymentStatus = paymentStatus ? false : true;
    let formData = { paymentStatus: paymentStatus };
    updateSellTokenStatus(id, formData)
      .then(() => {
        dispatch(getSellTokenFunction());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendToken = async (Id, amount, to, payoutNetwork) => {
    if (window.networkChain != payoutNetwork) {
      return toast.warning("Please Connect With "+ `${payoutNetwork}`+ " Network");
    }
    setisProcessing(true);
    const transferToken = async (amount) => {
      let _amount = amount * 1e6;
      await window.TOKENCONTRACT.methods
        .transfer(to, _amount.toString())
        .send({ from: window.walletAddress })
        .then(async (d) => {
          const toAddress = d?.to;
          const transactionid = d?.transactionHash;

          const data = {
            toAddress: toAddress,
            transactionHash: transactionid,
            id: Id,
            isTransferred: d.status,
          };

          await updateTransferredUSDC(Id, data)
            .then((response) => {
              toast.success(response.data.message);
              dispatch(getSellTokenFunction());
              setisProcessing(false);
            })
            .catch((error) => {
              console.log(error);
              return toast.error(error?.response?.data?.message);
            });
        })
        .catch((error) => {
          console.log(error);
          return toast.error(
            "Something went wrong, \n Transaction not performed"
          );
        });
      setisProcessing(false);
    };

    transferToken(amount);
  };

  const gernrateReport = () => {
    setOpen(false);
    let formData = { startData: startDate, toDate: toDate };
    genrateSaleTokenReport(formData)
      .then((res) => {
        toast.success(res.data.message);
        if (res?.data?.path) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data?.path}`);
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlelOpen = () => {
    setOpen(true);
  };


  const tokenColumn = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 120,
      valueGetter: (params) => `${params?.row.id}`,
    },
    {
      field: "toAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) => `${params?.row.Pr_Details.PropertyAddress}`,
    },
    {
      field: "Contract Address",
      headerName: "Smart Contract Address",
      width: 400,
      valueGetter: (params) => `${params?.row.Pr_Details.SmartContractAddress}`,
    },
    {
      field: "propertyToken",
      headerName: "Property Token",
      width: 150,
      valueGetter: (params) => `${formatNumber(params?.row.saleToken * 1)}`,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount $",
      width: 150,
      valueGetter: (params) => `${formatNumberNew(params?.row.totalAmount)}`,
    },
    {
      field: "plateformFee",
      headerName: "Plateform Fee $",
      width: 150,
      valueGetter: (params) => `${formatNumberNew(params?.row.plateformFee)}`,
    },
    {
      field: "Net Amount",
      headerName: "netAmount $",
      width: 150,
      valueGetter: (params) => `${formatNumberNew(params?.row.netAmount)}`,
    },
    {
      field: "Token Price",
      headerName: "Token Price $",
      width: 150,
      valueGetter: (params) =>
        `${formatNumber(
          (params?.row.Pr_Details.TotalPrice /
            params?.row.Pr_Details.TotalTokens) *
          1
        )}`,
    },
    {
      field: "Transaction hash ",
      headerName: "Transaction hash ",
      width: 550,
      valueGetter: (params) => `${params?.row?.transactionHash}`,
    },
    {
      field: "paymentStatus",
      headerName: "Token Received",
      width: 140,
      renderCell: (params) => {
        return `${params?.row?.isVerified}`;
      },
    },
    {
      field: "isTokenTransfred",
      headerName: "Token Transfred",
      width: 140,
      renderCell: (params) => {
        return `${params?.row?.isTransferred}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Requested Date",
      width: 130,
      renderCell: (params) => {
        return `${moment(params?.row?.createdAt).format("MMMM Do YYYY") }`;
      },
    },
    {
      field: "TransferredUsdcHash",
      headerName: "Transferred USDC Hash",
      width: 550,
      renderCell: (params) => {
        return `${params?.row?.TransferredUsdcHash}`;
      },
    },
    {
      field: "payoutCryptoNetwork",
      headerName: "Payout Network",
      width: 120,
      renderCell: (params) => {
        return `${params?.row?.payoutCryptoNetwork}`;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className={`${params?.row?.paymentStatus ? "deleteButton" : "publishContract" }`}
              onClick={(e) =>
                handlePaymentStatus(params?.row?.id, params?.row?.isVerified)
              }
            >
              {!params.row.isVerified ? "Verify" : "Verified"}
            </div>
            {params.row.isVerified ? (
              <>
                {!params.row.isTransferred ? (
                  <>
                    {!isProcessing ? (
                      <>
                        <button
                          className="publishToken"
                          onClick={(e) =>
                            handleSendToken(
                              params?.row?.id,
                              params?.row?.netAmount,
                              params?.row?.fromAddress,params?.row?.payoutCryptoNetwork
                            )
                          }
                        >
                          {" "}
                          Send
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="publishToken">
                          {" "}
                          Send
                          {/* <i class="fa fa-spinner fa-spin"></i> */}
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <button className="publishToken"> Transfred </button>
                )}
              </>
            ) : (
              " "
            )}
            {/* {setisProcessing(false)} */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getSellTokenFunction());
  }, [dispatch]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate Property Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="formCustom">
                <lable>Select From Data</lable>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="p-3 rounded text-md form-control  border  focus:ring-1" />
              </div>
              <div className="formCustom">
                <lable>Select To Data</lable>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} className="p-3 rounded text-md form-control border  focus:ring-1" />
              </div>
              <div className="mt-4">
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>

            </Button>
            <div className="moadl-footer">
              <button className="btnCancel" onClick={handleClose}>Cancel</button>
              <button className="btnReport" onClick={gernrateReport}>Generate Report</button>
            </div>


          </DialogActions>
        </Dialog>
      </div>

      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />


          <div className="datatable">
            <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>
              <button onClick={handlelOpen} className="link" > Generate Report </button>
            </div>
            <div className="datatableTitle">Sell <span translate="no"> Token </span> Listing</div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={tokenDetails ? tokenDetails : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={tokenColumn}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SellTokenList;
