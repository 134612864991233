import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createBlogs = createAsyncThunk(
  "/blogs/createBlogs",
  async ({ updatedBlogsdata, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createBlogs(updatedBlogsdata);
      toast.success("Blogs Added Successfully");
      navigate("/admin/blogs");
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "/blogs/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getBlogs();
      return response.data.getBlogs;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBlogsById = createAsyncThunk(
  "/blog/fetchBlogsById",
  async (blogsId, { rejectWithValue }) => {
    try {
      const response = await api.getBlogsById(blogsId);
      return response.data.getBlogs;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBlogs = createAsyncThunk(
  "blog/deleteBlog",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteBlogs(id);
      toast.success("blogs Deleted Successfully");

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBlogs = createAsyncThunk(
  "blogs/updateBlog",
  async (
    { blogsId, updatedBlogsdata, toast, navigate },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.updateBlogs(updatedBlogsdata, blogsId);
      toast.success("blog Updated Successfully");
      navigate("/admin/blogs");
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blog: {},
    blogs: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [createBlogs.pending]: (state, action) => {
      state.loading = true;
    },
    [createBlogs.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createBlogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBlogs.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogs.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    },
    [getBlogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBlogsById.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogsById.fulfilled]: (state, action) => {
      state.loading = false;
      state.blog = action.payload;
    },
    [getBlogsById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteBlogs.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBlogs.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;

      if (id) {
        state.blogs = state.blogs.filter((item) => item.id !== id);
      }
    },
    [deleteBlogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateBlogs.pending]: (state, action) => {
      state.loading = true;
    },
    [updateBlogs.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateBlogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default blogSlice.reducer;
