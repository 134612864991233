import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Card from "react-bootstrap/Card";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { getUsers } from "../../redux/features/authSlice";
import { getProperties } from "../../redux/features/propertySlice";
import { getSellTokenFunction } from "../../redux/features/tokenSlice";
import { fetchCompletedRent } from "../../redux/features/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  BarElement,
  CategoryScale, // x--axis
  LinearScale, //y-axis
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Dashboard = () => {
  const dispatch = useDispatch();
 

  const { users } = useSelector((state) => ({
    ...state.auth,
  }));

  const { properties } = useSelector((state) => ({
    ...state.property,
  }));

  const { tokenDetails } = useSelector((state) => ({
    ...state.token,
  }));

  const { rentListing } = useSelector((state) => ({
    ...state.order,
  }));

  let countJan = [];
  let countFeb = [];
  let countMar = [];
  let countApr = [];
  let countMay = [];
  let countJun = [];
  let countJul = [];
  let countAug = [];
  let countSep = [];
  let countOct = [];
  let countNov = [];
  let countDec = [];

  let countPropJan = [];
  let countPropFeb = [];
  let countPropMar = [];
  let countPropApr = [];
  let countPropMay = [];
  let countPropJun = [];
  let countPropJul = [];
  let countPropAug = [];
  let countPropSep = [];
  let countPropOct = [];
  let countPropNov = [];
  let countPropDec = [];

  let countTokenJan = [];
  let countTokenFeb = [];
  let countTokenMar = [];
  let countTokenApr = [];
  let countTokenMay = [];
  let countTokenJun = [];
  let countTokenJul = [];
  let countTokenAug = [];
  let countTokenSep = [];
  let countTokenOct = [];
  let countTokenNov = [];
  let countTokenDec = [];

  let countRentJan = [];
  let countRentFeb = [];
  let countRentMar = [];
  let countRentApr = [];
  let countRentMay = [];
  let countRentJun = [];
  let countRentJul = [];
  let countRentAug = [];
  let countRentSep = [];
  let countRentOct = [];
  let countRentNov = [];
  let countRentDec = [];

  const [countJanTotal, setCountJanTotal] = useState(0);
  const [countFebTotal, setCountFebTotal] = useState(0);
  const [countMarTotal, setCountMarTotal] = useState(0);
  const [countAprTotal, setCountAprTotal] = useState(0);
  const [countMayTotal, setCountMayTotal] = useState(0);
  const [countJuneTotal, setCountJuneTotal] = useState(0);
  const [countJulyTotal, setCountJulyTotal] = useState(0);
  const [countAugTotal, setCountAugTotal] = useState(0);
  const [countSepTotal, setCountSepTotal] = useState(0);
  const [countOctTotal, setCountOctTotal] = useState(0);
  const [countNovTotal, setCountNovTotal] = useState(0);
  const [countDecTotal, setCountDecTotal] = useState(0);

  const [countPropJanTotal, setCountPropJanTotal] = useState(0);
  const [countPropFebTotal, setCountPropFebTotal] = useState(0);
  const [countPropMarTotal, setCountPropMarTotal] = useState(0);
  const [countPropAprTotal, setCountPropAprTotal] = useState(0);
  const [countPropMayTotal, setCountPropMayTotal] = useState(0);
  const [countPropJuneTotal, setCountPropJuneTotal] = useState(0);
  const [countPropJulyTotal, setCountPropJulyTotal] = useState(0);
  const [countPropAugTotal, setCountPropAugTotal] = useState(0);
  const [countPropSepTotal, setCountPropSepTotal] = useState(0);
  const [countPropOctTotal, setCountPropOctTotal] = useState(0);
  const [countPropNovTotal, setCountPropNovTotal] = useState(0);
  const [countPropDecTotal, setCountPropDecTotal] = useState(0);

  const [countTokenJanTotal, setCountTokenJanTotal] = useState(0);
  const [countTokenFebTotal, setCountTokenFebTotal] = useState(0);
  const [countTokenMarTotal, setCountTokenMarTotal] = useState(0);
  const [countTokenAprTotal, setCountTokenAprTotal] = useState(0);
  const [countTokenMayTotal, setCountTokenMayTotal] = useState(0);
  const [countTokenJuneTotal, setCountTokenJuneTotal] = useState(0);
  const [countTokenJulyTotal, setCountTokenJulyTotal] = useState(0);
  const [countTokenAugTotal, setCountTokenAugTotal] = useState(0);
  const [countTokenSepTotal, setCountTokenSepTotal] = useState(0);
  const [countTokenOctTotal, setCountTokenOctTotal] = useState(0);
  const [countTokenNovTotal, setCounTokenNovTotal] = useState(0);
  const [countTokenDecTotal, setCountTokenDecTotal] = useState(0);

  const [countRentJanTotal, setCountRentJanTotal] = useState(0);
  const [countRentFebTotal, setCountRentFebTotal] = useState(0);
  const [countRentMarTotal, setCountRentMarTotal] = useState(0);
  const [countRentAprTotal, setCountRentAprTotal] = useState(0);
  const [countRentMayTotal, setCountRentMayTotal] = useState(0);
  const [countRentJuneTotal, setCountRentJuneTotal] = useState(0);
  const [countRentJulyTotal, setCountRentJulyTotal] = useState(0);
  const [countRentAugTotal, setCountRentAugTotal] = useState(0);
  const [countRentSepTotal, setCountRentSepTotal] = useState(0);
  const [countRentOctTotal, setCountRentOctTotal] = useState(0);
  const [countRentNovTotal, setCountRentNovTotal] = useState(0);
  const [countRentDecTotal, setCountRentDecTotal] = useState(0);

  const [userDate, setUserDate] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [dateFilter, setDateFilter] = useState("2022");

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          countJanTotal,
          countFebTotal,
          countMarTotal,
          countAprTotal,
          countMayTotal,
          countJuneTotal,
          countJulyTotal,
          countAugTotal,
          countSepTotal,
          countOctTotal,
          countNovTotal,
          countDecTotal,
        ],
        backgroundColor: "#007bff",
        barThickness: 50,
        width: 150,
      },
    ],
  };

  const propertyData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Properties",
        data: [
          countPropJanTotal,
          countPropFebTotal,
          countPropMarTotal,
          countPropAprTotal,
          countPropMayTotal,
          countPropJuneTotal,
          countPropJulyTotal,
          countPropAugTotal,
          countPropSepTotal,
          countPropOctTotal,
          countPropNovTotal,
          countPropDecTotal,
        ],
        backgroundColor: "#7450F8",
        barThickness: 50,
        width: 150,
      },
    ],
  };

  const sellTokenData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sell Tokens",
        data: [
          countTokenJanTotal,
          countTokenFebTotal,
          countTokenMarTotal,
          countTokenAprTotal,
          countTokenMayTotal,
          countTokenJuneTotal,
          countTokenJulyTotal,
          countTokenAugTotal,
          countTokenSepTotal,
          countTokenOctTotal,
          countTokenNovTotal,
          countTokenDecTotal,
        ],
        backgroundColor: "#28a745",
        barThickness: 50,
        width: 150,
      },
    ],
  };

  const rentData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Distributed Rent",
        data: [
          countRentJanTotal,
          countRentFebTotal,
          countRentMarTotal,
          countRentAprTotal,
          countRentMayTotal,
          countRentJuneTotal,
          countRentJulyTotal,
          countRentAugTotal,
          countRentSepTotal,
          countRentOctTotal,
          countRentNovTotal,
          countRentDecTotal,
        ],
        backgroundColor: "#17a2b8",
        barThickness: 50,
        width: 150,
      },
    ],
  };

  const getUserData = () => {
    for (var i in users) {
      let date = new Date(users[i].createdAt);
      const dateMonthCount = date.getMonth() + 1;
      const dateYear = date.getFullYear();
      if (dateYear == dateFilter) {
        if (dateMonthCount == 1) {
          countJan.push(1);
        } else if (dateMonthCount == 2) {
          countFeb.push(1);
        } else if (dateMonthCount == 3) {
          countMar.push(1);
        } else if (dateMonthCount == 4) {
          countApr.push(1);
        } else if (dateMonthCount == 5) {
          countMay.push(1);
        } else if (dateMonthCount == 6) {
          countJun.push(1);
        } else if (dateMonthCount == 7) {
          countJul.push(1);
        } else if (dateMonthCount == 8) {
          countAug.push(1);
        } else if (dateMonthCount == 9) {
          countSep.push(1);
        } else if (dateMonthCount == 10) {
          countOct.push(1);
        } else if (dateMonthCount == 11) {
          countNov.push(1);
        } else if (dateMonthCount == 12) {
          countDec.push(1);
        }
      }

      setCountJanTotal(countJan.length);
      setCountFebTotal(countFeb.length);
      setCountMarTotal(countMar.length);
      setCountAprTotal(countApr.length);
      setCountMayTotal(countMay.length);
      setCountJuneTotal(countJun.length);
      setCountJulyTotal(countJul.length);
      setCountAugTotal(countAug.length);
      setCountSepTotal(countSep.length);
      setCountOctTotal(countOct.length);
      setCountNovTotal(countNov.length);
      setCountDecTotal(countDec.length);
    }
  };

  // Properties  condionton

  const getPropertyData = () => {
    //alert('hi')
    for (var i in properties) {
      let date = new Date(properties[i].createdAt);
      const dateMonthCount = date.getMonth() + 1;
      const dateYear = date.getFullYear();
      if (dateYear == dateFilter) {
        if (dateMonthCount == 1) {
          countPropJan.push(1);
        } else if (dateMonthCount == 2) {
          countPropFeb.push(1);
        } else if (dateMonthCount == 3) {
          countPropMar.push(1);
        } else if (dateMonthCount == 4) {
          countPropApr.push(1);
        } else if (dateMonthCount == 5) {
          countPropMay.push(1);
        } else if (dateMonthCount == 6) {
          countPropJun.push(1);
        } else if (dateMonthCount == 7) {
          countPropJul.push(1);
        } else if (dateMonthCount == 8) {
          countPropAug.push(1);
        } else if (dateMonthCount == 9) {
          countPropSep.push(1);
        } else if (dateMonthCount == 10) {
          countPropOct.push(1);
        } else if (dateMonthCount == 11) {
          countPropNov.push(1);
        } else if (dateMonthCount == 12) {
          countPropDec.push(1);
        }
      }
      setCountPropJanTotal(countPropJan.length);
      setCountPropFebTotal(countPropFeb.length);
      setCountPropMarTotal(countPropMar.length);
      setCountPropAprTotal(countPropApr.length);
      setCountPropMayTotal(countPropMay.length);
      setCountPropJuneTotal(countPropJun.length);
      setCountPropJulyTotal(countPropJul.length);
      setCountPropAugTotal(countPropAug.length);
      setCountPropSepTotal(countPropSep.length);
      setCountPropOctTotal(countPropOct.length);
      setCountPropNovTotal(countPropNov.length);
      setCountPropDecTotal(countPropDec.length);
    }
  };

  const getSellTokenData = () => {
    //alert('hi')
    for (var i in tokenDetails) {
      let date = new Date(tokenDetails[i].createdAt);
      const dateMonthCount = date.getMonth() + 1;
      const dateYear = date.getFullYear();
      if (dateYear == dateFilter) {
        if (dateMonthCount == 1) {
          countTokenJan.push(1);
        } else if (dateMonthCount == 2) {
          countTokenFeb.push(1);
        } else if (dateMonthCount == 3) {
          countTokenMar.push(1);
        } else if (dateMonthCount == 4) {
          countTokenApr.push(1);
        } else if (dateMonthCount == 5) {
          countTokenMay.push(1);
        } else if (dateMonthCount == 6) {
          countTokenJun.push(1);
        } else if (dateMonthCount == 7) {
          countTokenJul.push(1);
        } else if (dateMonthCount == 8) {
          countTokenAug.push(1);
        } else if (dateMonthCount == 9) {
          countTokenSep.push(1);
        } else if (dateMonthCount == 10) {
          countTokenOct.push(1);
        } else if (dateMonthCount == 11) {
          countTokenNov.push(1);
        } else if (dateMonthCount == 12) {
          countTokenDec.push(1);
        }
      }
      setCountTokenJanTotal(countTokenJan.length);
      setCountTokenFebTotal(countTokenFeb.length);
      setCountTokenMarTotal(countTokenMar.length);
      setCountTokenAprTotal(countTokenApr.length);
      setCountTokenMayTotal(countTokenMay.length);
      setCountTokenJuneTotal(countTokenJun.length);
      setCountTokenJulyTotal(countTokenJul.length);
      setCountTokenAugTotal(countTokenAug.length);
      setCountTokenSepTotal(countTokenSep.length);
      setCountTokenOctTotal(countTokenOct.length);
      setCounTokenNovTotal(countTokenNov.length);
      setCountTokenDecTotal(countTokenDec.length);
    }
  };

  const getRentData = () => {
    ////alert('hi')
    for (var i in rentListing) {
      let date = new Date(rentListing[i].createdAt);
      const dateMonthCount = date.getMonth() + 1;
      const dateYear = date.getFullYear();
      if (dateYear == dateFilter) {
        if (dateMonthCount == 1) {
          countRentJan.push(1);
        } else if (dateMonthCount == 2) {
          countRentFeb.push(1);
        } else if (dateMonthCount == 3) {
          countRentMar.push(1);
        } else if (dateMonthCount == 4) {
          countRentApr.push(1);
        } else if (dateMonthCount == 5) {
          countRentMay.push(1);
        } else if (dateMonthCount == 6) {
          countRentJun.push(1);
        } else if (dateMonthCount == 7) {
          countRentJul.push(1);
        } else if (dateMonthCount == 8) {
          countRentAug.push(1);
        } else if (dateMonthCount == 9) {
          countRentSep.push(1);
        } else if (dateMonthCount == 10) {
          countRentOct.push(1);
        } else if (dateMonthCount == 11) {
          countRentNov.push(1);
        } else if (dateMonthCount == 12) {
          countRentDec.push(1);
        }
      }
      setCountRentJanTotal(countRentJan.length);
      setCountRentFebTotal(countRentFeb.length);
      setCountRentMarTotal(countRentMar.length);
      setCountRentAprTotal(countRentApr.length);
      setCountRentMayTotal(countRentMay.length);
      setCountRentJuneTotal(countRentJun.length);
      setCountRentJulyTotal(countRentJul.length);
      setCountRentAugTotal(countRentAug.length);
      setCountRentSepTotal(countRentSep.length);
      setCountRentOctTotal(countRentOct.length);
      setCountRentNovTotal(countRentNov.length);
      setCountRentDecTotal(countRentDec.length);
    }
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getProperties());
    dispatch(getSellTokenFunction());
    dispatch(fetchCompletedRent());
    if (users.length > 0) {
      getUserData();
      getPropertyData();
      getSellTokenData();
      getRentData();
    }
  }, [
    users.length,

    properties.length,

    tokenDetails.length,

    rentListing.length,

    dateFilter,
  ]);

  useEffect(() => {
    selectedDateFilter(" "+currentYear);
  },[]);

  const selectedDateFilter = (year) => {
    let dateSelected = year;
    setDateFilter(dateSelected);
  };

  const widgetUser = {
    type: "users",
    dateInput: dateFilter,
  };

  const widgetInvestor = {
    type: "investor",
    dateInput: dateFilter,
  };

  const widgetProperties = {
    type: "properties",
    dateInput: dateFilter,
  };

  const widgetTotalHouse = {
    type: "totalHouse",
    dateInput: dateFilter,
  };

  const widgetTotalTokens = {
    type: "totalTokens",
    dateInput: dateFilter,
  };

  const widgetTotalHousePrice = {
    type: "totalHousePrice",
    dateInput: dateFilter,
  };

  const widgetUserTotalGrossRentMonth = {
    type: "totalGrossRentMonth",
    dateInput: dateFilter,
  };

  const widgetUserTotalGrossRentYear = {
    type: "totalGrossRentYear",
    dateInput: dateFilter,
  };

  const widgetUserTotalNetRentMonth = {
    type: "totalNetRentMonth",
    dateInput: dateFilter,
  };

  return (
    <div className="home">
      <Sidebar />

      <div className="homeContainer">
        <div style={{ float: "right" }}>
          
          <select defaultValue={currentYear}
            onChange={(e) => selectedDateFilter(e.target.value)}
            className="w-60 border text-black p-3 2xl:text-xl"
            style={{ float: "right", marginBottom: "20px" }} >
              
             

            <option value="2022">
              2022
            </option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
        <Navbar />

        <br></br>
        <div className="widgets">
          <Widget widget={widgetUser} />
          <Widget widget={widgetInvestor} />
          <Widget widget={widgetProperties} />
          <Widget widget={widgetTotalHouse} />
          <Widget widget={widgetTotalTokens} />
        </div>
        <div className="widgets">
          <Widget widget={widgetTotalHousePrice} />
          <Widget widget={widgetUserTotalGrossRentMonth} />
          <Widget widget={widgetUserTotalGrossRentYear} />
          <Widget widget={widgetUserTotalNetRentMonth} />
        </div>
        <div className="widgets">
          <Card
            style={{
              width: "50%",
              boxShadow: "2px 2px #EEEEEE",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  color: "#A0A0A0",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Users
              </Card.Title>
              <Card.Text>
                <Bar data={data} />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            style={{
              width: "50%",
              boxShadow: "2px 2px #EEEEEE",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  color: "#A0A0A0",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Properties
              </Card.Title>
              <Card.Text>
                <Bar data={propertyData} />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="widgets">
          <Card
            style={{
              width: "50%",
              boxShadow: "2px 2px #EEEEEE",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  color: "#A0A0A0",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Sell <span translate="no"> Tokens </span>
              </Card.Title>
              <Card.Text>
                <Bar data={sellTokenData} />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            style={{
              width: "50%",
              boxShadow: "2px 2px #EEEEEE",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  color: "#A0A0A0",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Distributed Rent
              </Card.Title>
              <Card.Text>
                <Bar data={rentData} />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
    // ok
  );
};

export default Dashboard;
