import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deltedFaq, fetchFaq } from "../../redux/features/faqSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";

const Faqs = () => {
  const dispatch = useDispatch();

  const { faqs } = useSelector((state) => ({
    ...state.faq,
  }));
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Faq data?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deltedFaq({ id, toast }));
      }
    });
  };

  const faqColumns = [
    {
      field: "question",
      headerName: "Question",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 600,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/faq/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchFaq());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: 'flex', justifyContent: 'end' }}>
              <Link
                to={{
                  pathname: `/admin/faq/new`,
                }}
                className="link"
              >
                Add New Faq
              </Link>
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={faqs ? faqs : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={faqColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
