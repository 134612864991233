import React, { useEffect } from "react";
import { Spinner, UserSideBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../redux/features/orderSlice";
import { OrderDetails } from "../components";
import { logDOM } from "@testing-library/react";
import { toast } from "react-toastify";

const Order = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrder());
  }, [dispatch]);

  const { orders, loading } = useSelector((state) => state.order);
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                My <strong>Account</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />
            <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
              <div id="tabContentExample">
                <div className="table-layout tableBox">
                  <table className="table-fixed w-full">
                    <thead>
                      <tr className="bg-blue-900">
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Order
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Date
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Status
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Total
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.length === 0 && <h1>No Orders found</h1>}

                      {orders &&
                        orders.map((item) => (
                          <OrderDetails key={item.id} item={item} />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Order;
