import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { forgotpassword } from "../redux/features/authSlice";
import { Spinner } from "../components";
const initialState = {
  email: "",
};

const ForgotPassword = () => {
  const [formValue, setFormValue] = useState(initialState);
  const { loading, error } = useSelector((state) => ({ ...state.auth }));
  const { email } = formValue;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(forgotpassword({ formValue, navigate, toast }));
    }
  };

  const onInputChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <>
      <section id="register-form">
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-center bg-gray-100">
            <div className="centered-div">
              <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-2 lg:px-24">
                <h2 className="text-center text-4xl font-bold text-blue-800 mb-5">
                  Forgot<span className="font-normal ml-2">Password</span>
                </h2>
                <form
                  className="flex flex-col pt-3 md:pt-8 p-8 bg-gray-100 mt-5"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col pt-4">
                    <label htmlFor="email" className="text-lg font-normal">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="your@email.com"
                      value={email}
                      onChange={onInputChange}
                      required
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                  </div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 max-w-xs rounded-full m-auto w-full"
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-1/2 shadow-2xl relative bg-black">
            <img
              className="object-cover w-full min-h-screen hidden lg:block xl:block  h-full"
              src="assets/images/bg.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
