import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { fetchRentListing } from "../../redux/features/orderSlice";
import { getClimedRent, getDistributedRentdetailsFunction } from "../../redux/features/rentSlice";
import { updateRentDistribution, getDistributedRentdetails, genrateRentReport, updateClaimedRentDistribution } from "../../redux/api";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { formatNumber, formatDate } from "../../utils/Formatter";
import { DataGrid } from "@mui/x-data-grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Loader from "./Loader";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { REACT_APP_BASE_API_URL } = process.env;

const Rents = () => {
  const dispatch = useDispatch();
  const [isProcessing, setisProcessing] = useState(false);
  const { rentListing } = useSelector((state) => ({
    ...state.order,
  }));
  const { rentCompletedPayment } = useSelector((state) => ({
    ...state.rent,
  }));
  const { claimedRent } = useSelector((state) => ({
    ...state.rent,
  }));

  // Fetching transfereed payment details
  useEffect(() => {
    dispatch(getDistributedRentdetailsFunction());
    dispatch(getClimedRent());
  }, [dispatch]);

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };

  const handleSendPayment = async (id, receiverAddress, amount) => {
    if (window.networkChain != "Ethereum" && window.networkChain != "Polygon") {
      return toast.warning("Please Connect With Valid Blokchain Network");
    }

    setisProcessing(true);
    let _amount = amount * 1e6;
    await window.TOKENCONTRACT.methods
      .transfer(receiverAddress, _amount.toString())
      .send({ from: window.walletAddress })
      .then((d) => {
        // Update transaction into database after success payemnt

        let formData = { transactionHash: d.transactionHash,ctyptoNetwork:window.networkChain };
        updateRentDistribution(formData, id)
          .then((res) => {
            toast.success(res.data.message);
            dispatch(fetchRentListing());
            dispatch(getClimedRent());
            setisProcessing(false);
          })
          .catch((error) => {
            console.log(error);
            setisProcessing(false);
            return toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        console.log(error);
        return toast.error(
          "Something went wrong, \n Transaction not performed"
        );
      });
    setisProcessing(false);
  };
  const handleClaimedSendPayment = async (id, receiverAddress, amount,claimedCtyptoNetwork) => {
    if (window.networkChain != claimedCtyptoNetwork) {
      return toast.warning(`Please Connect With ${claimedCtyptoNetwork} Network`);
    }

    setisProcessing(true);
    let _amount = amount * 1e6;
    await window.TOKENCONTRACT.methods
      .transfer(receiverAddress, _amount.toString())
      .send({ from: window.walletAddress })
      .then((d) => {
        // Update transaction into database after success payemnt

        let formData = { transactionHash: d.transactionHash,
          ctyptoNetwork:claimedCtyptoNetwork };
        updateClaimedRentDistribution(formData, id)
          .then((res) => {
            toast.success(res.data.message);
            dispatch(fetchRentListing());
            dispatch(getClimedRent());
            setisProcessing(false);
          })
          .catch((error) => {
            console.log(error);
            setisProcessing(false);
            return toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        console.log(error);
        return toast.error(
          "Something went wrong, \n Transaction not performed"
        );
      });
    setisProcessing(false);
  };

  const RentDistributeColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 180,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 6) + "..."+params?.row?.User_Details?.walletAddress.substring((params?.row?.User_Details?.walletAddress).length -6)}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "PropertyAddress",
      headerName: "Number of Property",
      width: 250,
      valueGetter: (params) =>
        `${params?.row?.propertyId}`,
    },
    // {
    //   field: "totalPropertyToken",
    //   headerName: "Total Property Tokens",
    //   width: 200,
    //   valueGetter: (params) => {
    //     return params?.row?.totalPropertyToken;
    //   },
    // },

    {
      field: "monthlyRent",
      headerName: "Net Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userNetMonthlyRent)}`;
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: (params) => {
        return (
          <>
            <div className="cellAction">
              {!isProcessing ? (
                params.row.hasTransferred ? (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Transferred"
                        color="success"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      handleSendPayment(
                        params.row.userId,
                        params.row.User_Details.walletAddress,
                        params.row.userNetMonthlyRent
                      )
                    }
                  >
                    ${formatNumber(params.row.userNetMonthlyRent, 4)} Send Payment
                  </Button>
                )
              ) : (
                // <button disabled className="publishContract" id="paymentbtn">
                //   Processing...
                // </button>
                <></>



              )}
            </div>

          </>

        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/admin/rentdetails/${params.row.userId}`}>
              <Button
                variant="contained"
              >
                View Details

              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  const RentTransferedColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address ",
      width: 180,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 6) + "...."+params?.row?.User_Details?.walletAddress.substring((params?.row?.User_Details?.walletAddress).length -6)}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) =>
        `${params?.row?.Property_Details?.PropertyAddress}`,
    },

    {
      field: "monthlyRent",
      headerName: "Property Total Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare)}`;
      },
    },
    {
      field: "totalPropertyToken",
      headerName: "Total Property Tokens",
      width: 200,
      valueGetter: (params) => {
        return `${formatNumber(params?.row?.totalPropertyToken)}`;
      },
    },
    {
      field: "totalTokens",
      headerName: "Total Property Tokens",
      width: 200,
      valueGetter: (params) => {
        return `${formatNumber(params?.row?.totalTokens)}`;
      },
    },
    {
      field: "userGrossMonthlyRentShare",
      headerName: "User Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare, 4)}`;
      },
    },
    {
      field: "platformFeeShare",
      headerName: "Plateform Fee",
      width: 150,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.platformFeeShare, 4)}`;
      },
    },
    {
      field: "propertyFeeShare",
      headerName: "Property Management Fee",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.propertyFeeShare, 4)}`;
      },
    },
    {
      field: "userNetMonthlyRent",
      headerName: "User Net Monthly Rent",
      width: 170,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userNetMonthlyRent, 4)}`;
      },
    },
    {
      field: "rentOfDays",
      headerName: "Rent of Days",
      width: 120,
      valueGetter: (params) => {
        return params?.row?.rentOfDays;
      },
    },
    {
      field: "createdAt",
      headerName: "Rent Generated Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.createdAt);
      },
    },
    {
      field: "rentDate",
      headerName: "Rent of Month",
      width: 150,
      valueGetter: (params) => {
        return moment(params?.row.rentDate).format("MMMM, YYYY");
      },
    },
    {
      field: "rentSentDate",
      headerName: "Rent Transfer Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.rentSentDate);
      },
    },
    {
      field: "transactionHash ",
      headerName: "Transaction Hash",
      width: 180,
      renderCell: (params) => {
        return params?.row?.transactionHash ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.transactionHash.substring(0, 6) + "...."+params?.row?.transactionHash.substring((params?.row?.transactionHash).length -6)}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.transactionHash)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "claimedCtyptoNetwork",
      headerName: "Payout Network",
      width: 150,
      valueGetter: (params) => {
        return (params?.row.claimedCtyptoNetwork);
      },
    },
    {
      field: "Action",
      headerName: "Payment Status",
      width: 150,
      renderCell: (params) => {
        return (<>
          {
            params.row.hasTransferred ?
              (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Transferred"
                    color="success"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
              : (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Pending"
                    color="error"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
          }
        </>);
      },
    },
  ];

  const ClaimedRentColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 180,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 6) + "..."+params?.row?.User_Details?.walletAddress.substring((params?.row?.User_Details?.walletAddress).length -6)}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },    
    {
      field: "claimedCtyptoNetwork",
      headerName: "Claimed Blockchain Network",
      width: 205,
      valueGetter: (params) => `${params?.row?.claimedCtyptoNetwork}`
    },
    {
      field: "monthlyRent",
      headerName: "Net Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `${formatNumber(params?.row?.userNetMonthlyRent)}`;
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: (params) => {
        return (
          <>
            <div className="cellAction">
              {!isProcessing ? (
                params.row.hasTransferred ? (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Transferred"
                        color="success"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      handleClaimedSendPayment(
                        params.row.userId,
                        params.row.User_Details.walletAddress,
                        params.row.userNetMonthlyRent,params.row.claimedCtyptoNetwork
                      )
                    }
                  >
                    ${formatNumber(params.row.userNetMonthlyRent, 4)} Send Payment
                  </Button>
                )
              ) : (
                <></>
              )}
            </div>

          </>

        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/admin/claimedrent-details/${params.row.userId}/${params.row.claimedCtyptoNetwork}`}>
              <Button
                variant="contained"
              >
                View Details

              </Button>
            </Link>
          </div>
        );
      },
    },
  ];


  useEffect(() => {
    dispatch(fetchRentListing());
    dispatch(getClimedRent());
  }, [dispatch]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const gernrateReport = () => {
    setOpen(false);
    let formData = { startData: startDate, toDate: toDate };
    genrateRentReport(formData)
      .then((res) => {
        toast.success(res.data.message);
        if (res?.data?.path) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data?.path}`);
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = () => {
    // setAgreeActive(false);
    setOpen(false);
  };

  const handlelOpen = () => {
    // setAgreeActive(false);
    setOpen(true);
  };

  return (

    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate Property Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="formCustom">
                <lable>Select From Data</lable>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="p-3 rounded text-md form-control  border  focus:ring-1" />
              </div>
              <div className="formCustom">
                <lable>Select To Data</lable>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} className="p-3 rounded text-md form-control border  focus:ring-1" />
              </div>
              <div className="mt-4">
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>

            </Button>
            <div className="moadl-footer">
              <button className="btnCancel" onClick={handleClose}>Cancel</button>
              <button className="btnReport" onClick={gernrateReport}>Generate Report</button>
            </div>


          </DialogActions>
        </Dialog>
      </div>

      <div className="list">
        {isProcessing ? (<> <Loader /></>) : (<></>)}

        <Sidebar />
        <div className="listContainer">
          <Navbar />


          <Box>
            <div className="datatable sell-dist" style={{ display: "flex", justifyContent: "space-between"}}>

              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Pending" />
                  <Tab label="Completed" />
                  <Tab label="Claimed"/>
                </Tabs>
              </Box>
              <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>
                <button onClick={handlelOpen} className="btn link" > Generate Report </button>
              </div>
            </div>
            <Box>
              {tabIndex === 0 && (
                <Box>
                  <Typography>
                    <div className="datatable">
                      <DataGrid
                        className="datagrid"
                        disableSelectionOnClick
                        rows={rentListing ? rentListing : []}
                        getRowId={(row) => row?.id || row?.data?.id}
                        columns={RentDistributeColumn}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                      />
                    </div>
                  </Typography>
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <Typography>
                    <div className="datatable">
                      <DataGrid
                        className="datagrid"
                        disableSelectionOnClick
                        rows={rentCompletedPayment ? rentCompletedPayment : []}
                        getRowId={(row) => row?.id || row?.data?.id}
                        columns={RentTransferedColumn}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                      />
                    </div>
                  </Typography>
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  <Typography>
                    <div className="datatable">
                      <DataGrid
                        className="datagrid"
                        disableSelectionOnClick
                        rows={claimedRent ? claimedRent : []}
                        getRowId={(row) => row?.id || row?.data?.id}
                        columns={ClaimedRentColumn}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                      />
                    </div>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>


        </div>
      </div>
    </>
  );
};

export default Rents;
