import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTeam, getTeam } from "../../redux/features/teamSlice";
import FileBase from "react-file-base64";
import { Spinner } from "../../components";

const initialState = {
  name: "",
  profile: "",
  description: "",
};

const NewTeam = () => {
  const [file, setFile] = useState("");

  const [teamData, setTeamData] = useState(initialState);
  const { error, loading, team } = useSelector((state) => ({ ...state.team }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, profile, imageFile } = teamData;
  const { teamId } = useParams();

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setTeamData({ ...teamData, [name]: value });
  };

  const handleSubmit = (e) => {
    if (!teamData.imageFile) {
      toast.error("Please select profile image");
    }

    e.preventDefault();
    if (name && profile && teamData.imageFile) {
      const updatedTeamData = { ...teamData };
      dispatch(createTeam({ updatedTeamData, dispatch, navigate, toast }));
    }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    setTeamData({ ...team });
  }, [team]);

  useEffect(() => {
    dispatch(getTeam(teamId));
  }, []);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div className="bottom">
            <div className="left">
              <img src={imageFile} alt="" />
            </div>
            <div className="right editprofile">
              <form onSubmit={handleSubmit} className="addTeam-form">
                <div>
                  <div className="formInput">
                    <label htmlFor="file">
                      Image: <DriveFolderUploadOutlinedIcon className="icon" />
                    </label>
                    <input type="file" id="file" style={{ display: "none" }} />
                    <FileBase
                      type="file"
                      multiple={false}
                      onChange={(e) => setFile(e.target.files[0])}
                      onDone={({ base64 }) =>
                        setTeamData({ ...teamData, imageFile: base64 })
                      }
                      required={true}
                    />
                  </div>
                  <div className="formInput">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="formInput">
                    <label>Profile</label>
                    <input
                      type="text"
                      name="profile"
                      id="profile"
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="formInput">
                    <label>Description</label>
                    <textarea
                      id="Description"
                      name="description"
                      spellCheck="false"
                      rows="4"
                      cols="50"
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </div>

                <br />
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Create</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewTeam;
