import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlogCard, Spinner } from "../components";
import { getBlogs } from "../redux/features/blogSlice";
function Blog() {
  const { blogs, loading } = useSelector((state) => ({
    ...state.blog,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      <section className="py-5 bg-grey">
        <div className="container mx-auto">
          <div className="flex">
            <div className="w-full">
              <div className="blog text-center">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            {blogs.length === 0 && <h1>No Blogs found</h1>}

            {blogs &&
              blogs.map((item, index) => <BlogCard key={index} {...item} />)}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
