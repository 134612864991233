import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Web3 from "web3";
import ABI from "../_abi.json";
import {
  getUserTokenAddress,
  getUserProperyDetail,
  updateSellToken,
  getFee,
  getMyFee
} from "../redux/api";
import { formatNumber } from "../utils/Formatter";
const { REACT_APP_ADMMIN_WALLET_ADDRESS } = process.env;

const SellToken = () => {
  const [numberOfToken, setnumberOfToken] = useState(0);
  const [sellAmount, setsellAmount] = useState(0);
  const [netAmount, setnetAmount] = useState(0);
  const [plateFromFee, setPlateFromFee] = useState(2);
  const [selcedoFeeAmount, setselcedoFeeAmount] = useState(0);
  const [properties, setproperties] = useState([]);
  const [myPropety, setmyPropety] = useState("");
  const [propertyTokenPrice, setpropertyTokenPrice] = useState(0);
  const [properyTokenContract, setproperyTokenContract] = useState("");
  const [propertyCryptoNetwork, setpropertyCryptoNetwork] = useState("");
  const [isValueVerified, setisValueVerified] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [payoutChain, setpayoutChain] = useState("Polygon");


  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("profile"));
    const Id = userInfo?.id;
    getUserTokenAddress(Id)
      .then((data) => {
        setproperties(data?.data?.getPropertiesAddAndId);
      })
      .catch((err) => {
        console.log(err);
      });
      getMyFee({feeType:"SELL_FEE"})
      .then((res) => {
        setPlateFromFee(res?.data?.fetchFee?.percent);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ChangeNumberOfToken = async (e) => {
    setnumberOfToken(e.target.value);
    setsellAmount(e.target.value * propertyTokenPrice);
    setselcedoFeeAmount(
      (e.target.value * propertyTokenPrice * plateFromFee) / 100
    );
    setnetAmount(
      e.target.value * propertyTokenPrice -
      (e.target.value * propertyTokenPrice * plateFromFee) / 100
    );
  };

  const handleChangeProperty = (e) => {
    setmyPropety(e.target.value);
    let formData = { propertyId: e.target.value };
    getUserProperyDetail(formData)
      .then((data) => {
        setpropertyTokenPrice(data?.data?.data?.perTokenPrice);
        setproperyTokenContract(data?.data?.data?.SmartContractAddress);
        setpropertyCryptoNetwork(data?.data?.data?.network);
        setsellAmount(numberOfToken * data?.data?.data?.perTokenPrice);
        setselcedoFeeAmount(
          (numberOfToken * data?.data?.data?.perTokenPrice * plateFromFee) / 100
        );
        setnetAmount(
          numberOfToken * data?.data?.data?.perTokenPrice -
          (numberOfToken * data?.data?.data?.perTokenPrice * plateFromFee) / 100
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sellPropertyToken = async (amount, sellTokenContract,propertyCryptoNetwork) => {
    if (!amount) return toast.error("Net Amount Must Be Positive");

    if (!isValueVerified)
      return toast.warning("Please Checked the Box as values are correct ");

    if (window.networkChain != propertyCryptoNetwork) {
      return toast.warning("Please Connect With " +`${propertyCryptoNetwork}`+" Network");
    }
    let userInfo = JSON.parse(localStorage.getItem("profile"));
    const Id = userInfo?.id;

    try {
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          window.walletAddress = result[0];
          localStorage.setItem("accountAddress", result[0]);
        });
    } catch (error) {
      console.log(error);
    }
    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    const TOKENCONTRACT_ADDRESS = sellTokenContract;
    var TOKENCONTRACT = new web3.eth.Contract(ABI, TOKENCONTRACT_ADDRESS);
    setisProcessing(true);
    let _numberOfToken = (numberOfToken + "000000000000000000").toString();
    let receiverAddress = REACT_APP_ADMMIN_WALLET_ADDRESS;
    await TOKENCONTRACT.methods
      .transfer(receiverAddress, _numberOfToken)
      .send({ from: window.walletAddress })
      .then((d) => {
        // Update transaction into database after success payemnt

        var formData = {
          propertyId: myPropety,
          userId:Id,
          transactionHash: d?.transactionHash,
          saleToken: numberOfToken,
          totalAmount: sellAmount,
          plateformFee: selcedoFeeAmount,
          netAmount: netAmount,
          fromAddress: d?.from,
          toAddress: d?.to,
          payoutCryptoNetwork:payoutChain
        };

        updateSellToken(formData)
          .then((res) => {
            setisProcessing(false);
            toast.success("Token Successfully Sold");
          })
          .catch((error) => {
            console.log(error);
            return toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        console.log(error);
        setisProcessing(false);
        return toast.error(
          "Something went wrong, \n Transaction not performed"
        );
      });
  };

  const accpectValue = (e) => {
    if (e.target.checked) setisValueVerified(true);
    else setisValueVerified(false);
  };

  return (
    <>
      <section id="selltoken" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <div className="w-full">
              <div className="flex justify-between items-center">
                <h2 className="m-0 sm:text-4xl text-xl font-bold text-black">
                  Sell <span translate="no"> Tokens </span>
                </h2>
                <div className="button-back">
                  <a
                    href="/#"
                    className="rounded-full bg-blue-900 text-white view px-10 py-2 inline-block"
                  >
                    {" "}
                    Back to Asset
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col mt-5">
            <div className="w-full">
              <p className="font-normal 2xl:text-xl text-black text-justify">
                { }
                <span translate="no"> SPACIOS </span>could get to buy back your tokens. This exceptional service has time and quantity limitations and conditions.
                {/* to <b>${formatNumber(2000)} per week.</b>{" "} */}
              </p>
              <p className="font-normal 2xl:text-xl text-black text-justify">
                <span translate="no"> SPACIOS </span>will evaluate each particular situation within 90 business days. There are pre-established limits{" "}
                <b>60 business days.</b> There are pre-established limits .{" "}
              </p>
            </div>
          </div>
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col mt-10">
            <div className="w-full">
              <div className="border-2 border-blue-900 p-5 bg-white">
                <div className="text-center">
                  <p className="font-normal 2xl:text-xl text-black text-center">
                  With prior agreement and fulfilling conditions we can buy back certain <span translate="no"> tokens </span>{" "}
                  </p>
                  <div className="rounded-full bg-blue-900 text-white view px-10 py-2 inline-block text-white mt-3">
                    Sold Out
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col mt-10">
            <div className="w-full">
              <div className="border-2 border-blue-900 p-5 bg-white">
                <div className="text-center">
                  <p className="font-normal 2xl:text-xl text-black text-center">
                    <span translate="no"> Token </span> buyback payments will be sent to the selected address
                    and network.
                  </p>
                  <div className="lg:w-1/2 m-auto mt-5 width60">
                    <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row">
                      <div className="w-1/2 border-r-2 border-gray-200 p-2 bg-blue-900 text-white text-left">
                        PAYOUT NETWORK
                      </div>
                      <div className="w-1/2 border-blue-900 p-2 bg-blue-900 text-white text-left">
                        DESTINATION
                      </div>
                    </div>
                    <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row">
                      <div className="w-1/2 border-2 border-gray-200 p-2 bg-white text-left text-black">
                        <div className="form-input">
                          <span>
                            <input className="mr-2" name="payout" value="Ethereum" type="radio" checked={payoutChain=="Ethereum"} onChange={(e)=> setpayoutChain(e.target.value)} />
                            <span translate="no">Ethereum</span>
                          </span>
                          <span className="lg:ml-5 sm:inline block">
                            {" "}
                            <input className="mr-2" name="payout" value="Polygon" type="radio" checked={payoutChain=="Polygon"} onChange={(e)=> setpayoutChain(e.target.value)} />
                            Polygon (Matic)
                          </span>
                        </div>
                      </div>
                      <div className="w-1/2 border-2 border-gray-200 border-l-0 p-2 bg-white  text-left text-black">
                        <div className="flex  align-center ">
                          <a
                            className="text-blue-900 text-md underline font-medium breakWord"
                            href="/#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {REACT_APP_ADMMIN_WALLET_ADDRESS}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col mt-10">
            <div className="w-full shadow p-5 bg-white">
              <div className="table-responsive overflow-auto">
                <table className="table w-full overflow-auto">
                  <thead className="border-b bg-gray-100">
                    <tr className="table-row">
                      <th className="p-5 text-left text-blue-900 font-bold 2xl:text-xl whitespace-nowrap">
                        Property
                      </th>
                      <th className=" p-5 text-left text-blue-900 font-bold 2xl:text-xl whitespace-nowrap">
                        <span translate="no"> Tokens </span>
                      </th>
                      <th className="p-5 text-right text-blue-900 font-bold 2xl:text-xl whitespace-nowrap">
                        Price / <span translate="no"> Tokens </span>
                      </th>
                      <th className=" p-5 text-right text-blue-900 font-bold 2xl:text-xl whitespace-nowrap">
                        Sale Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-row">
                      <td className="p-5 text-left">
                        <select
                          className="w-72 border text-black p-3 2xl:text-xl"
                          onChange={handleChangeProperty}
                        >
                          <option value="0"> Select a properties </option>
                          {properties.map((property) => (
                            <option value={property?.id}>
                              {property?.address}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="p-5 text-left">
                        <input
                          type="text"
                          className="w-72 border text-black p-3 2xl:text-xl"
                          value={numberOfToken}
                          onChange={ChangeNumberOfToken}
                          placeholder="Enter Number of Token"
                        />
                        {/* defaultValue={0} */}
                      </td>
                      <td className=" p-5 text-right 2xl:text-xl">
                        {propertyTokenPrice}
                      </td>
                      <td className="p-5 text-right 2xl:text-xl">
                        ={" "}
                        <span className="font-bold">
                          ${formatNumber(sellAmount)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col mt-10">
            <div className="md:w-1/2 w-full" />
            <div className="md:w-1/2 w-full">
              <div className="total-table  p-3 flex justify-end w-full">
                <table className="table max-w-screen-sm w-full">
                  <tbody>
                    <tr>
                      <td className="text-left text-blue-900 font-bold 2xl:text-lg whitespace-nowrap">
                        SUBTOTAL :
                      </td>
                      <td className="text-right text-black font-bold 2xl:text-lg">
                        ${formatNumber(sellAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left text-blue-900 font-bold 2xl:text-lg whitespace-nowrap">
                        <span translate="no"> SPACIOS </span> FEE ({plateFromFee}%)
                      </td>
                      <td className="text-right text-black font-bold 2xl:text-lg">
                        - ${formatNumber(selcedoFeeAmount)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="text-left text-blue-900 font-bold 2xl:text-lg whitespace-nowrap pb-5">
                        PROCESSOR FEE ({processFee}%)
                      </td>
                      <td className="text-right text-black font-bold 2xl:text-lg pb-5">
                        - ${processFeeAmount.toFixed(2)}
                      </td>
                    </tr> */}
                  </tbody>
                  <tfoot className="">
                    <tr>
                      <th className="text-left text-blue-900 font-bold 2xl:text-lg whitespace-nowrap pt-3 border-t-2 border-black">
                        TOTAL SALE
                      </th>
                      <th className="text-right text-black font-bold 2xl:text-xl whitespace-nowrap pt-3 border-t-2 border-black">
                        $ {formatNumber(netAmount)}
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={2} className="pt-10">
                        <input
                          type="checkbox"
                          onChange={(e) => accpectValue(e)}
                        />{" "}
                        I verify that these values are correct.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="pt-5 text-right">
                        {!isProcessing ? (
                          <button
                            className="rounded-full bg-blue-900 text-white view px-10 py-2 inline-block"
                            onClick={(e) =>
                              sellPropertyToken(netAmount, properyTokenContract, propertyCryptoNetwork)
                            }
                          >
                            Sell <span translate="no"> Tokens </span>
                          </button>
                        ) : (
                          <button className="rounded-full bg-blue-900 text-white view px-10 py-2 inline-block">
                            Sell <span translate="no"> Tokens </span> <i className="fa fa-spinner fa-spin"></i>{" "}
                          </button>
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellToken;
