import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteLearn, fetchLearn } from "../../redux/features/learnSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";

const Learns = () => {
  const dispatch = useDispatch();

  const { learns } = useSelector((state) => ({
    ...state.learn,
  }));
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete it?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLearn({ id, toast }));
      }
    });
  };

  const learnColumns = [
    {
      field: "title",
      headerName: "Title",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 260,
    },
    {
      field: "summary",
      headerName: "summary",
      width: 270,
    },
    {
      field: "externalLink",
      headerName: "external_link",
      width: 220,
    },
    {
      field: "linkTitle",
      headerName: "linkTitle",
      width: 220,
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/learn/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchLearn());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>
              <Link
                to={{
                  pathname: "/admin/learn/new",
                }}
                className="link"
              >
                Add New Learn
              </Link>
            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={learns ? learns : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={learnColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Learns;
