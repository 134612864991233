import React, { useEffect } from "react";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUser } from "../../redux/features/authSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const { REACT_APP_BASE_API_URL } = process.env;

const Single = () => {
  const { viewUser } = useSelector((state) => ({ ...state.auth }));
  const user = viewUser;
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id, dispatch]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{user?.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{"+"+user?.phoneCode +" "+ user?.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email Status:</span>
                  <span className="itemValue">{user?.emailStatus}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Postal Code:</span>
                  <span className="itemValue">{user?.postalCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{user?.country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Dob:</span>
                  <span className="itemValue">{user?.dob}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Billing Countrty:</span>
                  <span className="itemValue">{user?.billingCountry}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Billing Street:</span>
                  <span className="itemValue">{user?.billingStreet}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Billing City:</span>
                  <span className="itemValue">{user?.billingCity}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Identification Countrty:</span>
                  <span className="itemValue">
                    {user?.identificationCountry}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Document Status:</span>
                  <span className="itemValue">{user?.document_status}</span>
                </div>
              </div>
              <div className="details">
                <div className="detailItem">
                  <span className="itemValue">
                    <img
                      src={`${REACT_APP_BASE_API_URL}${
                        user?.image ? user?.image : "uploads/images.png"
                      }`}
                      alt=""
                    />
                  </span>
                </div>
              </div>
              {user?.idFront ? (
                <div className="details">
                  <div className="detailItem">
                    <span className="itemValue">
                      <img
                        alt=""
                        width={400}
                        src={`${REACT_APP_BASE_API_URL}${
                          user?.idFront ? user?.idFront : "uploads/images.png"
                        }`}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {user?.idBack ? (
                <div className="details">
                  <div className="detailItem">
                    <span className="itemValue">
                      <img
                        alt=""
                        width={400}
                        src={`${REACT_APP_BASE_API_URL}${
                          user?.idBack ? user?.idBack : "uploads/images.png"
                        }`}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="editButton">
              <Button variant="contained">
                <Link to={`/admin/users/edit/${id}`}>Change Status</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Single;
