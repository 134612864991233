import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermById } from "../redux/features/termsSlice";

const TermsCondition = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTermById());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const { terms } = useSelector((state) => ({
    ...state.term,
  }));

  return (
    <section id="property" className="py-12">
      <div className="container mx-auto">
        <section
          dangerouslySetInnerHTML={{ __html: terms }}
          className="SearchResult-body"
        />
      </div>
    </section>
  );
};

export default TermsCondition;
