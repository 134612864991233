import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { formatPercentage, formatNumber } from "../utils/Formatter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createInvoice } from "../redux/api";
import React, { useState } from "react";

const { REACT_APP_BASE_API_URL } = process.env;

const SellTokensHistory = ({ item }) => {
  const [isProcessingPdf, setisProcessingPdf] = useState(false);

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };

  return (
    <> 
      <tr className="bg-white">

        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {moment(item?.createdAt).format("MMMM Do YYYY")}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {formatNumber(item?.totalAmount)}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {formatNumber(item?.netAmount)}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.payoutCryptoNetwork}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.transactionHash ? ( <>
          {item?.transactionHash?.substring(0, 6)+ "...."+ item?.transactionHash?.substring(
              (item?.transactionHash).length -6) }            
           <ContentCopyIcon
              onClick={(e) =>
                clipboard(item?.transactionHash)
              }
              index={item?.id}
            />
            </> ) : ""}
        </td>   
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.isVerified ? "Verified" : "Pending"}
        </td>  
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.isTransferred ? "Accepted" : "Pending"}
        </td>   
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.TransferredUsdcHash ? ( <>
          {item?.TransferredUsdcHash?.substring(0, 6)+ "...."+ item?.TransferredUsdcHash?.substring(
              (item?.TransferredUsdcHash).length -6) }            
           <ContentCopyIcon
              onClick={(e) =>
                clipboard(item?.TransferredUsdcHash)
              }
              index={item?.id}
            />
            </> ) : ""}
        </td>    
      </tr>
    </>
  );
};

export default SellTokensHistory;
