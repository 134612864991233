import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/features/authSlice";
import { Spinner } from "../../components";
import { getUser } from "../../redux/features/authSlice";

const NewUser = () => {
  const { id } = useParams();
  const { error, loading } = useSelector((state) => ({ ...state.auth }));

  const { viewUser } = useSelector((state) => ({ ...state.auth }));
  const user = viewUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target).entries());
    if (id) {
      dispatch(updateUser({ id, formData, navigate, toast, dispatch }));
    }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id, dispatch]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={user?.firstName}
                  />
                </div>
                <div className="formInput">
                  <label>last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={user?.lastName}
                  />
                </div>
                <div className="formInput">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={user?.email}
                    disabled
                  />
                </div>
                <div className="formInput">
                  <label>Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    value={user?.country}
                  />
                </div>
                <div className="formInput">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    value={user?.billingPostalCode}
                  />
                </div>
                <div className="formInput">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={"+"+user?.phoneCode +" " +user?.phone}
                  />
                </div>
                <div className="formInput">
                  <label>Document Status</label>
                  <select name="document_status">
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
                <div className="formInput">
                  <label>Comment</label>
                  <textarea
                    name="comment"
                    // value={user?.comment ? user?.comment : " "}
                    rows={5}
                    cols={40}
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button type="submit" className="btnsubmit">
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUser;
