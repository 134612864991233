import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setLogout } from "../../redux/features/authSlice";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import BookIcon from "@mui/icons-material/Book";
import QuizIcon from "@mui/icons-material/Quiz";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GavelIcon from "@mui/icons-material/Gavel";

const Sidebar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLogout());
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span translate="no" className="logo">
            SPACIOS
          </span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/admin" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/admin/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/admin/properties" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Properties</span>
            </li>
          </Link>
          <Link to="/admin/order" style={{ textDecoration: "none" }}>
            <li>
              <ShoppingCartIcon className="icon" />
              <span>Order</span>
            </li>
          </Link>
          <Link to="/admin/rent" style={{ textDecoration: "none" }}>
            <li>
              <AccountBalanceIcon className="icon" />
              <span>Rent Distribution</span>
            </li>
          </Link>
          <Link to="/admin/selltokenlist" style={{ textDecoration: "none" }}>
            <li>
              <ShoppingCartIcon className="icon" />
              <span translate="no">
                Sell Token
              </span>
            </li>
          </Link>
          <Link to="/admin/team" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Team</span>
            </li>
          </Link>
          <Link to="/admin/learn" style={{ textDecoration: "none" }}>
            <li>
              <LightbulbIcon className="icon" />
              <span>Learn</span>
            </li>
          </Link>
          <Link to="/admin/blogs" style={{ textDecoration: "none" }}>
            <li>
              <BookIcon className="icon" />
              <span>Blog</span>
            </li>
          </Link>
          <Link to="/admin/faq" style={{ textDecoration: "none" }}>
            <li>
              <QuizIcon className="icon" />
              <span translate="no">
                 FAQ 
              </span>
            </li>
          </Link>

          <Link to="/admin/subscribe" style={{ textDecoration: "none" }}>
            <li>
              <EmailIcon className="icon" />
              <span>Subscribe</span>
            </li>
          </Link>
          <Link to="/admin/social" style={{ textDecoration: "none" }}>
            <li>
              <PublicIcon className="icon" />
              <span>Social Media</span>
            </li>
          </Link>
          <Link to="/admin/email" style={{ textDecoration: "none" }}>
            <li>
              <ContactMailIcon className="icon" />
              <span>Email Management</span>
            </li>
          </Link>
          <Link to="/admin/propertyEmail" style={{ textDecoration: "none" }}>
            <li>
              <EmailIcon className="icon" />
              <span>Property Email</span>
            </li>
          </Link>
          <Link to="/admin/generatedRentEmail" style={{ textDecoration: "none" }}>
            <li>
              <EmailIcon className="icon" />
              <span>Generated Rent Email</span>
            </li>
          </Link>

          <Link to="/admin/fee" style={{ textDecoration: "none" }}>
            <li>
              <ReceiptLongIcon className="icon" />
              <span>Fee Management</span>
            </li>
          </Link>
          <Link to="/admin/terms" style={{ textDecoration: "none" }}>
            <li>
              <GavelIcon className="icon" />
              <span>Terms and condition</span>
            </li>
          </Link>
          <li>
            <ExitToAppIcon className="icon" />
            <span onClick={() => handleLogout()}>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
