import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createFee = createAsyncThunk(
  "fee/createFee",
  async ({ formdata, dispatch, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createFee(formdata);
      toast.success("Fee Added Successfully");
      navigate("/admin/fee");
      return response?.data?.saveFee;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFee = createAsyncThunk(
  "fee/fetchFee",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchFee();
      return response?.data?.fetchFee;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFee = createAsyncThunk(
  "fee/editFee",
  async ({ feeId, formdata, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.editFee(formdata, feeId);
      toast.success("Fee Content Updated Successfully");
      navigate("/admin/fee");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const feeSlice = createSlice({
  name: "fee",
  initialState: {
    fee: {},
    fees: [],
    error: "",
    loading: false,
  },
  reducers: {
    setFeeDetail: (state, action) => {
      state.fees = action.payload;
    }
  },
  extraReducers: {
    [createFee.pending]: (state, action) => {
      state.loading = true;
    },
    [createFee.fulfilled]: (state, action) => {
      state.loading = false;
      state.fees = [action.payload];
    },
    [createFee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchFee.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchFee.fulfilled]: (state, action) => {
      state.loading = false;
      state.fees = action.payload;
    },
    [fetchFee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [editFee.pending]: (state, action) => {
      state.loading = true;
    },
    [editFee.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.fees = state.fees.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editFee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { setFeeDetail } = feeSlice.actions;
export default feeSlice.reducer;
