import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { formatPercentage, formatNumber } from "../utils/Formatter";
import { createInvoice } from "../redux/api";
import React, { useState } from "react";

const { REACT_APP_BASE_API_URL } = process.env;

const OrderDetails = ({ item }) => {
  const [isProcessingPdf, setisProcessingPdf] = useState(false);

  const createInoice = (orderId) => {
    setisProcessingPdf(true);
    let userInfo = JSON.parse(localStorage.getItem("profile"));
    const Id = userInfo["id"];
    let formData = {
      orderId: orderId,
      id: Id,
    };

    createInvoice(formData)
      .then((res) => {
        setisProcessingPdf(false);
        if (res?.data?.data) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data.data}`, "_blank");
        }
      })
      .catch((error) => {
        console.log(error);
        setisProcessingPdf(false);
        return toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <tr className="bg-white">
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          <Link
            className="product-id text-blue-900 underline"
            to={`/orderdetails/${item?.id}`}
          >
            #{item?.id}
          </Link>
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {moment(item?.date).format("MMMM Do YYYY")}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.paymentStatus}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          $ {formatNumber(item?.totalAmount * 1)} for{" "}
          {formatNumber(item?.totalToken)} items
        </td>
        <td className="border text-center sm:text-sm= xl:text:md text-md p-3">
          <Link
            to={`/orderdetails/${item?.id}`}
            className="rounded-full bg-blue-900 text-white view px-8 py-2"
          >
            View
          </Link>
          {!isProcessingPdf ? (
            item?.invoicePath ? (
              <button style={{padding: "inherit"}}>
              <a
                href={`${REACT_APP_BASE_API_URL}${item?.invoicePath}`}
                className="rounded-full bg-blue-900 text-white view px-8 py-2"
                target="_blank"
              >
                Invoice
              </a>
              </button>
            ) : (
              <>
                {" "}
                <button onClick={() => createInoice(item.id)} style={{padding: "inherit"}}>
                  {" "}
                  <a
                    className="rounded-full bg-blue-900 text-white view px-8 py-2"
                    target="_blank"
                  >
                    Invoice
                  </a>
                </button>{" "}
              </>
            )
          ) : (
            <button style={{padding: "inherit"}}>
              {" "}
              <a
                className="rounded-full bg-blue-900 text-white view px-4 py-2"
                target="_blank"
              >
                Invoice <i class="fa fa-spinner fa-spin"></i>
              </a>
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default OrderDetails;
