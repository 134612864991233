import React, { useEffect } from "react";
import TeamModel from "../components/TeamModel";
import { Spinner } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { getTeams } from "../redux/features/teamSlice";

const Team = () => {
  const { teams, loading } = useSelector((state) => ({
    ...state.team,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <section id="inner-banner" className="bg-gray-700 2xl:h-full">
        <div className="container mx-auto">
          <div className="inner-flex inline-flex-custom">
            <div className="left-content 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-10 sm:pt-12">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                Meet Our Team
              </h2>
              <p className="xl:text-xl lg:text-xl xl:text-xl text-base font-light leading-relaxed mt-0 mb-4 text-white">
                <span translate="no"> SPACIOS </span> seeks to project itself over time as a reliable
                alternative for fractional passive real estate investment
                through <span translate="no"> blockchain </span> technology (real estate tokenization), thus
                generating permanent cash flows for holders of <span translate="no"> tokens </span>
                representing the underlying investment, with earnings
                expectations of capital over time thanks to surplus value.
              </p>
              <p className="xl:text-xl lg:text-xl xl:text-xl text-base font-light leading-relaxed mt-0 mb-4 text-white">
                <span translate="no"> SPACIOS </span> is managed by a team of real estate investors with
                experience in the world of lawyers, property managers, brokerage
                firms, accountants and tax specialists. We also have the
                legal-accounting-tax technical support of the consulting firm
                <span translate="no"> Salcedo&Cía </span>.
              </p>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                className="h-full w-full position-relative"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="property" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex md:flex-wrap sm:flex-wrap flex-wrap">
            {teams.length === 0 && <h1>No Team member found</h1>}

            {teams &&
              teams.map((item, index) => <TeamModel key={index} {...item} />)}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
