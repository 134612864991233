import React from "react";

const Job = () => {
  return (
    <div>
      <section id="property" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="font-bold 2xl:text-4xl text-gray-900 text-justify pb-3 border-bottomheading">
            Jobs
          </h2>
        </div>
        <div className="pt-12 job-itemborder">
          <div className="container mx-auto">
            <h2 className="font-bold 2xl:text-2xl text-white-900 text-justify p-3 mb-4 headingjob">
              Compliance and General Paralegal
            </h2>
            <h3 className="font-bold 2xl:text-xl text-white-900 text-justify mb-3 mt-6">
              Compliance and General Paralegal
            </h3>
            <p className="font-normal 2xl:text-md text-gray-500 text-justify pb-3">
              RealT was founded in 2019 by two successful real estate
              entrepreneurs who have also been active in cryptocurrency since
              2013. They used their experience in both worlds to envision and
              create RealT. Taking their global knowledge and experience in real
              estate and their successful investments in <span translate="no"> blockchain </span> technology,
              they’ve developed a platform and a business model for tokenizing
              real estate investing. RealT replaces paper deeds with digital
              <span translate="no"> tokens </span>, a new asset ownership mechanism. This is an innovative
              approach to the world of DeFi, based on the <span translate="no"> Ethereum blockchain </span>.
            </p>
            <p className="font-normal 2xl:text-md text-gray-500 text-justify pb-3">
              RealT is moving from being a start-up to being the authority in
              fractionalized real estate investing. Today, our team has grown
              exponentially and spans across the globe, with a diverse pool of
              talent to take RealT to the next level.
            </p>
            <p className="font-normal 2xl:text-md text-gray-500 text-justify pb-3">
              We are a team of innovators, forward thinkers and multitaskers,
              and are passionate about improving the communities we invest in.
              Interested? We’d love to hear from you!
            </p>
            <h3 className="font-bold 2xl:text-xl text-white-900 text-justify mb-3 mt-10">
              How you’ll Contribute
            </h3>
            <p className="font-normal 2xl:text-md text-gray-500 text-justify pb-3">
              The RealT team is welcoming a Regulatory Compliance Paralegal to
              work alongside the Chief Compliance Officer/ Chief Financial
              Officer. You’re an integral part of this visionary business,
              laying the groundwork for exceptional regulatory and compliance
              practices, while organizing and executing key reports and filings.
              You work closely with outside counsel and various firms, research
              and stay up to date on regulatory shifts, and support the CCO in
              contract reviews. A strong communicator and deeply efficient
              professional, you will have an opportunity to flex your love of
              documentation and interface with technology groups excited about
              supporting your workflow. You thrive on research and details.
              Success in this role holds potential for vertical growth, and role
              expansion within Compliance, Finance, or Legal departments based
              on experience and interest.
            </p>
            <h3 className="font-bold 2xl:text-xl text-white-900 text-justify mb-3 mt-10">
              Day-to-Day
            </h3>
            <ul className="list-disc pl-5 mb-3 font-normal 2xl:text-md text-gray-500 text-justify">
              <li>
                Own compliance policies and procedures through documentation,
                verifications, versioning, and deadline management
              </li>
              <li>
                Prepare and execute SEC filings, Regulation D, Regulation A, and
                BlueSky forms
              </li>
              <li>Form and maintain corporate entities</li>
              <li>
                Support Compliance when it comes to corporate governance, board
                materials, shareholder agreements, and employment legalities, to
                name some areas
              </li>
              <li>Respond to regulatory inquiries and business transactions</li>
              <li>
                Support CCO/CFO in preparing for internal, external, regulatory,
                and AML audits and reviews
              </li>
              <li>
                Maintain and manage an online compliance reporting platform for
                code of ethics monitoring
              </li>
              <li>
                Conduct, analyze, and summarize research, especially as related
                to financial services
              </li>
              <li>
                Manage correspondence and documentation with domestic and
                international outside counsel, financial firms, and regulatory
                agencies
              </li>
              <li>File and data management</li>
              <li>
                Proactively suggest improvements to workflow and software
                adoption
              </li>
            </ul>
            <h3 className="font-bold 2xl:text-xl text-white-900 text-justify mb-3 mt-10">
              Preferred Background
            </h3>
            <ul className="list-disc pl-5 mb-3 font-normal 2xl:text-md text-gray-500 text-justify">
              <li>
                Progressive experience and diverse background in financial
                compliance, legal support, or regulatory management
              </li>
              <li>Experience with SEC reporting</li>
              <li>
                Understanding of <span translate="no"> Blockchain </span> and crypto currency in professional
                or regulatory setting
              </li>
              <li>Certified in or interest in AML or CAMS</li>
              <li>
                Worked with or managed corporate governance for global companies
              </li>
              <li>
                Awe-inspiring precision and consistency in your work product
              </li>
              <li>
                Strong software skills and proactive approach to adopting new
                technological tools
              </li>
              <li>
                Adaptive, confident, and professional in shifting conditions and
                deadline-driven environment
              </li>
            </ul>
            <h3 className="font-bold 2xl:text-xl text-white-900 text-justify mb-3 mt-10">
              Team-wide Characteristics
            </h3>
            <ul className="list-disc pl-5 mb-3 font-normal 2xl:text-md text-gray-500 text-justify">
              <li>
                Ability to work independently in a fast-paced, remote
                environment
              </li>
              <li>
                Record of creating efficient and optimized solutions solo and as
                part of a team
              </li>
              <li>
                Intrinsic motivation and desire to learn in an innovative
                environment
              </li>
              <li>
                Fluency in English; some fluency in French or Spanish a plus
              </li>
              <li>Early adopter of your favorite technologies</li>
            </ul>
            <p className="font-normal 2xl:text-md text-gray-500 text-justify pb-3">
              RealT is a welcoming community of tech and real estate
              entrepreneurs where you can grow to meet the challenges of today’s
              world.
            </p>
            <div className="py-12">
              <p className="font-bold text-center 2xl:text-md text-gray-900 welooking">
                We look forward to hearing from you — Please contact us at:{" "}
                <a href="http://"><span translate="no"> HR@Spacios.co </span></a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Job;
