import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { formatNumber } from "../utils/Formatter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

const DistributedRentDetails = ({ item }) => {
  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };
  return (
    <>
      <tr className="bg-white">
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          <Link
            className="product-id text-blue-900 underline"
            to={`/orderdetails/${item?.id}`}
          >
            #{item?.id}
          </Link>
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {moment(item?.createdAt).format("MMMM Do YYYY")}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          {item?.hasTransferred ? "Success" : "Pending"}
        </td>
        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
          $ {formatNumber(item?.userNetMonthlyRent, 4)}
        </td>
        <td
          colSpan={5}
          className="border text-center 2xl:text-lg xl:text:md text-md p-3"
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {item?.transactionHash ? (
              <div>
                {item?.transactionHash.substring(0, 10) + " "}
                <ContentCopyIcon
                  onClick={(e) => clipboard(item?.transactionHash)}
                  index={item.id}
                />{" "}
              </div>
            ) : (
              <div>N/A</div>
            )}
          </div>
        </td>
        {/* <td className="border text-center sm:text-sm= xl:text:md text-md p-3">
                    <Link
                        to={`/orderdetails/${item?.id}`}
                        className="rounded-full bg-blue-900 text-white view px-10 py-2"
                    >
                        View
                    </Link>

                    {item?.paymentStatus === "completed" ? (
                        <Link
                            to={`${REACT_APP_BASE_API_URL}${item?.invoicePath}`}
                            className="rounded-full bg-blue-900 text-white view px-10 py-2"
                        >
                            Invoice
                        </Link>
                    ) : (
                        ""
                    )}
                </td> */}
      </tr>
    </>
  );
};

export default DistributedRentDetails;
