import React from "react";
const DeedHouse = () => {
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>Who holds the deed to the house?</h2>
            <div className="content-inner">
              <p></p>
            </div>
            <div className="heading-text">
              <p>
                RealT does not tokenize the physical asset. It is currently
                legally impossible to directly ascertain real estate ownership
                through tokenization.
              </p>
              <p>
                RealT tokenizes interests in a Limited Liability Company (LLC)
                that is the sole owner of the asset acquired. Each asset will be
                owned by one single LLC. <span translate="no"> Tokens </span>{" "}
                represent ownership of a membership interest in the LLC and this
                tokenized ownership is enshrined in the subscription agreement
                and in the ownership records, making it irrevocable.
              </p>
              <p>
                The deed to each property is held by the LLC. Purchasing
                RealTokens for an LLC that owns one single deed of a property is
                simply purchasing your share of that deed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeedHouse;
