import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchOrder, fetchOrderByAdmin } from "../../redux/features/orderSlice";
import { updateSendPropertyToken, genrateOrderReport,refundOrderPayment } from "../../redux/api";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Web3 from "web3";
import Loader from "./Loader";
import ABI_ from "../../_abiProperties.json";
import TokenABI from "../../_abi.json"; 
import { updateOrderStatus,rejectOrder } from "../../redux/api";
import { formatPercentage, formatNumber } from "../../utils/Formatter";
import Stack from "@mui/material/Stack";
import { Chip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { REACT_APP_BASE_API_URL,
  REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT,
  REACT_APP_ADMMIN_WALLET_ADDRESS } = process.env;

const Orders = () => {
  var TOKENCONTRACT = "";
  const [isProcessing, setisProcessing] = useState(false);
  const dispatch = useDispatch();
  const { ordersByAdmin } = useSelector((state) => ({
    ...state.order,
  }));

  const handlePaymentStatus = async (id, paymentStatus, _contract) => {
    paymentStatus = paymentStatus ? false : true;
    updateOrderStatus(id, { status: paymentStatus })
      .then(() => {
        dispatch(fetchOrderByAdmin());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRejectOrder = async(id,status,amount)=>{
    status = status ? false : true;
    const formData={status:status,amount:amount,isAmountRefunded:false,isOrderRejected:status};
    rejectOrder(id,formData).then(()=>{
      dispatch(fetchOrderByAdmin());
    }).catch((error) =>{
      console.log(error);
    });
  };

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };

  const handleSendToken = async (Id, _contract, amount, _toAddress,blockchainNetwork) => {
    if (window.networkChain != blockchainNetwork) {
      return toast.warning("Please Connect With "+blockchainNetwork);
    }

    const connactContract = () => {
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      const TOKENCONTRACT_ADDRESS = _contract;
      TOKENCONTRACT = new web3.eth.Contract(ABI_, TOKENCONTRACT_ADDRESS);
    };
    setisProcessing(true);
    const transferToken = async (amount) => {
      let _amount = amount * 1e18;
      await TOKENCONTRACT.methods
        .transfer(_toAddress, _amount.toString())
        .send({ from: window.walletAddress })
        .then(async (d) => {
          const toAddress = d?.to;
          const transactionid = d?.transactionHash;
          const data = {
            toAddress: toAddress,
            transactionHash: transactionid,
            id: Id,
          };

          await updateSendPropertyToken(data)
            .then((response) => {
              toast.success(response.data.message);
              setisProcessing(false);
              dispatch(fetchOrderByAdmin());
            })
            .catch((error) => {
              console.log(error);
              return toast.error(error?.response?.data?.message);
              setisProcessing(false);
            });
        })
        .catch((error) => {
          console.log(error);
          return toast.error(
            "Something went wrong, \n Transaction not performed"
          );
        });
      setisProcessing(false);
    };

    connactContract();
    transferToken(amount);
  };

  const handleOrderRefund = async (Id, amount, _toAddress) => {
    if (window.networkChain != "Ethereum") {
      return toast.warning("Please Connect With Ethereum");
    }

    const connactContract = () => {
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      const TOKENCONTRACT_ADDRESS = REACT_APP_ETHEREUM_USDC_TOKEN_CONTRACT;
      TOKENCONTRACT = new web3.eth.Contract(TokenABI, TOKENCONTRACT_ADDRESS);
    };
    setisProcessing(true);
    const transferToken = async (amount) => {
      let _amount = amount * 1e6;
      await TOKENCONTRACT.methods
        .transfer(_toAddress, _amount.toString())
        .send({ from: window.walletAddress })
        .then(async (d) => {
          const toAddress = d?.to;
          const transactionid = d?.transactionHash;
          const data = {
            isAmountRefunded: true,
            amount:amount,
            transactionId: transactionid,
          };

          await refundOrderPayment(Id, data)
            .then((response) => {
              toast.success(response.data.message);
              setisProcessing(false);
              dispatch(fetchOrderByAdmin());
            })
            .catch((error) => {
              console.log(error);
              return toast.error(error?.response?.data?.message);
              setisProcessing(false);
            });
        })
        .catch((error) => {
          console.log(error);
          return toast.error(
            "Something went wrong, \n Transaction not performed"
          );
        });
      setisProcessing(false);
    };

    connactContract();
    transferToken(amount);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const gernrateReport = () => {
    setOpen(false);
    let formData = { startData: startDate, toDate: toDate };
    genrateOrderReport(formData)
      .then((res) => {
        toast.success(res.data.message);
        if (res?.data?.path) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data?.path}`);
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = () => {
    // setAgreeActive(false);
    setOpen(false);
  };

  const handlelOpen = () => {
    // setAgreeActive(false);
    setOpen(true);
  };


  const orderColumn = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 120,
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) => `${params?.row?.Order_Details.PropertyAddress}`,
    },
    {
      field: "User Name ",
      headerName: "User Name",
      width: 200,
      valueGetter: (params) =>
        `${params?.row?.order?.user?.firstName} ${params.row.order?.user?.lastName || ""
        }`,
    },
    {
      field: "userEmail",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.order?.user?.email}`,
    },
    {
      field: "Contract Address",
      headerName: "Smart Contract Address",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params.row.Order_Details.SmartContractAddress.substring(0, 6)+"...."+ params.row.Order_Details.SmartContractAddress.substring(params.row.Order_Details.SmartContractAddress.length -6)+" "}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params.row.Order_Details.SmartContractAddress)
              }
              index={params.row.id}
            />
          </div>
        );
      },
    },
    {
      field: "blockchainNetwork",
      headerName: "Property Blockchain",
      width: 150,
      valueGetter: (params) =>
        params.row.Order_Details.blockchainNetwork,
    },
    {
      field: "propertyToken",
      headerName: "Property Token",
      width: 150,
      valueGetter: (params) =>
        `${formatNumber(params?.row?.propertyToken * 1)}`,
    },
    {
      field: "per_token_price",
      headerName: "Per Token Price",
      width: 150,
      valueGetter: (params) =>
        `$${formatNumber(params?.row?.perTokenPrice * 1)}`,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 120,
      valueGetter: (params) =>
        `$${formatNumber(
          params?.row?.propertyToken * params?.row?.perTokenPrice
        )}`,
    },
  
    {
      field: "totalPaymentTaken",
      headerName: "Total Recieved Payment",
      width: 140,
      valueGetter: (params) =>
        `$${formatNumber(
          params?.row?.order?.totalAmount)}`,
    },
    {
      field: "networkChain",
      headerName: "BlockChain Network",
      width: 140,
      valueGetter: (params) => `${params?.row?.order.networkChain}`,
    },
    {
      field: "Transaction hash ",
      headerName: "Transaction Hash ",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.order?.transactionId.substring(0, 6)+"...."+params?.row?.order?.transactionId.substring(params?.row?.order?.transactionId.length -6)+" "}
            <ContentCopyIcon
              onClick={(e) => clipboard(params?.row?.order?.transactionId)}
              index={params.row.id}
            />
          </div>
        );
      },
    },

    {
      field: "paymentStatus",
      headerName: "Received Payment Status",
      width: 180,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            {params.row.order.paymentStatus ? (
              <Stack direction="row" spacing={1}>
                <Chip label="Success" color="success" variant="outlined" />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <Chip label="Failed" color="error" variant="outlined" />
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: "isTokenTransfred",
      headerName: "Ownership Status",
      width: 135,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            {params.row.isTokenTransfred ? (
              <Stack direction="row" spacing={1}>
                <Chip label="Transfered" color="success" variant="outlined" />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <Chip label="Pending" color="error" variant="outlined" />
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: "owner_ship_transaction_id",
      headerName: "Ownership Transaction Hash",
      width: 170,
      renderCell: (params) => {
        return params?.row?.transactionId && (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.transactionId.substring(0, 6)+"...."+params?.row?.transactionId.substring(
              (params?.row?.transactionId).length -6)+" "}
            <ContentCopyIcon
              onClick={(e) => clipboard(params?.row?.transactionId)}
              index={params.row.id}
            />
          </div>
        );
      },
    },
    { 
      field: "refundTransactionId_",
      headerName: "Refund Transaction Id",
      width: 170,
      renderCell: (params) => {
        return params?.row?.rejectedOrder?.transactionId && (
          <div
            className="d-flex justify-content-between align-items-center" 
            style={{ cursor: "pointer" }}
          >
            {params?.row?.rejectedOrder?.transactionId.substring(0, 6)+"...."+params?.row?.rejectedOrder?.transactionId.substring(
              (params?.row?.rejectedOrder?.transactionId).length -6)+" "}
            <ContentCopyIcon
              onClick={(e) => clipboard(params?.row?.rejectedOrder?.transactionId)}
              index={params.row.id}
            />
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 450,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {(!params?.row?.rejectedOrder?.isOrderRejected && !params?.row?.isTokenTransfred) ? (<>
              <div
                className={`${params.row.paymentStatus ? "deleteButton" : "publishContract"}`}
                onClick={(e) =>
                  handlePaymentStatus(params.row.id, params.row.paymentStatus)
                } title={`${params.row.paymentStatus ? "User Payment Verified" : "Pending User Payment Verification"}`}
              >
                {!params.row.paymentStatus ? "Payment Verify" : "Payment Verified"}
              </div> </>) : (" ") }
            {(params.row.paymentStatus) ? (
              <>
                {" "}

                {!params.row.isAgreementSigned ? <>
                  <button
                    className="deleteButton" title="Agreement Pdf Pending For Signature"
                  >
                    {" "} Pending Agreement Sign
                  </button> </> : <>
                  <a className="publishContract" href={REACT_APP_BASE_API_URL + params?.row?.agreementPdf} 
                    target="_blank" title="View Signed Agreement Pdf"
                  >
                    {" "} View Agreement
                  </a>
                  {!params?.row?.isTokenTransfred ? (
                    <>
                      {!isProcessing ? (
                        <button
                          className="publishToken"
                          onClick={(e) =>
                            handleSendToken(
                              params.row.id,
                              params?.row?.Order_Details.SmartContractAddress,
                              params?.row?.propertyToken,
                              params?.row?.order.walletAddress,
                              params?.row?.Order_Details.blockchainNetwork
                            )
                          } title="Send Token OwnerShip"
                        >
                          {" "}
                          Send <span translate="no"> Token </span>
                        </button>
                      ) : (
                        <button className="publishToken">Send <span translate="no"> Token </span> </button>
                      )}
                    </>
                  ) : (<> <a className="publishContract" title="Payment has been Transfred">
                        Token Transfred </a></>
                  )}
                </>}


              </>
            ) : (
              <>
                {!params?.row?.rejectedOrder?.isAmountRefunded ? (<>
                  <Button variant="contained" onClick={(e) =>
                    handleRejectOrder(params?.row?.id, params?.row?.rejectedOrder?.isOrderRejected,params?.row?.propertyToken)}
                  color={params?.row?.rejectedOrder?.isOrderRejected ? "primary" : "success"}  >
                    {params?.row?.rejectedOrder?.isOrderRejected?.isOrderRejected ? "Rejected | YES" : "Rejected | No"}
                  </Button>


                  {(params?.row?.rejectedOrder?.isOrderRejected) ?
                    (<>
                      {(!params?.row?.rejectedOrder?.isAmountRefunded) ?
                        (<> <button className="publishToken"
                          onClick={(e) => handleOrderRefund(params.row.id,params?.row?.propertyToken, params?.row?.order.walletAddress)}
                          title="Return Order Payment To User">
                          Refund Payment
                        </button> </>) :
                        (<><button className="publishToken"> Payment Refunded </button> </>)
                      }
                    </>) : (" ")
                  }
                </>) : (<><Button variant="contained" color="error" title="Order Completely Rejected and Amount Has Been Refuneded"> Payment Refunded </Button> </>)
                }
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchOrderByAdmin());
  }, [dispatch]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate Property Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="formCustom">
                <lable>Select From Data</lable>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="p-3 rounded text-md form-control  border  focus:ring-1" />
              </div>
              <div className="formCustom">
                <lable>Select To Data</lable>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} className="p-3 rounded text-md form-control border  focus:ring-1" />
              </div>
              <div className="mt-4">
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>

            </Button>
            <div className="moadl-footer">
              <button className="btnCancel" onClick={handleClose}>Cancel</button>
              <button className="btnReport" onClick={gernrateReport}>Generate Report</button>
            </div>


          </DialogActions>
        </Dialog>
      </div>
      <div className="list">
        {isProcessing ? (<> <Loader /></>) : (<></>)}


      
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle" style={{ display: "flex", justifyContent: "end" }}>

              <button onClick={handlelOpen} className="link" > Generate Report </button>

            </div>

            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={ordersByAdmin ? ordersByAdmin : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={orderColumn}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
