import React from "react";
import { CircularProgress } from "@mui/material";
import "../../assets/css/responsive.css";
const Loader = (props) => {
  return (
    <div className="loaderspinner">
      <div className="spinner-content text-center">
        <CircularProgress color="secondary" />
        <p
          className="text-white mb-0 mt-4 text-center fs-3"
          style={{ fontSize: "20px" }}
        >
          {props.msg1
            ? props.msg1
            : "Please wait... while we process your payment."}
        </p>
        <p
          className="text-red mb-0 mt-2 text-center fs-3"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {props.msg2
            ? props.msg2
            : "Please don't refresh the page or click back button while payment is being processed."}
        </p>
      </div>
    </div>
  );
};

export default Loader;
