import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { fetchPropertyEmail } from "../../redux/features/emailSlice";
import { getGeneratedRentEmails } from "../../redux/api";


const GeneratedRentEmails = () => {
  const dispatch = useDispatch();

  const [generatedRentMail, setgeneratedRentMail] = useState([]);
  const { PropertyEmail } = useSelector((state) => ({ ...state.email }));

  const userColumns = [
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 220,
      valueGetter: (params) =>
        `${params.row.rentDistribution[0].User_Details.firstName} ${params.row.rentDistribution[0].User_Details.lastName}`
    },
    {
      field: "email",
      headerName: "Email id",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 250,
      valueGetter: (params) =>
        `${params.row.rentDistribution[0].User_Details.email}`
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 620,
      renderCell: (params) => {
        return(
          params.row.rentDistribution.map((properties, i) => {
          return   (<div className={`cellWithStatus ${params.row.mailStatus}}`}>
          <Stack spacing={1} alignItems="center">
         
              <Stack direction="row" spacing={1}>
                <Chip label={properties.Property_Details.PropertyAddress} color="success" variant="outlined" />
              </Stack>
           
          </Stack>
        </div>)
            
          })
        
        );
      },

    },
    

    {
      field: "status",
      headerName: "Email Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.mailStatus}}`}>
            <Stack spacing={1} alignItems="center">
              {params.row.mailStatus ? (
                <Stack direction="row" spacing={1}>
                  <Chip label="Success" color="success" variant="outlined" />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  <Chip label="Pending" color="error" variant="outlined" />
                </Stack>
              )}
            </Stack>
          </div>
        );
      },
    },

  ];

  useEffect(() => {
    dispatch(fetchPropertyEmail());
    getGeneratedRentEmails().then(res=>{
      setgeneratedRentMail(res.data.finalData);
    }).catch((err)=>{
      console.log(err);
    })

  }, [dispatch]);

  return (
    <>

      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
  
          <div className="datatable">
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={generatedRentMail ? generatedRentMail : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={userColumns}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default GeneratedRentEmails;
