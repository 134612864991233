const EightPercent = (GrossRent) => {
  const montlygrossRent = GrossRent / 12;
  return ((montlygrossRent * 8) / 100).toFixed(2);
};

const twoPercentage = (GrossRent) => {
  const montlygrossRent = GrossRent / 12;
  return ((montlygrossRent * 2) / 100).toFixed(2);
};

const montlyProperty = (GrossRent, PropertyTaxes) => {
  const montlygrossRent = parseInt(GrossRent / 12);
  return (
    GrossRent / 12 -
    ((montlygrossRent * 8) / 100 +
      (montlygrossRent * 2) / 100 +
      PropertyTaxes * 1)
  ).toFixed(2);
};

const montlyPropertyClp = (GrossRentClp, PropertyTaxes) => {
  const montlygrossRent = parseInt(GrossRentClp / 12);
  return (
    GrossRentClp / 12 -
    ((montlygrossRent * 8) / 100 +
      (montlygrossRent * 2) / 100 +
      PropertyTaxes * 1)
  ).toFixed(2);
};

const anuallyProperty = (GrossRent, PropertyTaxes) => {
  const montlygrossRent = GrossRent / 12;
  return (
    GrossRent -
    (parseFloat(montlygrossRent * 8) / 100 +
      parseFloat(montlygrossRent * 2) / 100 +
      PropertyTaxes)
  ).toFixed(2);
};

module.exports = {
  EightPercent,
  anuallyProperty,
  montlyProperty,
  twoPercentage,
  montlyPropertyClp,
};
