import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const propertiesCount = createAsyncThunk(
  "dash/propertiesCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.propertiesCount();
      return response.data.propertyCount;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activeProperties = createAsyncThunk(
  "dash/activeProperties",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.activeProperties();
      return response.data.activeProperties;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const totalTokens = createAsyncThunk(
  "dash/totalTokens",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.totalTokens();
      return response?.data?.tokenSum;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const totalPrice = createAsyncThunk(
  "dash/totalPrice",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.totalPrice();
      return response?.data?.priceSum;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const yearlyGrossRent = createAsyncThunk(
  "dash/yearlyGrossRent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.yearlyGrossRent();
      return response?.data?.totalYearlyGrossRent;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const monthlyGrossRent = createAsyncThunk(
  "dash/monthlyGrossRent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.monthlyGrossRent();
      return response?.data?.totalMonthlyGrossRent;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const yearlyNetRent = createAsyncThunk(
  "dash/yearlyNetRent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.yearlyNetRent();
      return response?.data?.totalYearlyNetRent;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const totalInvestor = createAsyncThunk(
  "dash/totalInvestor",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.totalInvestor();
      return response?.data?.totalInvestor;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const investorDetailsData = createAsyncThunk(
  "dash/investorDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.investorDetails();
      return response?.data?.investorDetails;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState: {
    propertiesCountData: [],
    activePropertiesData: [],
    investorDetails: [],
    totalTokensData: [],
    totalPriceData: [],
    yearlyGrossRentData: [],
    monthlyGrossRentData: [],
    yearlyNetRentData: [],
    monthlyNetRent: [],
    totalInvestorData: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    //Properties
    [propertiesCount.pending]: (state, action) => {
      state.loading = true;
    },
    [propertiesCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertiesCountData = action.payload;
    },
    [propertiesCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Active Properties
    [activeProperties.pending]: (state, action) => {
      state.loading = true;
    },
    [activeProperties.fulfilled]: (state, action) => {
      state.loading = false;
      state.activePropertiesData = action.payload;
    },
    [activeProperties.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Investor Details
    [investorDetailsData.pending]: (state, action) => {
      state.loading = true;
    },
    [investorDetailsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.investorDetails = action.payload;
    },
    [investorDetailsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [totalTokens.pending]: (state, action) => {
      state.loading = true;
    },
    [totalTokens.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalTokensData = action.payload;
    },
    [totalTokens.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [totalPrice.pending]: (state, action) => {
      state.loading = true;
    },
    [totalPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPriceData = action.payload;
    },
    [totalPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [yearlyGrossRent.pending]: (state, action) => {
      state.loading = true;
    },
    [yearlyGrossRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.yearlyGrossRentData = action.payload;
    },
    [yearlyGrossRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [monthlyGrossRent.pending]: (state, action) => {
      state.loading = true;
    },
    [monthlyGrossRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.monthlyGrossRentData = action.payload;
    },
    [monthlyGrossRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [yearlyNetRent.pending]: (state, action) => {
      state.loading = true;
    },
    [yearlyNetRent.fulfilled]: (state, action) => {
      state.loading = false;
      state.yearlyNetRentData = action.payload;
    },
    [yearlyNetRent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [totalInvestor.pending]: (state, action) => {
      state.loading = true;
    },
    [totalInvestor.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalInvestorData = action.payload;
    },
    [totalInvestor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default dashBoardSlice.reducer;
