import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Sidebar from "../../components/sidebar/Sidebar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProperty,
  deletePropertyImage,
  setProperty,
} from "../../redux/features/propertySlice";
import { Spinner } from "../../components";
import { Editor } from "@tinymce/tinymce-react";
import "../../../src/pages/new/new.scss";
import { getProperty } from "../../redux/api";

const AddProperty = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const { REACT_APP_TINYMCE_API } = process.env;

  const [selectedImages, setSelectedImages] = useState([]);
  const [file, setFile] = useState("");
  const [showImg, setshowImg] = useState(true);
  const [properDetail, setProperDetail] = useState("");
  const [neighbour, setNeighbour] = useState("");
  const [isPropertyProcessing, setisPropertyProcessing] = useState(false);

  const { error, loading, property } = useSelector((state) => ({
    ...state.property,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { propertyId } = useParams();

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    if (properDetail.length > 0) {
      formData.set("DetailsProperty", properDetail);
    }
    if (neighbour.length > 0) {
      formData.set("Neighbour", neighbour);
    }

    dispatch(updateProperty({ propertyId, formData, toast, navigate }));
  };

  const deleteImages = async (id) => {
    dispatch(deletePropertyImage(id))
      .then((response) => {
        getProperty(propertyId).then((res)=>{

          dispatch(setProperty(res?.data));
        });
      })
      .catch((error) => {
        console.log("error=",error);
      });
  };
  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const updateImgShow = (e) => {
    setFile(e.target.files[0]);
    setshowImg(false);
  };


  useEffect(() => {
    getProperty(propertyId).then((res) => {
      dispatch(setProperty(res?.data));
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
    });
    // dispatch(getProperty(propertyId));
  }, [dispatch]);


  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="bottom">
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>General Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="left">
                  <img
                    src={
                      propertyId && showImg
                        ? `${REACT_APP_BASE_API_URL}${property?.PropertyAvatar}`
                        : file
                          ? URL.createObjectURL(file)
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw-ETMC23xkCA0zFh6AKf_T5tM1JksNCtSTQ&usqp=CAU"
                    }
                    alt=""
                  />
                  {/* <label>
                    + Add Main Image
                    <input
                      type="file"
                      name="PropertyAvatar"
                      accept="image/png , image/jpeg, image/webp"
                      style={{ display: "none" }}
                      onChange={updateImgShow}
                    />
                  </label> */}
                </div>
                <div className="right editprofile">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="formInput">
                      <label>
                        + Add Main Image
                        <input
                          type="file"
                          name="PropertyAvatar"
                          accept="image/png , image/jpeg, image/webp"
                          style={{ display: "none" }}
                          onChange={updateImgShow}
                        />
                      </label>
                      <div className="images-Area">
                        {property &&
                          property.PropertyImages_Detail &&
                          property.PropertyImages_Detail.map(
                            (image, index) => {
                              return (
                                <div className="imagesBox">
                                  <div
                                    key={image.propertyImage}
                                    className="image"
                                  >
                                    <img
                                      src={`${REACT_APP_BASE_API_URL}${image.propertyImage}`}
                                      id="deleteImage"
                                      alt="upload"
                                    />
                                    <span
                                      className="closeBtn"
                                      type="submit"
                                      onClick={() => deleteImages(image.id)}
                                    >
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <label>
                        + Add Images
                        <br />
                        <input
                          type="file"
                          name="PropertyImage"
                          id="imageFile"
                          multiple
                          accept="image/png , image/jpeg, image/webp"
                          onChange={onSelectFile}
                          style={{ display: "none" }}
                        />
                      </label>

                      <div className="images uploaded-images">
                        {selectedImages &&
                          selectedImages.map((image) => {
                            return (
                              <div key={image} className="image">
                                <img src={image} alt="upload" />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="formHeading">
                      <p>PROPERTY BACKGROUND: </p>
                    </div>

                    <div className="formInput">
                      <label>Property Address</label>
                      <input
                        type="text"
                        placeholder="Property Address"
                        name="PropertyAddress"
                        id="PropertyAddress"
                        key={property?.PropertyAddress}
                        defaultValue={property?.PropertyAddress}

                      />
                    </div>
                    <div className="formInput">
                      <label>Property Type</label>
                      <input
                        type="text"
                        placeholder="Property Type"
                        name="PropertyType"
                        id="PropertyType"
                        key={property?.PropertyType}
                        defaultValue={property?.PropertyType}
                      />
                    </div>
                    <div className="formInput">
                      <label>
                        ANNUAL INCOME (without capital appreciation)
                      </label>
                      <input
                        type="number"
                        placeholder="Annual Income"
                        name="AnnualIncome"
                        id="AnnualIncome"
                        key={property?.AnnualIncome}
                        defaultValue={property?.AnnualIncome}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Neighbourhood</label>
                      <input
                        type="text"
                        placeholder="Neighbourhood"
                        name="Neighbourhood"
                        id="Neighbourhood"
                        key={property?.Neighbourhood}
                        defaultValue={property?.Neighbourhood}
                      />
                    </div>
                    <div className="formInput">
                      <label>CAPITAL APPRECIATION (average estimated)</label>
                      <input
                        type="number"
                        placeholder="CapitalAppreciation"
                        name="CapitalAppreciation"
                        id="CapitalAppreciation"
                        key={property?.CapitalAppreciation}
                        defaultValue={property?.CapitalAppreciation}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Construction Year</label>
                      <input
                        type="number"
                        placeholder="Construction Year"
                        name="ConstructionYear"
                        id="ConstructionYear"
                        key={property?.ConstructionYear}
                        defaultValue={property?.ConstructionYear}
                        required
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Total Investment (in US $)</label>
                      <input
                        type="number"
                        placeholder="Total Price"
                        name="TotalPrice"
                        id="TotalPrice"
                        key={property?.TotalPrice}
                        defaultValue={property?.TotalPrice}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Size (in sqm)</label>
                      <input
                        type="number"
                        placeholder="Size"
                        name="LotSize"
                        id="LotSize"
                        key={property?.LotSize}
                        defaultValue={property?.LotSize}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Total <span translate="no"> SPACIOS BRICK Tokens </span> issues</label>
                      <input
                        type="number"
                        placeholder="Total Tokens"
                        name="TotalTokens"
                        id="TotalTokens"
                        key={property?.TotalTokens}
                        defaultValue={property?.TotalTokens}
                        min="0"
                      />
                    </div>
                    <div className="formInput">
                      <label>Lease Contract</label>
                      <input
                        type="text"
                        placeholder="Lease Contract"
                        name="LeaseContract"
                        id="LeaseContract"
                        key={property?.LeaseContract}
                        defaultValue={property?.LeaseContract}
                        min="0"
                      />
                    </div>

                    <div className="formInput">
                      <label>Passive Cash Flow per 1 Token (annually)</label>
                      <input
                        type="number"
                        placeholder="Passive Cash Flow"
                        name="PassiveCashFlow"
                        id="PassiveCashFlow"
                        key={property?.PassiveCashFlow}
                        defaultValue={property?.PassiveCashFlow}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Territorial Taxes</label>
                      <input
                        type="number"
                        placeholder="Territorial Taxes"
                        name="TerritorialTaxes"
                        id="TerritorialTaxes"
                        key={property?.TerritorialTaxes}
                        defaultValue={property?.TerritorialTaxes}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>
                        Capital Gain Incorporated in 1 <span translate="no"> Token </span> (annually)
                      </label>
                      <input
                        type="number"
                        placeholder="Capital Gain Incorporated"
                        name="CapitalGainIncorporated"
                        id="CapitalGainIncorporated"
                        key={property?.CapitalGainIncorporated}
                        defaultValue={property?.CapitalGainIncorporated}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>kitchen</label>
                      <input
                        type="number"
                        placeholder="kitchen"
                        name="kitchen"
                        id="kitchen"
                        key={property?.kitchen}
                        defaultValue={property?.kitchen}
                        min="0"
                      />
                    </div>

                    <div className="formInput">
                      <label>Max <span translate="no"> Token </span></label>
                      <input
                        type="number"
                        placeholder="Max Token"
                        name="MaxToken"
                        id="MaxToken"
                        key={property?.MaxToken}
                        defaultValue={property?.MaxToken}
                        min="0"
                      />
                    </div>
                    <div className="formInput">
                      <label>Bathroom</label>
                      <input
                        type="number"
                        placeholder="Bathroom"
                        name="Bathroom"
                        id="Bathroom"
                        key={property?.Bathroom}
                        defaultValue={property?.Bathroom}
                        min="0"
                      />
                    </div>

                    <div className="formInput">
                      <label>Common expenses (tenant expense in US $)</label>
                      <input
                        type="number"
                        placeholder="Common Expenses"
                        name="CommonExpenses"
                        id="CommonExpenses"
                        key={property?.CommonExpenses}
                        defaultValue={property?.CommonExpenses}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Balcony</label>
                      <input
                        type="number"
                        placeholder="Balcony"
                        name="Livingroom"
                        id="Livingroom"
                        key={property?.Livingroom}
                        defaultValue={property?.Livingroom}
                        min="0" 
                      />
                    </div>
                    <div className="formInput">
                      <label>Common expenses (tenant expense in CLP)</label>
                      <input
                        type="number"
                        placeholder="Common Expenses"
                        name="CommonExpensesClp"
                        id="CommonExpensesClp"
                        key={property?.CommonExpensesClp}
                        defaultValue={property?.CommonExpensesClp}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Bedroom</label>
                      <input
                        type="number"
                        placeholder="Bedroom"
                        name="Bedroom"
                        id="Bedroom"
                        key={property?.Bedroom}
                        defaultValue={property?.Bedroom}
                        min="0"
                      />
                    </div>
                    <div className="formInput">
                      <label>Total Investment (in CLP)</label>
                      <input
                        type="number"
                        placeholder="Total Price"
                        name="TotalPriceClp"
                        id="TotalPriceClp"
                        key={property?.TotalPriceClp}
                        defaultValue={property?.TotalPriceClp}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formHeading">
                      <p>NUMBERS: </p>
                    </div>
                    <div className="formInput">
                      <label>Gross Rent (US $)</label>
                      <input
                        type="number"
                        placeholder="Gross Rent"
                        name="GrossRent"
                        id="GrossRent"
                        key={property?.GrossRent}
                        defaultValue={property?.GrossRent}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Underlying Asset Price (CLP)</label>
                      <input
                        type="number"
                        placeholder="Underlying Asset Price"
                        name="UnderlyingAssetPriceClp"
                        id="UnderlyingAssetPriceClp"
                        key={property?.UnderlyingAssetPriceClp}
                        defaultValue={property?.UnderlyingAssetPriceClp}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Gross Rent (CLP)</label>
                      <input
                        type="number"
                        placeholder="Gross Rent"
                        name="GrossRentClp"
                        id="GrossRentClp"
                        key={property?.GrossRentClp}
                        defaultValue={property?.GrossRentClp}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Initial Maintenance Reserve (US $)</label>
                      <input
                        type="number"
                        placeholder="Initial Maintenance Reserve"
                        name="InitialMaintenanceReserve"
                        id="InitialMaintenanceReserve"
                        key={property?.InitialMaintenanceReserve}
                        defaultValue={property?.InitialMaintenanceReserve}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Property Taxes (US $)</label>
                      <input
                        type="number"
                        placeholder="Property Taxes"
                        name="PropertyTaxes"
                        id="PropertyTaxes"
                        key={property?.PropertyTaxes}
                        defaultValue={property?.PropertyTaxes}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Initial Maintenance Reserve (CLP)</label>
                      <input
                        type="number"
                        placeholder="Initial Maintenance Reserve"
                        name="InitialMaintenanceReserveClp"
                        id="InitialMaintenanceReserveClp"
                        key={property?.InitialMaintenanceReserveClp}
                        defaultValue={property?.InitialMaintenanceReserveClp}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Property Taxes (CLP)</label>
                      <input
                        type="number"
                        placeholder="Property Taxes"
                        name="PropertyTaxesClp"
                        id="PropertyTaxesClp"
                        key={property?.PropertyTaxesClp}
                        defaultValue={property?.PropertyTaxesClp}
                        min="0" step="any"
                      />
                    </div>
                    <div className="formInput">
                      <label>Insurance (US $)</label>
                      <input
                        type="number"
                        placeholder="Insurance"
                        name="Insurance"
                        id="Insurance"
                        key={property?.Insurance}
                        defaultValue={property?.Insurance}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Underlying Asset Price (US $)</label>
                      <input
                        type="number"
                        placeholder="Underlying Asset Price"
                        name="UnderlyingAssetPrice"
                        id="UnderlyingAssetPrice"
                        key={property?.UnderlyingAssetPrice}
                        defaultValue={property?.UnderlyingAssetPrice}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Insurance (CLP)</label>
                      <input
                        type="number"
                        placeholder="Insurance"
                        name="InsuranceClp"
                        id="InsuranceClp"
                        key={property?.InsuranceClp}
                        defaultValue={property?.InsuranceClp}
                        min="0" step="any"
                      />
                    </div>

                    <div className="formInput">
                      <label>Details Property </label>
                      <textarea
                        rows={10}
                        cols={75}
                        type="text"
                        placeholder="Details Property"
                        name="DetailsProperty"
                        id="DetailsProperty"
                        style={{ display: "none" }}
                        value={property?.DetailsProperty}
                      />
                      <Editor
                        apiKey={REACT_APP_TINYMCE_API}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menubar: false,
                          plugins: [
                            "a11ychecker",
                            "advlist",
                            "advcode",
                            "advtable",
                            "autolink",
                            "checklist",
                            "export",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "powerpaste",
                            "fullscreen",
                            "formatpainter",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        }}
                        initialValue={property?.DetailsProperty}
                        outputFormat="text"
                        onEditorChange={(newText) => setProperDetail(newText)}
                      />
                    </div>
                    <div className="formInput">
                      <label>Neighbour</label>
                      <textarea
                        rows={10}
                        cols={75}
                        type="text"
                        placeholder="Neighbour"
                        name="Neighbour"
                        id="Neighbour"
                        style={{ display: "none" }}
                        defaultValue=""
                        value={property?.Neighbour}
                      />
                      <Editor
                        apiKey={REACT_APP_TINYMCE_API}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menubar: false,
                          plugins: [
                            "a11ychecker",
                            "advlist",
                            "advcode",
                            "advtable",
                            "autolink",
                            "checklist",
                            "export",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "powerpaste",
                            "fullscreen",
                            "formatpainter",
                            "insertdatetime",
                            "media",
                            "table",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | casechange blocks | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                        }}
                        initialValue={property?.Neighbour}
                        outputFormat="text"
                        onEditorChange={(newText) => setNeighbour(newText)}
                      />
                    </div>

                    {loading ? (
                      <Spinner />
                    ) : isPropertyProcessing ? (
                      <button disabled className="btnsubmit">Update
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <>
                        <button className="btnsubmit">Update
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {propertyId && <></>}
        </div>
      </div>
    </div>
  );
};

export default AddProperty;
