import React, { useState } from "react";
import { Link } from "react-router-dom";
import { fetchCart } from "../redux/features/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatNumber,formatPercentage } from "../utils/Formatter";
import { createCart } from "../redux/api";

const { REACT_APP_BASE_API_URL } = process.env;

const PropertyCard = ({ product }) => {
  const dispatch = useDispatch();
  const profile = JSON.parse(localStorage.getItem("profile"));

  const [data, setData] = useState({
    PropertyId: product?.id,
    TotalTokens: product?.TotalTokens,
    TotalPrice: product?.TotalPrice,
    UserId: profile?.id,
  });
  const [token, setToken] = useState("");
  const [totalReturn, setTotalReturn] = useState("");

  let userInfo = JSON.parse(localStorage.getItem("profile"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (token) {
      createCart(data)
        .then((response) => {
          toast.success(response.data.message);
          dispatch(fetchCart());
        })
        .catch((error) => {
          return toast.error(error?.response?.data?.message);
        });
    }
  };
  const handlechange = (e) => {
    setToken(e.target.value);
    setData({ ...data, Tokens: e.target.value });
  };
  var dd="";
  return (
    <>
      <div className="Property-available mb-5">
        <div className="2xl:w-full">
          <div
            className={` ${product?.sold === "sold" ? "bg-gray-1000" : "bg-black"
              }  p-2 mb-2 2xl:text-2xl xl:text-xl lg:text-xl md:text-2xl sm:text-xl text-xl text-center text-white`}
          >
            {product?.sold === "sold" ? "Sold Out" : "Available"}
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full img-heigh">
            <div
              style={{ margin: "0px" }}
              className={` ${product?.sold === "sold" ? "relative" : ""
                } image h-full`}
            >
              <Link to={`/propertydetails/${product?.id}`}>
                <img
                  src={`${REACT_APP_BASE_API_URL}${product.PropertyAvatar}`}
                  alt=""
                  className={` ${product?.sold === "sold"
                    ? "object-cover opacity-50 bg-gray-700"
                    : ""
                    }  h-full w-full object-cover`}
                />
              </Link>
              {product?.sold === "sold" && (
                <div className="overlay-bg absolute top-0 w-full h-full  flex items-center justify-center">
                  <button
                    type="button"
                    className="bg-blue-800 text-white p-3 text-center align-middle text-xl w-48 rounded-full"
                  >
                    SOLD OUT
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full w-full p-5 text-xl bg-white">
            <div className="text-center w-full p-3">
              <h3 style={{ fontSize: "1.8rem" }}> <strong> {product?.PropertyAddress} </strong></h3>
            </div>
            <div className="flex py-5">
              <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-1/2 text-center centerLine">
                <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-xl text-lg block font-bold w-full my-text">
                  Total Price
                </span>
                <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-light block w-full total-font">
                  US$ {formatNumber(product?.TotalPrice)}
                </span>
              </div>
              <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-1/2 text-center">
                <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-xl text-lg block font-bold w-full my-text">
                  <span translate="no"> Token </span> Price
                </span>
                <span className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-light w-full price-font">
                  US$ {formatNumber(product?.TotalPrice / product?.TotalTokens)}
                </span>
              </div>
            </div>
            <div className="flex justify-between bg-gray-200 p-3 items-center mb-5">
              <div className="2xl:w-1/2">
                <h4 className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-bold w-full">
                  Annual Income{" "}
                </h4>
                <p className="2xl:text-lg lg:text-lg xl:text-lg md:text-md sm:text-sm text-sm block font-normal w-full">
                  (Without capital appreciation)
                </p>
              </div>
              <div className="2xl:w-1/2 text-right">
                <span className="data">
                  {formatPercentage(product?.AnnualIncome)} %
                </span>
              </div>
            </div>
            <div className="flex justify-between bg-gray-200 p-3 items-center mb-5">
              <div className="2xl:w-1/2">
              <h4 className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-bold w-full">
                  Annual Capital Appreciation
                </h4>                
                <p className="2xl:text-lg lg:text-lg xl:text-lg md:text-md sm:text-sm text-sm block font-normal w-full">
                  (Estimated)
                </p>
              </div>
              <div className="2xl:w-1/2 text-right">
                <span className="data">
                 {formatPercentage(parseFloat(product?.CapitalAppreciation).toFixed(2))} %
                </span>
              </div>
            </div>
            <div className="flex justify-between bg-gray-200 p-3 items-center mb-5">
              <div className="2xl:w-1/2">
              <h4 className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-bold w-full">
                  Total Annual Income
                </h4>                
              </div>
              <div className="2xl:w-1/2 text-right">
                <span className="data">
                  { formatPercentage((parseFloat(product?.AnnualIncome)+(parseFloat(product?.CapitalAppreciation))).toFixed(2))} %
                   
                </span>
              </div>
            </div>
            <div className="flex justify-between bg-gray-200 p-3 items-center mb-5">
              <div className="2xl:w-1/2">
                <h4 className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg block font-bold w-full">
                  Passive Cash Flow Per 1 <span translate="no"> Token </span>{" "}
                </h4>
                <p className="2xl:text-lg lg:text-lg xl:text-lg md:text-md sm:text-sm text-sm block font-normal w-full">
                  (Annually)
                </p>
              </div>
              <div className="2xl:w-1/2 text-right">
                <span className="data">
                  US$ {formatNumber(product?.PassiveCashFlow)}
                </span>
              </div>
            </div>
            <div className="flex flex-wrap justify-between p-3 items-center mb-5">
              {!product?.IsSold ? (
                <>
                  <div className="my-btn p-0">
                    <form onSubmit={handleSubmit} style={{display: "flex"}}>
                      <input
                        className="quantity-cell mr-2"
                        type="number"
                        name="cartquantity"
                        value={token}
                        onChange={handlechange}
                        id={product?.id}
                        min="1"
                        max={product?.MaxToken}
                      />

                      <input
                        type="submit"
                        value="Add to Cart"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      />
                    </form>
                    <p className="ml-20 text-sm text-orange-500 ">
                      <span translate="no"> Max token </span>: {product?.MaxToken}
                    </p>
                  </div>
                  <div className="my-btn">
                    <Link
                      className="bg-blue-800 text-white p-3 text-center align-middle w-48 rounded-full" 
                      to={`/propertydetails/${product?.id}`}
                    >
                      View Property
                    </Link>
                    <p className="ml-10 mt-3 text-sm">
                      {product?.TotalTokens - product?.Stock ? <>
                        <span translate="no"> Stock </span> : {product?.TotalTokens - product?.Stock}{" "}
                      </> :
                        <> <p className="text-sm text-orange-500 ">Sold Out </p> </>
                      }
                    </p>
                  </div>
                </>
              ) : (
                <Link
                  className="bg-blue-800 text-white p-3 text-center align-middle text-xl w-72 rounded-full" 
                  to={`/propertydetails/${product?.id}`}
                >
                  View Property
                </Link>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyCard;
