import React, { useEffect, useRef, useState } from "react";
import { Spinner, UserSideBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermById } from "../redux/features/termsSlice";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router";
import { formatNumber, formatPercentage } from "../utils/Formatter";
import { createAgreemetPdf, updateAgreementPdf } from "../redux/api";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SignPad from "react-signature-pad-wrapper";
import { setProperty } from "../redux/features/propertySlice";
import { getOrderDetails } from "../redux/api";
import { setMe } from "../redux/features/authSlice";
import { setOrderDetail } from "../redux/features/orderSlice";
import { setFeeDetail } from "../redux/features/feeSlice";
import Loader from "../components/admin/Loader";
import Checkbox from "@mui/material/Checkbox";
import {
  twoPercentage,
  EightPercent,
  montlyProperty,
  montlyPropertyClp,
} from "../utils/PropertyDetailCalculation";

const PendingSignatureDetails = () => {
  const [open, setOpen] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [loading, setLoading] = useState();
  const [imagePath, setImagePath] = useState("");
  const [loader, setLoader] = useState(false);
  const { orderDetail } = useSelector((state) => state.order);
  const { me } = useSelector((state) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const handleClose = () => {
    setAgreeActive(false);
    setOpen(false);
  };
  const signaturePadRef = useRef();

  const { property } = useSelector((state) => state.property);
  const { fees } = useSelector((state) => state.fee);
  const { terms } = useSelector((state) => ({
    ...state.term,
  }));

  useEffect(() => {
    dispatch(fetchTermById());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getOrderDetails(id)
        .then((res) => {
          dispatch(setProperty(res.data.property));
          dispatch(setMe(res.data.user));
          dispatch(setMe(res.data.user));
          dispatch(setOrderDetail(res.data.orderDetails));
          dispatch(setFeeDetail(res.data.fee));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error");
          toast.error(error.response.data.message);
        });
    }
  }, [dispatch]);

  if (loading) {
    return <Spinner />;
  }
  let userInfo = JSON.parse(localStorage.getItem("profile"));

  const clearPad = () => {
    signaturePadRef.current.clear();
  };

  const checkedStatus = (event) => {
    if (event.target.checked) {
      setAgreeActive(true);
    } else {
      setAgreeActive(false);
    }
  };

  const signContract = (id) => {
    if (signaturePadRef.current.isEmpty()) {
      toast.error("Signature can not be empty.");
    } else {
      setLoader(true);
      let payload = {
        imgUrl: signaturePadRef.current.toDataURL(),
        orderDetailId: id,
      };

      createAgreemetPdf(payload)
        .then((res) => {
          setImagePath(res.data.data);
          setOpen(true);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          return toast.error(err?.response?.data?.message);
        });
    }
  };

  const updateSign = () => {
    const payload = { id, imagePath };
    updateAgreementPdf(payload)
      .then(() => {
        toast.success("Document has been signed successfully");
        navigate("/documentSignature");
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        return toast.error(err?.response?.data?.message);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      {loader ? (
        <Loader
          msg1="Please wait... while we integrating you signature."
          msg2="Please don't refresh the page or click back button while integrating signature and generating Pdf document"
        />
      ) : (
        <></>
      )}

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Kindly select checkbox to agree"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="flex-Wrap"
            >
              <section
                dangerouslySetInnerHTML={{ __html: terms }}
                className="SearchResult-body"
              />

              <div className="mt-4">
                <Checkbox
                  type="checkbox"
                  name="agree"
                  value="Agreed"
                  onClick={(e) => checkedStatus(e)}
                />
                <strong>I Accept The Terms And Conditions </strong>
                {/* <input
                  type="checkbox"
                  name="agree"
                  value="Agreed"
                  onClick={(e) => checkedStatus(e)}
                />  */}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>
              <a href={REACT_APP_BASE_API_URL + imagePath} target="_blank">
                View
              </a>
            </Button>
            <Button onClick={handleClose}>Disagree</Button>
            {agreeActive ? (
              <>
                {" "}
                <Button onClick={updateSign} autoFocus>
                  Agree
                </Button>
              </>
            ) : (
              <></>
            )}
          </DialogActions>
        </Dialog>
      </div>
      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                My <strong>Account</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="../assets/images/banner2.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />
            {loading ? (
              "Loading..."
            ) : (
              <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
                <div id="tabContentExample">
                  <div className="table-layout  bg-white">
                    <p className="2xl:text-xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-center text-lg font-bold text-white-800 m-0 p-3 bg-blue-900">
                      <strong className="font-medium text-white">
                        PURCHASE AGREEMENT OF SPACES{" "}
                        <span translate="no"> BRICK TOKENS </span>
                      </strong>
                    </p>
                    <div className="text-container p-5 ">
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        Date: {moment(orderDetail?.createdAt).format("MMMM Do")}{" "}
                        of the year{" "}
                        {moment(orderDetail?.createdAt).format("YYYY")} .
                        <br />
                        <br />
                        User: {me?.firstName} {me?.lastName}
                        <br />
                        E-mail: {me?.email}
                        <br />
                        Address: {me?.address}
                        <br />
                        Country: {me?.country}
                      </p>

                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        User who will be referred to hereinafter as the{" "}
                        <b> "Buyer"</b> , on the one hand; and on the other,{" "}
                        <b> Real InvesToken </b> SpA., company of the line of
                        business of its name, unique tax role No. 77.520.897-k,
                        represented by Mr. Claudio Eduardo Salcedo Gabrielli,
                        Chilean, married, lawyer, identity card No.
                        10.023.104-2, both domiciled for these purposes at
                        Avenida Providencia No. 1650, offices 702 and 703,
                        Providencia commune, Santiago de Chile, hereinafter the{" "}
                        <b> "Seller"</b> ; The execution of a contract for the
                        sale of{" "}
                        <b>
                          {" "}
                          <span translate="no">
                            {" "}
                            SPACIOS BRICK tokens{" "}
                          </span>{" "}
                        </b>{" "}
                        is recorded in accordance with the following:
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>FIRST:</b> <u>General.-</u> This contract is governed
                        by the provisions of the document entitled "Terms and
                        Conditions" published on the website{" "}
                        <a href="#">www.spacios.io</a> , which forms an integral
                        part of this instrument.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>SECOND:</b> <u>Purpose-</u> The purchase and sale
                        falls on a certain number of{" "}
                        <b>
                          {" "}
                          <span translate="no">
                            {" "}
                            SPACIOS BRICK tokens{" "}
                          </span>{" "}
                        </b>{" "}
                        issued by the Seller, which are associated with the
                        department object of investment detailed in Annex I with
                        their respective specifications.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>THIRD:</b>{" "}
                        <u>
                          Price and Quantity of{" "}
                          <span translate="no">tokes </span> .-
                        </u>{" "}
                        The purchase price is: US${" "}
                        {formatNumber(orderDetail?.perTokenPrice)}.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        That the Seller delivers to the Buyer the number of
                        <span translate="no"> tokens </span>:
                        {formatNumber(orderDetail?.propertyToken)} of a fixed
                        value of{" "}
                        <b>
                          {" "}
                          US$ [ US${" "}
                          {formatNumber(
                            property?.TotalPrice / property?.TotalTokens
                          )}{" "}
                          ] for each{" "}
                          <span translate="no"> SPACIOS BRICK Token </span> ;{" "}
                        </b>
                        which are sent to your virtual{" "}
                        <span translate="no"> wallet </span>.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        The Buyer has paid the Seller the Price through his
                        virtual <span translate="no"> wallet </span> in USDC
                        (stable coins equivalent to United States dollars).
                      </p>

                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>FOURTH:</b> <u>Benefits.-</u> Each issued{" "}
                        <b>
                          {" "}
                          <span translate="no"> SPACIOS BRICK token </span>{" "}
                        </b>{" "}
                        intrinsically contains the right for its holder to
                        obtain monthly rental income associated with the
                        specific property leased to third parties, as well as
                        any capital gains or higher values that could be
                        obtained in its sale. future (after 5 years of
                        investment or at a later date).
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>FIFTH:</b> <u>Guarantee.-</u> Each{" "}
                        <span translate="no"> SPACIOS BRICK token </span> issued
                        due to the commissioning of a particular property is
                        guaranteed in direct relation to its equivalent
                        fractional value in the market price of said property.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>SIXTH:</b> <u>Applicable legislation-</u> Both enter
                        into this purchase and sale of{" "}
                        <span translate="no"> SPACIOS BRICKS Tokens </span>
                        taking into account the current regulations of the
                        Fintech Law (Law No. 21,521 of January 4, 2023) and the
                        instructions issued by the Financial Market Commission
                        (CMF). This contract will be governed by the laws of the
                        Republic of Chile.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>SEVENTH:</b> <u>Domicile.-</u> The parties establish
                        their domicile in the city and commune of Santiago and
                        submit to the jurisdiction of its ordinary courts of
                        justice.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                        <b>
                          <u>REPRESENTATIONS</u>{" "}
                        </b>{" "}
                        The legal representative of Real InvestToken SpA., is
                        recorded in a public deed dated January 17, 2022, with
                        repertoire No. 17.01.2022 / 1421 of the Forty-first
                        Notary of Santiago of Mr. Félix Jara Cadot.
                      </p>
                      <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5 ">
                        <b>
                          <u>SIGNATURES:</u>{" "}
                        </b>{" "}
                        <span className="flex mt-3">
                          <span className="inline-block text-center mr-5">
                            <b>BUYER</b>
                          </span>
                          <span className="inline-block text-center">
                            <b>SELLER</b>
                            <br />
                            p.p. Real InvesToken SpA.
                          </span>
                        </span>
                      </p>
                    </div>

                    <div className="document-table-wrapper p-5">
                      <h2 className="2xl:text-2xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-bold text-blue-800 mb-4 text-center">
                        ANNEX I / ANEXO I
                      </h2>

                      <p className="mb-4">
                        <b>
                          <span className="text-blue-800">
                            ADDRESS/DIRECCIÓN:
                          </span>{" "}
                          SANTA ROSA Nº146, Floor 21st, Santiago/CHILE
                        </b>
                      </p>

                      <table className="table-fixed w-full">
                        <thead className="bg-gray-500 text-left">
                          <tr>
                            <th
                              className="text-white 2xl:text-md xl:text:md text-md p-3"
                              colSpan={2}
                            >
                              PROPERTY BACKGROUND/ANTECEDENTES:
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              ANNUAL INCOME (without capital appreciation)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.AnnualIncome)} %
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              CAPITAL APPRECIATION (average estimated)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.CapitalAppreciation)} %
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              TOTAL
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(
                                parseFloat(property?.AnnualIncome) +
                                  parseFloat(property?.CapitalAppreciation)
                              )}{" "}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Total Investment
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {formatNumber(property?.TotalPrice)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Total{" "}
                              <span translate="no"> SPACIOS BRICK Tokens</span>{" "}
                              issues
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.TotalTokens)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              <span translate="no"> Token </span> Price
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(
                                property?.TotalPrice / property?.TotalTokens
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Passive Cash Flow per 1{" "}
                              <span translate="no"> Token </span> (annually)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {formatNumber(property?.PassiveCashFlow)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Capital Gain Incorporated in 1{" "}
                              <span translate="no"> Token </span> (annually)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(property?.CapitalGainIncorporated)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              TOTAL (annually)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(
                                parseFloat(property?.PassiveCashFlow) +
                                  parseFloat(property?.CapitalGainIncorporated)
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table-fixed w-full mt-5">
                        <thead className="bg-gray-500 text-left">
                          <tr>
                            <th
                              className="text-white 2xl:text-md xl:text:md text-md p-3"
                              colSpan={2}
                            >
                              PROPERTY BACKGROUND/ANTECEDENTES:
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Property type
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.PropertyType}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Construction year
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.ConstructionYear}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Size
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.LotSize} sq mts /{" "}
                              {property?.LotSize * 10.763} sq ft
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Bedroom
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.Bedroom}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Bathroom
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.Bathroom}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              kitchen
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.kitchen}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Balcony
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.Livingroom}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Lease Contract
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {property?.LeaseContract}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Common expenses (tenant expense)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {formatNumber(property?.CommonExpenses)} / CL${" "}
                              {formatNumber(property?.CommonExpensesClp)}{" "}
                              (approx.)
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Territorial taxes
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {property?.TerritorialTaxes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table-fixed w-full mt-5">
                        <thead className="bg-gray-500 text-left">
                          <tr>
                            <th
                              className="text-white 2xl:text-md xl:text:md text-md p-3"
                              colSpan={2}
                            >
                              NUMBERS/NÚMEROS:
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Gross Rent (annually)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.GrossRent)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Gross Rent (monthly)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.GrossRent / 12)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Monthly Costs
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.MonthlyCosts)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Property Management
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatPercentage(fees[1]?.percent)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Access to the SPACIOS Platform
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatPercentage(fees[0]?.percent)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Property Taxes
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              {formatNumber(property?.PropertyTaxes)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Net Rent (monthly)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(
                                montlyProperty(
                                  property?.GrossRent,
                                  property?.PropertyTaxes
                                ) * 1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Net Rent (annually)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(
                                montlyProperty(
                                  property?.GrossRent,
                                  property?.PropertyTaxes
                                ) *
                                  1 *
                                  12
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              Total Investment
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {formatNumber(property?.TotalPrice)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              - Underlying Asset Price
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ US${" "}
                              {formatNumber(property?.UnderlyingAssetPrice)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              - Operating Expense Reimbursement
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber((property?.TotalPrice * 10) / 100)}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              - - Initial Maintenance Reserve
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US${" "}
                              {formatNumber(
                                property?.InitialMaintenanceReserve
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                              - Insurance ( years)
                            </td>
                            <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                              US$ {formatNumber(property?.Insurance)}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table-fixed w-full mt-5 mb-5">
                        <tr>
                          <td className="text-md p-3 text-left">
                            PROPERTY DETAILS/DETALLES:
                          </td>
                        </tr>

                        <tr>
                          <td className="text-md p-3 text-center messageBOX">
                            <section
                              dangerouslySetInnerHTML={{
                                __html: property?.DetailsProperty,
                              }}
                              className="SearchResult-body"
                            />
                          </td>
                        </tr>
                      </table>

                      <div
                        className="square border"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "20px",
                        }}
                      >
                        {" "}
                        <span
                          className="square border border-primary squarePad"
                          style={{ border: "1px solid black" }}
                        >
                          <SignPad
                            className="canvasPad"
                            width={700}
                            height={300}
                            ref={signaturePadRef}
                          />

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              justifyContent: "space-evenly",

                              padding: "20px",
                            }}
                          >
                            <input
                              type="button"
                              onClick={clearPad}
                              value="Clear"
                              className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            />
                            <input
                              type="submit"
                              onClick={(e) => signContract(orderDetail?.id)}
                              value="Procced"
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PendingSignatureDetails;
