const moment = require("moment");

const formatNumber = (number, toFxed = 2) => {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: toFxed,
    maximumFractionDigits: toFxed,
  }).format(number);
};
const formatNumberNew = (number, toFxed = 6) => {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: toFxed,
    maximumFractionDigits: toFxed,
  }).format(number);
};
const formatPercentage = (number) => {
  let s = number?.toString()?.replace(".", ",");
  return s;
};

const formatDate = (number) => {
  const myDate = new Date(number);
  let formattedDate = moment(myDate).format("MM/DD/YY HH:mm");
  return formattedDate;
};
module.exports = { formatNumber, formatPercentage, formatDate,formatNumberNew };
