import React, {  useRef, useState } from "react";
import SignPad from "react-signature-pad-wrapper";
import { toast } from "react-toastify";
import { createContractSign } from "../redux/api";
const SignaturePad = () => {
  const signaturePadRef = useRef();
  const [imagePath, setImagePath] = useState("");

  const clearPad = () => {
    signaturePadRef.current.clear();
    //setImagePath("")
  };

  const signContract = () => {
    if (signaturePadRef.current.isEmpty()) {
      toast.error("Signature can not be empty!");
    } else {
      setImagePath(signaturePadRef.current.toDataURL());
      let paload = {
        imgUrl: signaturePadRef.current.toDataURL()
      };
      createContractSign(paload).then((res) => {
      })
        .catch((error) => {
          console.log(error);

        });

    }
  };

  return (
    <>

      <div className="container mx-auto">
        <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">

          <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
            <div id="tabContentExample">
              <div className="table-layout  bg-white">

                <p className="2xl:text-xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-center text-lg font-bold text-white-800 m-0 p-3 bg-blue-900">
                  <strong className="font-medium text-white">
                    PURCHASE AGREEMENT OF  <span translate="no"> SPACES BRICK TOKENS </span>
                  </strong>
                </p>
                <div className="text-container p-5 ">
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    Date: [Date] of the year 2023.-
                    <br />
                    <br />
                    User: [Buyer Name]
                    <br />
                    E-mail: [ ]
                    <br />
                    Tax role: [ ]
                    <br />
                    Address: [Address]
                    <br />
                    Country: [ ]
                  </p>

                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    User who will be referred to hereinafter as the{" "}
                    <b> "Buyer"</b> , on the one hand; and on the other,{" "}
                    <b> Real InvesToken </b> SpA., company of the line of
                    business of its name, unique tax role No. 77.520.897-k,
                    represented by Mr. Claudio Eduardo Salcedo Gabrielli,
                    Chilean, married, lawyer, identity card No. 10.023.104-2,
                    both domiciled for these purposes at Avenida Providencia
                    No. 1650, offices 702 and 703, Providencia commune,
                    Santiago de Chile, hereinafter the <b> "Seller"</b> ; The
                    execution of a contract for the sale of{" "}
                    <b> <span translate="no"> SPACIOS BRICK tokens </span> </b> is recorded in accordance
                    with the following:
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>FIRST:</b> <u>General.-</u> This contract is governed
                    by the provisions of the document entitled "Terms and
                    Conditions" published on the website{" "}
                    <a href="#"><span translate="no"> www.spacios.io </span> </a> , which forms an integral
                    part of this instrument.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>SECOND:</b> <u>Purpose-</u> The purchase and sale falls
                    on a certain number of <b> <span translate="no"> SPACIOS BRICK tokens </span> </b>{" "}
                    issued by the Seller, which are associated with the
                    department object of investment detailed in Annex I with
                    their respective specifications.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>THIRD:</b> <u>Price and Quantity of <span translate="no"> Tokens </span>.-</u> The
                    purchase price is:  US${" "}
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    That the Seller delivers to the Buyer the number of
                    <span translate="no"> tokens </span>:
                    of a fixed value of{" "}
                    <b> US$  for each <span translate="no"> SPACIOS BRICK  Token </span>; </b>
                    which are sent to your virtual <span translate="no"> wallet </span>.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    The Buyer has paid the Seller the Price through his
                    virtual <span translate="no"> wallet </span> in USDT (stable coins equivalent to United
                    States dollars).
                  </p>

                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>FOURTH:</b> <u>Benefits.-</u> Each issued{" "}
                    <b> <span translate="no"> SPACIOS BRICK Token </span></b> intrinsically contains the
                    right for its holder to obtain monthly rental income
                    associated with the specific property leased to third
                    parties, as well as any capital gains or higher values
                    that could be obtained in its sale. future (after 5 years
                    of investment or at a later date).
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>FIFTH:</b> <u>Guarantee.-</u> Each <span translate="no"> SPACIOS BRICK token </span>
                    issued due to the commissioning of a particular property
                    is guaranteed in direct relation to its equivalent
                    fractional value in the market price of said property.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>SIXTH:</b> <u>Applicable legislation-</u> Both enter
                    into this purchase and sale of <span translate="no"> SPACIOS  BRICKS Tokens </span>
                    taking into account the current regulations of the Fintech
                    Law (Law No. 21,521 of January 4, 2023) and the
                    instructions issued by the Financial Market Commission
                    (CMF). This contract will be governed by the laws of the
                    Republic of Chile.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>SEVENTH:</b> <u>Domicile.-</u> The parties establish
                    their domicile in the city and commune of Santiago and
                    submit to the jurisdiction of its ordinary courts of
                    justice.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5">
                    <b>
                      <u>REPRESENTATIONS</u>{" "}
                    </b>{" "}
                    The legal representative of Real InvestToken SpA., is
                    recorded in a public deed dated January 17, 2022, with
                    repertoire No. 17.01.2022 / 1421 of the Forty-first Notary
                    of Santiago of Mr. Félix Jara Cadot.
                  </p>
                  <p className="2xl:text-md text-black dark:text-gray-400 m-0 mb-5 ">
                    <b>
                      <img src={imagePath} alt="" width="100px" />
                      <u>SIGNATURES:</u>
                    </b>{ }
                    <span className="flex mt-3">
                      <span className="inline-block text-center mr-5">
                        <b>BUYER</b>
                      </span>
                      <span className="inline-block text-center">
                        <b>SELLER</b>
                        <br />
                        p.p. Real InvesToken SpA.
                      </span>
                    </span>
                  </p>
                </div>

                <div className="document-table-wrapper p-5">
                  <h2 className="2xl:text-2xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-bold text-blue-800 mb-4 text-center">
                    ANNEX I / ANEXO I
                  </h2>

                  <p className="mb-4">
                    <b>
                      <span className="text-blue-800">
                        ADDRESS/DIRECCIÓN:
                      </span>{" "}
                      SANTA ROSA Nº146, Floor 21st, Santiago/CHILE
                    </b>
                  </p>

                  <table className="table-fixed w-full">
                    <thead className="bg-gray-500 text-left">
                      <tr>
                        <th
                          className="text-white 2xl:text-md xl:text:md text-md p-3"
                          colSpan={2}
                        >
                          PROPERTY BACKGROUND/ANTECEDENTES:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          ANNUAL INCOME (without capital appreciation)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          CAPITAL APPRECIATION (average estimated)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          TOTAL
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Total Investment
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Total <span translate="no"> SPACIOS BRICK Tokens </span> issues
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          <span translate="no"> Token </span> Price
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US${" "}

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Passive Cash Flow per 1 <span translate="no"> Token </span> (annually)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US${" "}

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Capital Gain Incorporated in 1 <span translate="no"> Token </span> (annually)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US${" "}

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          TOTAL (annually)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US${" "}

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table-fixed w-full mt-5">
                    <thead className="bg-gray-500 text-left">
                      <tr>
                        <th
                          className="text-white 2xl:text-md xl:text:md text-md p-3"
                          colSpan={2}
                        >
                          PROPERTY BACKGROUND/ANTECEDENTES:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Property type
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Construction year
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Size
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Bedroom
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Bathroom
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          1
                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          kitchen
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Balcony
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          1
                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Lease Contract
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">

                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Common expenses (tenant expense)
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US${" "}

                          (approx.)
                        </td>
                      </tr>
                      <tr>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-left">
                          Territorial taxes
                        </td>
                        <td className="border  2xl:text-md xl:text:md text-md p-3 text-right">
                          US$
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="square border" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
      }}> <span className="square border border-primary">
          <SignPad width={700} height={300} ref={signaturePadRef} />




          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            justifyContent: "space-evenly",
            padding: "20px"
          }}>
            <input type="button" onClick={clearPad} value="Clear" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" />
            <input type="submit" onClick={signContract} value="Procced" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" />
          </div>




        </span>

      </div>
      <form>


      </form>
    </>
  );
};

export default SignaturePad;
