import React, { useEffect } from "react";
import { fetchLearn } from "../redux/features/learnSlice";
import { useDispatch, useSelector } from "react-redux";
import { LearnCard, Spinner } from "../components";
function Learn() {
  const { learns, loading } = useSelector((state) => ({
    ...state.learn,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLearn());
  }, [dispatch]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      <section id="inner-banner" className="bg-gray-700 2xl:h-60">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 w-full mx-auto text-center">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                Learn <strong>About</strong> <span translate="no"> SPACIOS  Brick  Token </span>
              </h2>
              <p className="2xl:text-lg lg:text-lg xl:text-lg md:text-md sm:text-sm text-sm block font-normal w-full text-white">
                Learn how to invest in 'tokenized real estate' to obtain
                permanent passive income = cash flow + capital gains + stable
                returns over time; thanks to the benefits of <span translate="no"> blockchain </span>
                technology.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="property" className="py-12 bg-gray-100">
        <div className="container mx-auto max-w-50">
          <div className="section-content flex md:flex-row sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col flex-wrap">
            {learns.length === 0 && <h1>No Learn Education Post found</h1>}

            {learns &&
              learns.map((item, index) => <LearnCard key={index} {...item} />)}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Learn;
