import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropertyCard, Spinner } from "../components";
import { getPropertiesByStatus } from "../redux/features/propertySlice";


const Marketplace = () => {

  const { propertiesStatus, loading } = useSelector((state) => ({
    ...state.property,
  }));
  const { user } = useSelector((state) => ({
    ...state.auth,
  }));

  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(getPropertiesByStatus());


  }, [dispatch, user]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <section id="inner-banner" className="bg-gray-700 2xl:h-full">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                Our <strong>Fractional</strong> Properties.
              </h2>
              <h5 className="2xl:text-3xl xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl text-2xl  font-medium text-white">
                Cash flow of properties located in different
                <br />
                parts of the world.
              </h5>
              <div className="flex flex items-center mt-10">
                <ul>
                  <li className="border-style 2xl:mb-12 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-5">
                    <div className="main-content">
                      <p className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-light text-white">
                        Welcome to investing in{" "}
                        <strong className="font-bold">
                          tokenized real estate.
                        </strong>
                      </p>
                      <p className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-light text-white">
                        Welcome to the possibility of{" "}
                        <strong className="font-bold">
                          being a global investor.
                        </strong>
                      </p>
                      <p className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-light text-white">
                        Welcome to the {" "}
                        <strong className="font-bold">
                          future of the real estate investment…
                        </strong>
                      </p>
                      <p className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-light text-white">
                        Welcome to real estate{" "}
                        <strong className="font-bold">passive income</strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="property" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          {propertiesStatus.length === 0 && (
            <h5 className="text-greay-700 font-medium 2xl:text-4xl text-center 2xl:mt-4 xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl xl">
              No records found.
            </h5>
          )}

          {propertiesStatus &&
            propertiesStatus.map((product) => (
              <PropertyCard key={product.id} product={product} />
            ))}
        </div>
      </section>
      
      
    </>
  );
};

export default Marketplace;
