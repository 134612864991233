import React from "react";
import { useNavigate } from "react-router-dom";
const InvesToken = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>
              What is “<span translate="no"> SPACIOS </span> Real InvesToken”?
            </h2>
            <div className="content-inner">
              <p>
                <span translate="no"> SPACIOS </span> is an asset tokenization
                platform and <span translate="no"> marketplace </span> through
                which small investors can invest through the acquisition of{" "}
                <span translate="no"> tokens </span> in fractional real estate
                properties in different cities around the world that facilitates
                access and capitalization of the benefits of this type of
                investment. stable + the passive income it generates over time.
              </p>
              <p>&nbsp;</p>
              <p>
                <u>
                  <span translate="no"> SPACIOS </span> comes to simplify
                  investment in the real estate sector
                </u>
                :
              </p>
              <p>&nbsp;</p>
              <p>
                &ldquo;
                <em>
                  Our mission as an asset tokenization platform and{" "}
                  <span translate="no"> marketplace </span>
                  is to spread financial freedom through easy access to selected
                  real estate opportunities, which offer a powerful asset with a
                  great risk-return ratio (cash flow)
                </em>
                &rdquo;
              </p>
              <p>
                (
                <strong>
                  <span translate="no"> SPACIOS </span> Real InvesToken
                </strong>
                ).
              </p>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
              <div className="button-bottom">
                <button onClick={() => navigate(-1)} className="button-custom">
                  <p>Previous</p>
                  <i className="fas fa-arrow-left"></i>
                  Tokenization of real estate assets work
                </button>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
              <div className="button-bottom">
                <button
                  onClick={() => navigate("/faq/")}
                  className="button-custom left-btn-last"
                >
                  <p>Next FAQ</p>
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvesToken;
