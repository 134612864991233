import { padding } from "@mui/system";
import React from "react";

function LearnCard({
  externalLink,
  summary,
  title,
  linkTitle,
  imageFile,
  videoFile,
}) {
  const result = summary.slice(0, 300) + "...";
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  return (
    <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1sm:w-full w-full content-inner">
      <div className="card bg-white p-5 m-3 card_heiht text-center">
        <h5 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
          {title}
        </h5>
        <p className="font-normal 2xl:text-xl xl:text-2xl lg:text-lg md:text-lg mt-3 text-left">
          {result}
        </p>
        <div></div>

        {videoFile ? (
          <div>
            <video controls>
              <source src={`${baseUrl}${videoFile}`} type="video/mp4"></source>
            </video>
          </div>
        ) : (
          ""
        )}

        {imageFile ? (
          <button
            className="w-50 mt-4 rounded-full text-blue-800 border border-blue-800 hover:bg-blue-800 hover:text-white active:bg-blue-800 font-bold uppercase px-8 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <a
              target="_blank"
              href={`${baseUrl}${imageFile}`}
              rel="noopener noreferrer"
            >
              More Info
            </a>
          </button>
        ) : (
          ""
        )}
        <button
          className="w-50 mt-4 rounded-full text-blue-800 border border-blue-800 hover:bg-blue-800 hover:text-white active:bg-blue-800 font-bold uppercase px-8 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
          <a target="_blank" href={externalLink} rel="noopener noreferrer">
            {linkTitle}
          </a>
        </button>
      </div>
    </div >
  );
}

export default LearnCard;
