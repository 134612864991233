import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createLearn,
  editLearn,
  getLearn,
} from "../../redux/features/learnSlice";
import { Spinner } from "../../components";

const initialState = {
  title: "",
  summary: "",
  externalLink: "",
  linkTitle: ""
};

const NewLearn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { learnId } = useParams();
  const [learnData, setLearnData] = useState(initialState);
  const { title, summary, externalLink, linkTitle } = learnData;
  const { error, loading, learn } = useSelector((state) => ({
    ...state.learn,
  }));
  const [file, setFile] = useState("");
  const [video, setVideo] = useState("");

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setLearnData({ ...learnData, [name]: value });
  };

  const handlePhoto = async (e) => {
    if (e.target.files[0]) {
      setFile({
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      });
    }
  };

  const handleVideo = async (e) => {
    if (e.target.files[0]) {
      setVideo({
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    const { name, value } = e.target;
    setLearnData({ ...learnData, [name]: value });
    let updatedLearndata = { ...learnData };

    // if (learnId) {
    //   dispatch(editLearn({ learnId, updatedLearndata, navigate, toast }));
    // } else {
    updatedLearndata = formData;
    dispatch(createLearn({ updatedLearndata, navigate, toast }));
    // }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    setLearnData({ ...learn });
  }, [learn]);

  useEffect(() => {
    if (learnId) {
      dispatch(getLearn(learnId));
    }
  }, [dispatch, learnId]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div></div>
          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="formInput">
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title" required
                    onChange={onInputChange}
                  // value={title}
                  />
                </div>
                <div className="formInput">
                  <label>Summary</label>
                  <textarea
                    name="summary"
                    id=""
                    rows={10}
                    cols={50}
                    required
                    // value={summary}
                    onChange={onInputChange}
                  ></textarea>
                </div>
                <div className="formInput">
                  <label>External Link</label>
                  <input
                    type="url"
                    name="externalLink"
                    id="externalLink"
                    required
                    onChange={onInputChange}
                  // value={externalLink}
                  />
                </div>
                <div className="formInput">
                  <label>Link Title</label>
                  <input
                    type="text"
                    name="linkTitle"
                    id="linkTitle"
                    required
                    onChange={onInputChange}
                  // value={linkTitle}
                  />
                </div>
                <div className="formInput">
                  <label>Document</label>
                  <input
                    type="file"
                    multiple={false}
                    required
                    onChange={handlePhoto}
                    name="imageFile"
                  />
                  <img src={file.preview} />
                </div>
                <div className="formInput">
                  <label>Video</label>

                  <input
                    type="file"
                    multiple={false}
                    required
                    onChange={handleVideo}
                    name="videoFile"
                  />
                  <iframe src={video.preview} />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Create</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewLearn;
