import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { formatNumber, formatDate } from "../../utils/Formatter";
import { GetUserPendingRentDistribution } from "../../redux/api";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
const Rentdeatils = () => {
  const [rentData, setRentData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await GetUserPendingRentDistribution(id);
        setRentData(result.data.getRentDetails);

      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };
  const RentDetailsColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 150,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 10) + " "}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) =>
        `${params?.row?.Property_Details?.PropertyAddress}`,
    },

    {
      field: "monthlyRent",
      headerName: "Property Total Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare)}`;
      },
    },
    {
      field: "totalPropertyToken",
      headerName: "Total Property Tokens",
      width: 200,
      valueGetter: (params) => {
        return `${formatNumber(params?.row?.totalPropertyToken)}`;
      },
    },
    {
      field: "totalTokens",
      headerName: "User Bought Tokens",
      width: 200,
      valueGetter: (params) => {
        return `${formatNumber(params?.row?.totalTokens)}`;
      },
    },
    {
      field: "userGrossMonthlyRentShare",
      headerName: "User Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare, 4)}`;
      },
    },
    {
      field: "platformFeeShare",
      headerName: "Plateform Fee",
      width: 150,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.platformFeeShare, 4)}`;
      },
    },
    {
      field: "propertyFeeShare",
      headerName: "Property Management Fee",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.propertyFeeShare, 4)}`;
      },
    },
    {
      field: "userNetMonthlyRent",
      headerName: "User Net Monthly Rent",
      width: 170,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userNetMonthlyRent, 4)}`;
      },
    },
    {
      field: "rentOfDays",
      headerName: "Rent of Days",
      width: 120,
      valueGetter: (params) => {
        return params?.row?.rentOfDays;
      },
    },
    {
      field: "createdAt",
      headerName: "Rent Generated Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.createdAt);
      },
    },
    {
      field: "rentDate",
      headerName: "Rent of Month",
      width: 150,
      valueGetter: (params) => {
        return moment(params?.row.rentDate).format("MMMM, YYYY");
      },
    },
    {
      field: "rentSentDate",
      headerName: "Rent Transfer Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.rentSentDate);
      },
    },
    {
      field: "Action",
      headerName: "Payment Status",
      width: 150,
      renderCell: (params) => {
        return (<>
          {
            params.row.hasTransferred ?
              (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Transferred"
                    color="success"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
              : (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Pending"
                    color="error"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
          }
        </>);
      },
    },
  ];
  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <div className="datatable">
            <div className='mb-2'>
              <Link to={"/admin/rent"}>
                <Button variant="contained" className="mb-4">
                                Back
                </Button>
              </Link>
            </div>
                        
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={rentData ? rentData : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={RentDetailsColumn}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rentdeatils;
