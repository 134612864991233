import React from "react";

const PrivacyPolicy = () => {
  return (
    <section id="property" className="py-12">
      <div className="container mx-auto">
        <h1 style={{ fontSize: "32px" }}>Privacy Policy</h1>
        <p>This is dummy text</p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
