import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createTeam = createAsyncThunk(
  "team/createTeam",
  async (
    { updatedTeamData, dispatch, navigate, toast },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.createTeam(updatedTeamData);
      toast.success("Team Added Successfully");
      navigate("/admin/team");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTeams = createAsyncThunk(
  "team/getTeams",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getTeams();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  "team/deleteTeam",
  async ({ id, Swal }, { rejectWithValue }) => {
    try {
      const response = await api.deleteTeam(id);
      Swal.fire("Deleted!", "", "success");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "team/updateTeam",
  async ({ teamId, updatedTeamData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.updateTeam(updatedTeamData, teamId);
      toast.success("Team Updated Successfully");
      navigate("/admin/team");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTeam = createAsyncThunk(
  "team/getTeam",
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await api.team(teamId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState: {
    team: {},
    teams: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [createTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [createTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.teams = [action.payload];
    },
    [createTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTeams.pending]: (state, action) => {
      state.loading = true;
    },
    [getTeams.fulfilled]: (state, action) => {
      state.loading = false;
      state.teams = action.payload;
    },
    [getTeams.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTeam.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.teams = state.teams.filter((item) => item.id !== id);
      }
    },
    [deleteTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTeam.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.teams = state.teams.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [updateTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [getTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.team = action.payload;
    },
    [getTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default teamSlice.reducer;
