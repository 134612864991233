import React from "react";
import { formatNumber } from "../utils/Formatter";

const { REACT_APP_BASE_API_URL } = process.env;

const PortfolioCard = ({ product }) => {
  return (
    <>
      <div className="p-3 h-full">
        <div className="border flex  p-3 rounded h-full dBlock_Sec">
          <div className="md:w-1/5 2xl:w-1/5 xl:w-1/5  w100">
            <img
              src={`${REACT_APP_BASE_API_URL}${product?.Order_Details?.PropertyAvatar}`}
              height="auto"
              width="100%"
              alt="property avatar"
            />
          </div>
          <div className="md:w-3/4 pl-3 2xl:w-3/4 xl:w-3/4 protContent_Box">
            <p className="font-bold 2xl:text-xl xl:text-1xl custom-heading text-black text-justify mt-2 ml-2">
              {product?.Order_Details?.PropertyAddress}
            </p>
            <div className="flex mt-14 justify-between blockSec">
              <div className="md:w-1/2 2xl:w-1/2 xl:w-1/2 ">
                <p className="md:text-1xl sm:text-lg cus-p ml-2">
                  <span translate="no"> tokens </span> <span> </span>
                </p>
                <p className="md:text-1xl sm:text-lg font-bold ml-2">
                  {product?.propertyToken}
                </p>
              </div>
              <div className="md:w-1/2 2xl:w-1/2 xl:w-1/2">
                <p className="md:text-1xl sm:text-lg cus-p text-sm">
                  Total price <span></span>
                </p>
                <p className="md:text-1xl sm:text-lg font-bold">
                  ${formatNumber(product?.Order_Details?.TotalPrice)}
                </p>
              </div>
              <div className="md:w-1/2 2xl:w-1/2 xl:w-1/2">
                <p className="md:text-1xl sm:text-lg cus-p">
                  <span translate="no"> Token </span> price <span></span>
                </p>
                <p className="md:text-1xl sm:text-lg font-bold">
                  ${formatNumber(product?.perTokenPrice)}
                </p>
              </div>
              <div className="md:w-1/2 2xl:w-1/2 xl:w-1/2">
                <p className="md:text-1xl sm:text-lg cus-p ">
                  Value held <span></span>
                </p>
                <p className="md:text-1xl sm:text-lg font-bold">
                  $
                  {formatNumber(
                    product?.propertyToken * product?.perTokenPrice
                  )}
                </p>
              </div>
              <div className="md:w-1/2 2xl:w-1/2 xl:w-1/2">
                <p className="md:text-1xl sm:text-lg cus-p ">
                  Fraction held <span></span>
                </p>
                <p className="md:text-1xl sm:text-lg font-bold">
                  {formatNumber(
                    (
                      (product?.propertyToken /
                        product?.Order_Details?.TotalTokens) *
                      100
                    ).toFixed(2)
                  )}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioCard;
