import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { getPropertiesByStatus } from "../redux/features/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../components/HomeSlider";
import { Spinner } from "../components";
import { sendUserUpdate } from "../redux/api";
import { OFFER, OFFER_MSG, EMAIL_EMPTY } from "../constants/Messages";

const Home = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const { loading, propertiesStatus } = useSelector((state) => ({
    ...state.property,
  }));
  const { user } = useSelector((state) => ({
    ...state.auth,
  }));
 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(getPropertiesByStatus());
      
  }, [dispatch, user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error(EMAIL_EMPTY);
    }

    const btn = document.getElementById("myBtn");
    btn.style.visibility = "hidden";
    sendUserUpdate({
      to: email,
      subject: OFFER,
      text: OFFER_MSG,
    })
      .then((response) => {
        btn.style.visibility = "visible";
        return toast.success(response?.data?.message);
      })
      .catch((error) => {
        btn.style.visibility = "visible";
        return toast.error(error?.response?.data?.message);
      });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section id="slider">
        <div className="container mx-auto">
          <div className="flex-custom">
            <div className="left-content 2xl:pt-36 xl:pt-24 lg:pt-20 md:pt-16 sm:pt-12">
              <div className="flex flex items-center">
                <ul>
                  <li className="border-style 2xl:mb-12 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-5">
                    <div className="headiing">
                      <h2 className="2xl:text-9xl xl:text-7xl lg:text-5xl md:text-5xl sm:text-3xl text-3xl font-black mb-5">
                        Cash <span className="font-light">Flow </span>
                      </h2>
                      <h5 className="2xl:text-4xl xl:text-3xl lg:text-3xl md:text-2xl sm:text-2xl text-2xl  font-medium">
                        Via investment in fractional real estate{" "}
                        <a href="http://">(tokenized)</a>
                      </h5>
                    </div>
                  </li>
                  <li className="border-style 2xl:mb-12 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-5">
                    <div className="main-content 2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-regular">
                      {" "}
                      Have you ever heard of someone buying real estate for just
                      US$ 100.- {" "}
                    </div>
                  </li>
                  <li className="border-style 2xl:mb-12 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-5">
                    <div className="main-content 2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-regular">
                      {" "}
                      Join the <strong><span translate="no"> BRICK FOUNDING </span>  </strong>trend{" "}
                    </div>
                  </li>
                  <li className="border-style 2xl:mb-12 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-5">
                    <div className="main-content">
                      <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-lg font-medium mb-5">
                          What is {" "}
                        <span translate="no"> SPACIOS Brick Token</span> ?
                      </h3>
                      <p className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg text-lg font-regular">
                        <span translate="no"> BRICK Token </span> is a unit of
                        value (digital cryptoactive) issued by{" "}
                        <span translate="no"> SPACIOS </span> that represents a
                        fraction of the real estate in which it is being
                        invested.{" "}
                      </p>
                      <h5 className="2xl:text-2xl lg:text-xl xl:text-xl md:text-xl sm:text-lg  text-lg font-bold flex items-center my-3">
                        <span translate="no"> SPACIOS Brick Token </span>={" "}
                        <img
                          alt=""
                          className="w-20"
                          src="assets/images/triangular.png"
                        />{" "}
                        (1 <span translate="no"> token </span> = US$ 100.-){" "}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right-image">
              <img
                alt=""
                src="assets/images/banner.png"
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="risk-Factor" className="py-12">
        <div className="container mx-auto">
          <div className="heading text-center mb-12">
            <h2 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl font-bold text-2xl">
              {" "}
              Passive <span className="font-normal">Income</span>
              <span className="text-blue-900 mx-3 font-normal  2xl:text-5xl xl:text-5xl lg:text-5xl md:text-5xl sm:text-5xl text-2xl">
                +
              </span>
              Capital <span className="font-normal">Gain</span>
              <span className="text-blue-900 mx-3 font-normal 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-5xl sm:text-5xl text-2xl">
                +
              </span>
              Low <span className="font-normal">Risk</span>
            </h2>
          </div>
          <div className="section-content flex md:flex-row sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col chartSec">
            <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1sm:w-full w-full">
              <img alt="" src="assets/images/img2.png" className="p-5" />
            </div>
            <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1sm:w-full w-full">
              <div className="table-responisve flex items-center h-full justify-center">
                <table className="table-auto hover:table-fixed custom-table">
                  <thead>
                    <tr>
                      <th className="bg-blue-800 text-white p-3 text-left align-middle text-xl" />
                      <th className="bg-blue-800 text-white p-3 text-left align-middle text-xl">
                        1 year
                      </th>
                      <th className="bg-blue-800 text-white p-3 text-left align-middle text-xl">
                        Total 5 years
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        Passive Income{" "}
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        6%
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        30% aprox.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        Capital Gain (estimated)
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        3%
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        15% aprox.
                      </td>
                    </tr>
                    <tr>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        Total Return{" "}
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        9%
                      </td>
                      <td className="text-black p-3 text-left align-middle text-lg">
                        More than 45% aprox.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Property-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="heading text-center mb-12">
            <h2 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl font-bold text-4xl">
              {" "}
              Properties <span className="font-normal mr-3"> to Invest in</span>
              Passive Income
            </h2>
            <h5 className="text-greay-700 font-medium 2xl:text-4xl text-center 2xl:mt-4 xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl xl">
              (Low Risks Investments in Real Estate)
            </h5>
          </div>
          <div className="property-slider">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Slider {...settings}>
                  {propertiesStatus &&
                    propertiesStatus.map((item) => (
                      <>
                        <HomeSlider key={item.id} {...item} />
                      </>
                    ))}
                </Slider>
              </>
            )}
          </div>
        </div>
      </section>
      <section id="Property-Factor" className="py-12 bg-white">
        <div className="container mx-auto">
          <div className="heading text-center mb-12">
            <h2 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl font-bold text-4xl">
              {" "}
              Be <span className="font-normal mr-3">Glocal</span>
              <span className="text-blue-700 font-normal">!</span>
            </h2>
            <h5 className="text-greay-700 font-medium 2xl:text-4xl text-center 2xl:mt-4 xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-xl">
              Invest globally and choose locally
            </h5>
          </div>
          <div className="top-image 2xl:w-1/2 xl:w-1/2  mx-auto">
            <img alt="" src="assets/images/img3.png" />
          </div>
          <div
            className="bottom-content relative bg-cover"
            style={{ backgroundImage: "url(assets/images/img4.png)" }}
          >
            <div className="flex justify-between flex-wrap items-center 2xl:h-96 xl:h-96 lg:h-96">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/2 sm:w-full sm:w-full 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-5 md:p-5 sm:mb-5 mb-5 ">
                <div className="contenthome">
                  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    <span translate="no"> SPACIOS BRICK Tokens </span>
                  </h2>
                  <p className="font-normal 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    They represent the fractionation in the property of the real
                    estate.
                  </p>
                </div>
              </div>
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 sm:w-full 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5 mb-5 sm:w-full md:w-1/2 md:mb-5 md:p-5">
                <div className="contenthome">
                  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    Legal Entity
                  </h2>
                  <p className="font-normal 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    A Proptech legal entity holds real estate as its asset, as
                    real estate cannot be directly tokenized.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between 2xl:items-end xl:items-end lg:items-center 2xl:h-96 xl:h-96 lg:h-96">
              <div className="2xl:w-1/3 xl:w-1/3 2xl:ml-52 xl:ml-52 lg:ml-52 md:ml-0 lg:w-1/4 md:w-1/2 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-5 md:mb-5 sm:mb-5 mb-5 md:p-5 sm:w-full sm:w-full">
                <div className="contenthome relative custom-position">
                  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    Monthly Cash Flow Payments
                  </h2>
                  <p className="font-normal 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    Owning property with{" "}
                    <span translate="no"> SPACIOS BRICK token </span> allows you
                    to collect cash flow (rent) in an easy way (in US dollars
                    with stablecoin sent to your{" "}
                    <span translate="no">wallet</span> .
                  </p>
                </div>
              </div>
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/2 md:mb-5 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5 mb-5 md:p-5 sm:w-full sm:w-full">
                <div className="contenthome">
                  <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    Management
                  </h2>
                  <p className="font-normal 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg">
                    The property management company handle: tenants, collects
                    rents, and manages repairs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Property-Factor" className="py-12 bg-white shadow-lg">
        <div className="container mx-auto">
          <div className="heading text-center mb-12">
            <h5 className="text-greay-700 font-medium 2xl:text-4xl text-center 2xl:mt-4 xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-xl">
              Passive Lease Income Cash Flow Automation
            </h5>
          </div>
          <div className="image-icons 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-full mx-auto">
            <img alt="" src="assets/images/img5.png" />
          </div>
        </div>
      </section>
      <section id="benefit" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="heading text-center mb-12">
            <h2 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl font-bold text-2xl">
              {" "}
              Benefits <span className="font-normal mr-3">of real estate</span>
              <span className="text-blue-700 font-bold">tokenization</span>
            </h2>
          </div>
          <div className="flex">
            <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full 2xl:block xl:block lg:block md:block sm:block hidden">
              <h2
                style={{ textAlign: "center" }}
                className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg"
              >
                Benefits
              </h2>
            </div>
            <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full 2xl:block xl:block lg:block md:block sm:block hidden">
              <h2
                style={{ textAlign: "center" }}
                className="font-bold 2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg"
              >
                Details
              </h2>
            </div>
          </div>
          <div className="listing">
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Fraccionalization
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Assets such a real estate have high barrier to entry due to
                    large upfront capital required.
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Fractionalizing such assets democratizes its access for
                    smaller investors.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl  listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Customizability
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Tokenization enables exposure to individual real estate
                    assets. Thus, instead of investing in the whole sector,
                    portfolio can be customized down to single buildings.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Liquidity
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Fractionalization increases the pool of potential investors
                    and can unlock global investor base{" "}
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Secondary markets also facilitate additional liquidity.{" "}
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Liquid assets command a premium and can increase asset
                    value.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Automation
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Smart contracts can automate steps such as compliance,
                    document verification, trading an escrow.
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Dividends and cash flows can be programmatically paid when
                    due.
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Liquid assets command a premium and can increase asset
                    value.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Cost Efficiency
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    By removing certain intermediaries and increasing efficiency
                    of processes, costs can be lowered.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Settlement Time
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Toke<span translate="no"> tokens </span>ns can settle in minutes or hours (depending on the
                    underlying <span translate="no"> blockchain </span>).
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    This unlocks the capital that is tied in the market which
                    currently settles al T+3/T+2.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Date Transparency
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Secure and visible recordkeeping on{" "}
                    <span translate="no"> blockchain </span> can increase
                    transparency to the underlying data.
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    {" "}
                    Especially for complex derivative products, the ability to
                    clearly link a security to its underlying value drivers.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/4 sm:w-1/4 w-full bg-blue-800 text-white p-3 text-left align-middle text-xl listing-left-box">
                <h2 className="font-normal text-center 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                  Structured Products
                </h2>
              </div>
              <div className="2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-3/4  w-full  p-3 text-left align-middle text-xl border border-black">
                <ul>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Additional value can be realized once assets are tokenized
                    and the enables the creation of additional layered financial
                    products such as basket of assets and derivatives.{" "}
                  </li>
                  <li className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
                    Since the underlying is tokenized, creating complex products
                    becomes, simpler through coded smart contracts.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Property-Factor" className="py-12 bg-gray-700 shadow-lg">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-full">
              <img alt="" src="assets/images/img6.png" />
            </div>
            <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-full">
              <div className="flex items-center h-full w-full justify-center flex-wrap">
                <div className="custom-center">
                  <h2 className="mb-5 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-2xl sm:text-2xl text-white font-normal text-center w-full">
                    Interested in Updates?
                  </h2>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="flex align-center justify-betweenflex align-center justify-between relative">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        title="Please press the enter button"
                        onChange={(e) => setEmail(e.target.value)}
                        className="h-16 rounded-full border-0 bg-white w-full px-12"
                        required
                      />
                      <input
                        type="submit"
                        value="Submit"
                        className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full absolute right-0 h-full w-20"
                        id="myBtn"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
