import React from "react";
import { useNavigate } from "react-router-dom";
const Tokenization = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>What is the tokenization of real estate assets?</h2>
            <div className="content-inner">
              <p>
                Tokenize =&gt; 'off-<span translate="no"> blockchain </span>{" "}
                asset' becomes 'in-<span translate="no"> blockchain </span>'
                representation (process requiring legal structure and
                compliance).
              </p>
              <p>&nbsp;</p>
              <p>
                A <span translate="no"> token </span> is the digital
                representation of an asset on the{" "}
                <span translate="no"> blockchain </span>. Tokenize is the
                digitization of an asset &ndash; in this case, real estate
                &ndash; in such a way that the{" "}
                <span translate="no"> token </span>
                captures and represents the value of that asset or asset and is
                thus duly registered in the{" "}
                <span translate="no"> blockchain </span>. Thus, a community of
                real estate <span translate="no"> tokens </span> is created.
              </p>
              <p>&nbsp;</p>
              <p>
                &ldquo;
                <em>
                  Tokenization is the process of representing fractional
                  ownership interest in an asset with a{" "}
                  <span translate="no"> blockchain </span>-based{" "}
                  <span translate="no"> token </span>
                </em>
                &rdquo; (Domus -&ldquo;Tokenization: The future of real estate
                investment?)
              </p>
              <p>&nbsp;</p>
              <p>
                &ldquo;<em>Asset tokenization is a Blue Ocean</em>&rdquo; (Eric
                S&aacute;nchez)
              </p>
              <p>&nbsp;</p>
              <p>
                The tokenization of real estate assets is an extraordinary way
                to generate real estate 'passive income' constantly and
                permanently over time through the collection of cash flow from
                the monthly rental fee of each property that flows to the
                community of associated <span translate="no"> tokens </span> to
                the tokenized property.
              </p>
              <p>&nbsp;</p>
              <p>
                In practice, tokenizing a property is dividing it into smaller
                parts that are represented by{" "}
                <span translate="no"> tokens </span> that represent a right in
                the fractioned totality.
              </p>
              <p>&nbsp;</p>
              <p>
                <u>Reasons to tokenize a property</u>:
              </p>
              <p>- Process automation.</p>
              <p>
                - Fractionation of the property (access to new types of
                investors).
              </p>
              <p>
                - Low investment risk (<span translate="no"> tokens </span>{" "}
                backed by real <span translate="no">Bricks </span> of real
                estate).
              </p>
              <p>- Democratization of real estate investments.</p>
              <p>- Passive income generating monthly cash flows.</p>
              <p>
                - Massive increase in liquidity due to the depth of the
                secondary markets &ndash;platforms&ndash; (liquid{" "}
                <span translate="no"> tokens </span> versus illiquid real estate
                assets).
              </p>
              <p>
                - Diversifies and shares the risk of real estate investment for
                a collective benefit.
              </p>
              <p>&nbsp;</p>
              <p>
                <u>&nbsp;</u>
              </p>
              <p>
                <u>TECHNICAL EXPLANATION</u>: Tokenization refers to the process
                of digitizing securities and their representation as{" "}
                <span translate="no"> tokens </span> on the{" "}
                <span translate="no"> blockchain </span>. The most common types
                of securities that will be tokenized are debt instruments and
                company shares. Once the security has been digitized and
                presented in the form of a{" "}
                <span translate="no"> blockchain </span>{" "}
                <span translate="no"> token </span>, the value can be managed in
                much the same way as bitcoin and other cryptocurrencies
                (tokenized securities are also called 'security
                <span translate="no"> tokens </span>', 'digital shares' and
                'digital assets'). (&ldquo;<span translate="no"> Token </span>{" "}
                Economy: How the Web3 Reinvents the Internet&rdquo; Shermin
                Voshmgir).
              </p>
              <p>&nbsp;</p>
              <p>
                Asset tokenization is one of the trends that will be
                unstoppable. (Organizations like the World Economic Forum are so
                optimistic as to estimate that by 2027 10% of global GDP will be
                tokenized or stored under the{" "}
                <span translate="no"> Blockchain </span>). By tokenizing real
                estate assets, users can conduct direct peer-to-peer
                transactions without the involvement of intermediaries
                (notaries). , lawyers, real estate brokers, banks). This helps
                with a risk-free business and also cuts down on additional
                costs. Tokenization eliminates the long, tedious real estate
                process and helps with efficient, peer-to-peer transactions to
                and from anywhere in the world.
              </p>
              <p>
                <span>&nbsp;</span>
              </p>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate(-1)}
                    className="button-custom"
                  >
                    <p>Previous</p>
                    <i className="fas fa-arrow-left"></i>
                    Difference b/w <span translate="no"> Token </span> and
                    <span translate="no"> cryptocurrency </span> ?
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate("/faq/tokenized")}
                    className="button-custom left-btn-last"
                  >
                    <p>Next FAQ</p>
                    <i className="fas fa-arrow-right"></i>
                    Tokenization of real estate{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tokenization;
