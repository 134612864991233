import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editTerm } from "../../redux/features/termsSlice";
import { Editor } from "@tinymce/tinymce-react";
import { Spinner } from "../../components";

const initialState = {
  pageTitle: "",
  pageDescription: "",
};

const NewTerm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [text, setText] = useState("");
  const [termData, setTermData] = useState(initialState);
  const { error, loading, terms } = useSelector((state) => ({ ...state.term }));

  const { REACT_APP_TINYMCE_API } = process.env;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setTermData({ ...termData, [name]: value });
  };

  const pageDescription = termData?.pageDescription
    ? termData?.pageDescription
    : "Write something.";

  const handleSubmit = (e) => {
    e.preventDefault();
    termData.pageDescription = text;
    const formData = { ...termData };
    if (id) {
      dispatch(editTerm({ id, formData, navigate, toast }));
    }
  };

  useEffect(() => {
    let singleTerm;
    if (id) {
      singleTerm = terms?.find((term) => parseInt(term.id) === parseInt(id));
      setText(singleTerm?.pageDescription);
      setTermData({ ...singleTerm });
    }
  }, [id, terms]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInputTerms">
                  <label>Description</label>
                  <textarea
                    name="pageDescription"
                    id="pageDescription"
                    rows={40}
                    cols={100}
                    value={termData.pageDescription}
                    style={{ display: "none" }}
                  ></textarea>
                  <Editor
                    apiKey={REACT_APP_TINYMCE_API}
                    init={{
                      selector: "textarea",
                      height: 1000,
                      menubar: false,
                      plugins: [
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                        "link image code",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | " +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help" +
                        "undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code",
                    }}
                    initialValue={pageDescription}
                    outputFormat="text"
                    onEditorChange={(newText) => setText(newText)}
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Update</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewTerm;
