import { useEffect, useState } from "react";
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProperties } from "../../redux/features/propertySlice";
import { getUsers } from "../../redux/features/authSlice";
import { formatNumber } from "../../utils/Formatter";
import {
  propertiesCount,
  activeProperties,
  investorDetailsData,
  totalTokens,
  totalPrice,
  yearlyGrossRent,
  monthlyGrossRent,
  yearlyNetRent,
  totalInvestor,
} from "../../redux/features/dashboardSlice";

const Widget = ({ widget }) => {
  const { type, dateInput } = widget;
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => ({
    ...state.property,
  }));

  const { users } = useSelector((state) => ({
    ...state.auth,
  }));

  const { activePropertiesData } = useSelector((state) => ({
    ...state.dashboard,
  }));

  const { investorDetails } = useSelector((state) => ({
    ...state.dashboard,
  }));

  let {
    propertiesCountData,
    totalTokensData,
    totalPriceData,
    yearlyGrossRentData,
    monthlyGrossRentData,
    yearlyNetRentData,
    totalInvestorData,
  } = useSelector((state) => ({
    ...state.dashboard,
  }));

  totalTokensData = formatNumber(totalTokensData * 1);
  propertiesCountData = formatNumber(propertiesCountData * 1);
  totalInvestorData = formatNumber(totalInvestorData * 1);

  const [filteredUser, setFilteredUser] = useState(0);
  const [filteresProperties, setFilteredProperties] = useState(0);
  const [filteredActiveProperties, setFilteredActiveProperties] = useState(0);
  const [filteredInvestors, setFilteredInvestor] = useState(0);
  const [filteredSpaciosToken, setFilteredSpaciosToken] = useState(0);
  const [filteredPropertyPrice, setFilteredPropertyPrice] = useState(0);
  const [filteredGrossRentYear, setFilteredGrossRentYear] = useState(0);
  const [filteredGrossRentMonth, setFilteredGrossRentMonth] = useState(0);

  let count = 0;

  const selectedDateChange = () => {
    //Filter users
    count = 0;
    for (let i = 0; i < users.length; i++) {
      let date = new Date(users[i].createdAt);

      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count++;
      }
    }
    setFilteredUser(count);

    //Filter properties
    count = 0;
    for (let i = 0; i < properties.length; i++) {
      let date = new Date(properties[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count++;
      }
    }
    setFilteredProperties(count);

    //Filter active properties
    count = 0;
    for (let i = 0; i < activePropertiesData.length; i++) {
      let date = new Date(activePropertiesData[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count++;
      }
    }
    setFilteredActiveProperties(count);

    //Filter Investors

    count = 0;
    for (let i = 0; i < investorDetails.length; i++) {
      let date = new Date(investorDetails[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count++;
      }
    }
    setFilteredInvestor(count);

    //Filter total spacios token

    count = 0;
    var finalTokenCount = 0;
    for (let i = 0; i < properties.length; i++) {
      let date = new Date(properties[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count = Number(count) + Number(properties[i].TotalTokens * 1);
      }
    }
    finalTokenCount = formatNumber(count);

    setFilteredSpaciosToken(finalTokenCount);

    //Filter Total Property Price
    count = 0;
    var finalPrice = 0;
    for (let i = 0; i < properties.length; i++) {
      let date = new Date(properties[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count = Number(count) + Number(properties[i].TotalPrice * 1);
      }
    }
    finalPrice = formatNumber(count);
    setFilteredPropertyPrice(finalPrice);

    //Filter gross rent year
    count = 0;
    for (let i = 0; i < properties.length; i++) {
      let date = new Date(properties[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count = Number(count) + Number(properties[i].GrossRent);
      }
    }
    finalRent = formatNumber(count);
    setFilteredGrossRentYear(finalRent);

    //Filter gross rent month
    count = 0;
    var finalRent = 0;
    for (let i = 0; i < properties.length; i++) {
      let date = new Date(properties[i].createdAt);
      const dateYear = date.getFullYear();
      if (dateYear == dateInput) {
        count = Number(count) + Number((properties[i].GrossRent * 1) / 12);
      }
    }
    finalRent = formatNumber(count);
    setFilteredGrossRentMonth(finalRent);
  };

  totalPriceData = formatNumber(totalPriceData);
  yearlyGrossRentData = formatNumber(yearlyGrossRentData);
  monthlyGrossRentData = formatNumber(monthlyGrossRentData);
  yearlyNetRentData = formatNumber(yearlyNetRentData);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getProperties());
    dispatch(propertiesCount());
    dispatch(investorDetailsData());
    dispatch(totalTokens());
    dispatch(totalPrice());
    dispatch(yearlyGrossRent());
    dispatch(monthlyGrossRent());
    dispatch(yearlyNetRent());
    dispatch(totalInvestor());
    dispatch(activeProperties());
    if (users.length > 0) {
      selectedDateChange();
    }
  }, [
    users.length,
    properties.length,
    investorDetails.length,
    activePropertiesData.length,
    dateInput,
  ]);

  let data;
  const diff = 20;

  switch (type) {
    case "users":
      data = {
        title: "Users",
        isMoney: false,
        link: "See all users",
        amount: filteredUser,
      };
      break;
    case "investor":
      data = {
        title: "Investors",
        isMoney: false,
        amount: filteredUser,
      };
      break;
    case "properties":
      data = {
        title: "Properties",
        isMoney: false,
        link: "View all properties",
        amount: filteresProperties,
      };
      break;
    case "totalHouse":
      data = {
        title: "Active Properties",
        isMoney: false,
        amount: filteredActiveProperties,
      };
      break;
    case "totalTokens":
      data = {
        title: "Total Spacios Tokens",
        isMoney: false,
        amount: filteredSpaciosToken,
      };
      break;
    case "totalHousePrice":
      data = {
        title: "Properties Price",
        isMoney: true,
        amount: filteredPropertyPrice,
      };
      break;
    case "totalGrossRentMonth":
      data = {
        title: "Yearly Gross Rent",
        isMoney: true,
        amount: filteredGrossRentYear,
      };
      break;
    case "totalGrossRentYear":
      data = {
        title: "Monthly Gross Rent",
        isMoney: true,
        amount: monthlyGrossRentData,
      };
      break;
    case "totalNetRentMonth":
      data = {
        title: "Yearly Net Rent",
        isMoney: true,
        amount: yearlyNetRentData,
      };
      break;
    default:
      break;
  }

  return (
    <>
      <div className="widget">
        <div className="left">
          <span className="title">{data.title}</span>
          <span className="counter">
            {data.isMoney && "$"} {data.amount}
          </span>
          <Link to={`/admin/${type}`}>
            <span className="link">{data.link}</span>
          </Link>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpIcon />
          </div>
          {data.icon}
        </div>
      </div>
    </>
  );
};

export default Widget;
