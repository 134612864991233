import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";

import {
  deleteSubscriber,
  fetchSubscribe,
} from "../../redux/features/subscribeSlice";

const Subscribes = () => {
  const dispatch = useDispatch();

  const { subscribers } = useSelector((state) => ({
    ...state.subscribe,
  }));
  const handleDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this subscibed user data ?"
      )
    ) {
      dispatch(deleteSubscriber({ id, toast }));
    }
  };

  const SubscribeColumns = [
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "createdAt",
      headerName: "Subscribed Date",
      width: 250,
      valueGetter: (params) => {
        return moment(params?.row.createdAt).format("MMMM Do YYYY");
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchSubscribe());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={subscribers ? subscribers : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={SubscribeColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribes;
