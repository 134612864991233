import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const getSellTokenFunction = createAsyncThunk(
  "token/sell",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getSellToken();
      return response.data.getUser;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  tokenDetails: [],
  error: "",
  loading: false,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: {
    [getSellTokenFunction.pending]: (state) => {
      state.loading = true;
    },
    [getSellTokenFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.tokenDetails = action.payload;
    },
    [getSellTokenFunction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default tokenSlice.reducer;
