import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner, UserSideBar } from "../components";
import { getUsers } from "../redux/features/authSlice";

const MyAccount = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  return (
    <>
      <>
        <section id="inner-banner" className="bg-gray-700 h-132">
          <div className="container mx-auto">
            <div className="inner-flex">
              <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
                <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                  My <strong>Account</strong>{" "}
                </h2>
              </div>
              <div className="right-image">
                <img
                  src="assets/images/banner2.png"
                  className="h-full w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="myaccount-Factor" className="py-12 bg-gray-100">
          <div className="container mx-auto">
            <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
              <UserSideBar />
              <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
                <div id="tabContentExample">
                  <div
                    className="p-4"
                    id="profile-example"
                    role="tabpanel"
                    aria-labelledby="profile-tab-example"
                  >
                    <p className="2xl:text-xl text-gray-500 dark:text-gray-400">
                      Hello{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        {user?.firstName} ( {user?.email} )
                      </strong>
                    </p>
                    <p className="2xl:text-xl text-black dark:text-gray-400 mt-5 mb-12">
                      From your account dashboard you can view your recent
                      orders, manage your shipping and billling addresses, and
                      edit your password and account details.
                    </p>
                    <h2 className="2xl:text-3xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-bold text-blue-800 mb-2">
                      Welcome to <span translate="no"> SPACIOS </span>, your platform for tokenized real
                      estate
                    </h2>
                    <h2 className="2xl:text-3xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-bold text-blue-800 mb-2">
                      Welcome to your passive income real estate…
                    </h2>
                    <h2 className="2xl:text-3xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-bold text-blue-800 mb-2">
                      Welcome to the key to your financial freedom…
                    </h2>
                    <div className="flex flex items-center flex-col mt-12">
                      <h5 className="2xl:text-2xl xl:text-3xl lg:text-3xl md:text-2xl sm:text-2xl text-2xl  font-bold w-full mb-3">
                        Check out <span translate="no"> SPACIOS </span> <span translate="no"> Marketplace </span>
                      </h5>
                      <ul>
                        <li className="border-style 2xl:mb-2 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-3">
                          <div className="main-content 2xl:text-xl lg:text-xl xl:text-xl md:text-lg sm:text-lg text-lg font-regular">
                            After purchasing <span translate="no"> SPACIOS BRICK tokens </span> , please return
                            here to verify your ID.
                          </div>
                        </li>
                        <li className="border-style 2xl:mb-2 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-3">
                          <div className="main-content 2xl:text-xl lg:text-xl xl:text-xl md:text-lg sm:text-lg text-lg font-regular">
                            Upon successful ID verification, and signing of the
                            property's subscription agreement, your <span translate="no"> SPACIOS BRICK tokens </span>
                            can be delivered.
                          </div>
                        </li>
                        <li className="border-style 2xl:mb-2 xl:mb-8 lg:mb-7 md:mb-5 sm:mb-5 p-3">
                          <div className="main-content 2xl:text-xl lg:text-xl xl:text-xl md:text-lg sm:text-lg text-lg font-regular">
                            Need assistance? Please reach out to us via our chat
                            or email us at: help@spacios.tech
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="hidden p-4"
                    id="dashboard-example"
                    role="tabpanel"
                    aria-labelledby="dashboard-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Dashboard tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                  <div
                    className="hidden p-4"
                    id="settings-example"
                    role="tabpanel"
                    aria-labelledby="settings-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Settings tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                  <div
                    className="hidden p-4"
                    id="contacts-example"
                    role="tabpanel"
                    aria-labelledby="contacts-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Contacts tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default MyAccount;
