import React, { useEffect, useState } from "react";
import {
  deleteProperty, getProperties,
} from "../../redux/features/propertySlice";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deployContract, getProperty, propertiesSoldStatus, updatePropertyStatus, genratePropertyReport } from "../../redux/api";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import ABI from "../../_abiProperties";
import Swal from "sweetalert2";
import { formatNumber, formatDate } from "../../utils/Formatter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContractByteCode from "../../constants/ByteCode";



const { REACT_APP_BASE_API_URL } = process.env;

const Properties = () => {
  const dispatch = useDispatch();
  const [isProcessing, setisProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const { properties } = useSelector((state) => ({
    ...state.property,
  }));

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this property?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProperty({ id, Swal }));
      }
    });
  };

  const handlePublish = async (id, status) => {
    status = status ? false : true;
    updatePropertyStatus({ isPublish: status }, id)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getProperties());
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };
  const handleRented = async (id, status) => {
    status = status ? false : true;
    updatePropertyStatus({ Rented: status }, id)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getProperties());
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };

  const handleSold = async (id, soldStatusparam) => {
    let soldStatus = soldStatusparam ? true : false;

    propertiesSoldStatus({ IsSold: soldStatus }, id)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getProperties());
      })
      .catch((error) => {
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleContract = async (id) => {
    if (window.networkChain != "Polygon" && window.networkChain != "Ethereum") {
      return toast.warning("Please Connect Valid Crypto Network");
    }

    var data = await getProperty(id);
    let totkeName = "SpaciosBrick " + data.data.PropertyAddress;
    let tokenContractName = "Spacios " + data.data.PropertyAddress;
    let totalTokens = data.data.TotalTokens;
    setisProcessing(true);
    await deploy(totkeName, tokenContractName, totalTokens, ContractByteCode)
      .then(async (contractAddress) => {
        setisProcessing(false);
        let contract_address = { contractAddress: contractAddress,blockchainNetwork:window.networkChain};
        await deployContract(contract_address, id);
        toast.success("Contract Deployed Successfully");
        dispatch(getProperties());
      })
      .catch((error) => {
        console.log(error);
        setisProcessing(false);
        toast.error("Something Went Wrong");
        return toast.error(error?.response?.data?.message);
      });
  };

  async function deploy(tokenName, tokenContractName, totalToken, bytecode) {

    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    const [account] = await web3.eth.getAccounts();
    const { _address } = await new web3.eth.Contract(ABI)
      .deploy({
        data: bytecode,
        arguments: [tokenName, tokenContractName, totalToken],
      })
      .send({
        from: account,
        gas: web3.utils.toHex(4700000),
        gasPrice: web3.utils.toHex(web3.utils.toWei("30", "gwei")),
      });

    let output = _address;
    return output;
  }
  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());


  const gernrateReport = () => {
    setOpen(false);
    let formData = { startData: startDate, toDate: toDate };
    genratePropertyReport(formData)
      .then((res) => {
        toast.success(res?.data?.message);
        if (res?.data?.path) {
          window.open(`${REACT_APP_BASE_API_URL}${res.data?.path}`);
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = () => {
    // setAgreeActive(false);
    setOpen(false);
  };

  const handlelOpen = () => {
    // setAgreeActive(false);
    setOpen(true);
  };


  const propertyColumns = [
    {
      field: "PropertyAddress",
      headerName: "Property",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={`${REACT_APP_BASE_API_URL}${params.row.PropertyAvatar}`}
              alt="avatar"
            />
            {params.row.PropertyAddress}
          </div>
        );
      },
    },
    {
      field: "MaxToken",
      headerName: "Maximum Token",
      width: 220,
      valueGetter: (params) => `${formatNumber(params?.row.MaxToken * 1)}`,
    },
    {
      field: "SmartContractAddress",
      headerName: "Smart Contract Address",
      width: 200,
      renderCell: (params) => {
        return params.row.SmartContractAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params.row.SmartContractAddress.substring(0, 10) + " "}
            <ContentCopyIcon
              onClick={(e) => clipboard(params.row.SmartContractAddress)}
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "blockchainNetwork",
      headerName: "Blockchain",
      width: 100,
    },
    {
      field: "PropertyType",
      headerName: "Property Type",
      width: 180,
    },
    {
      field: "CreatedAt",
      headerName: "Created Date",
      width: 180,
      valueGetter: (params) => `${formatDate(params?.row.createdAt)}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/admin/properties/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
            {params.row.SmartContractAddress && (
              <>
                <button
                  className="publishButton"
                  onClick={(e) =>
                    handlePublish(params.row.id, params.row.isPublish)
                  }
                >
                  {params.row.isPublish ? "Un-Publish" : "Publish"}
                </button>
                {params.row.isPublish && (
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      handleRented(params.row.id, params.row.Rented)
                    }
                    color={params.row.Rented ? "success" : "error"}
                  >
                    {params.row.Rented ? "Rented | YES" : "Rented | NO"}
                  </Button>
                )}
              </>
            )}
            {!params.row.SmartContractAddress && (
              <>
                {!isProcessing ? (
                  <>
                    <button
                      className="publishContract"
                      onClick={(e) => handleContract(params.row.id)}
                      id="smartbtn"
                    >
                      Deploy Contract
                    </button>
                  </>
                ) : (
                  <button className="publishContract" id="smartbtn">
                    Deploy Contract
                  </button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getProperties());
  }, [dispatch]);





  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate Property Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="formCustom">
                <lable>Select From Data</lable>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="p-3 rounded text-md form-control  border  focus:ring-1" />
              </div>
              <div className="formCustom">
                <lable>Select To Data</lable>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} className="p-3 rounded text-md form-control border  focus:ring-1" />
              </div>
              <div className="mt-4">
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>

            </Button>
            <div className="moadl-footer">
              <button className="btnCancel" onClick={handleClose}>Cancel</button>
              <button className="btnReport" onClick={gernrateReport}>Generate Report</button>
            </div>


          </DialogActions>
        </Dialog>
      </div>

      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <div className="datatableTitle">
              <Link
                to={{
                  pathname: "/admin/properties/new",
                }}
                className="link"
              >
                Add New Property
              </Link>

              <button onClick={handlelOpen} className="link" > Generate Report </button>

            </div>
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={properties ? properties : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={propertyColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Properties;
