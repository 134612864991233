import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { fetchRentListing } from "../../redux/features/orderSlice";
import { getDistributedRentdetailsFunction } from "../../redux/features/rentSlice";
import { updateRentDistribution, getDistributedRentdetails } from "../../redux/api";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { formatNumber, formatDate } from "../../utils/Formatter";
import { DataGrid } from "@mui/x-data-grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Loader from "./Loader";
import { Box, Tab, Tabs, Typography } from "@mui/material";

const Agreements = () => {
  const dispatch = useDispatch();
  const [isProcessing, setisProcessing] = useState(false);
  const { rentListing } = useSelector((state) => ({
    ...state.order,
  }));
  const { rentCompletedPayment } = useSelector((state) => ({
    ...state.rent,
  }));

  // Fetching transfereed payment details
  useEffect(() => {
    dispatch(getDistributedRentdetailsFunction());
  }, [dispatch]);

  const clipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast.success("Copied");
  };

  const handleSendPayment = async (id, receiverAddress, amount) => {
    if (window.networkChain != "Ethereum") {
      return toast.warning("Please Connect Ethereum Network");
    }

    setisProcessing(true);
    let _amount = amount * 1e6;
    await window.TOKENCONTRACT.methods
      .transfer(receiverAddress, _amount.toString())
      .send({ from: window.walletAddress })
      .then((d) => {
        // Update transaction into database after success payemnt

        let transaction_hash = d.transactionHash;
        updateRentDistribution({ transactionHash: transaction_hash }, id)
          .then((res) => {
            toast.success(res.data.message);
            dispatch(fetchRentListing());
            setisProcessing(false);
          })
          .catch((error) => {
            console.log(error);
            setisProcessing(false);
            return toast.error(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        console.log(error);
        return toast.error(
          "Something went wrong, \n Transaction not performed"
        );
      });
    setisProcessing(false);
  };

  const RentDistributeColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 150,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 10) + " "}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "PropertyAddress",
      headerName: "Number of Property",
      width: 250,
      valueGetter: (params) =>
        `${params?.row?.propertyId}`,
    },
    // {
    //   field: "totalPropertyToken",
    //   headerName: "Total Property Tokens",
    //   width: 200,
    //   valueGetter: (params) => {
    //     return params?.row?.totalPropertyToken;
    //   },
    // },

    {
      field: "monthlyRent",
      headerName: "Net Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userNetMonthlyRent)}`;
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: (params) => {
        return (
          <>
            <div className="cellAction">
              {!isProcessing ? (
                params.row.hasTransferred ? (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Transferred"
                        color="success"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      handleSendPayment(
                        params.row.userId,
                        params.row.User_Details.walletAddress,
                        params.row.userNetMonthlyRent
                      )
                    }
                  >
                                        ${formatNumber(params.row.userNetMonthlyRent, 4)} Send Payment
                  </Button>
                )
              ) : (
              // <button disabled className="publishContract" id="paymentbtn">
              //   Processing...
              // </button>
                <></>



              )}
            </div>

          </>

        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/admin/rentdetails/${params.row.userId}`}>
              <Button
                variant="contained"
              >
                                View Details

              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  const RentTransferedColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "User Email",
      headerName: "User Email",
      width: 200,
      valueGetter: (params) => `${params?.row?.User_Details?.email}`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 150,
      renderCell: (params) => {
        return params?.row?.User_Details?.walletAddress ? (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.row?.User_Details?.walletAddress.substring(0, 10) + " "}
            <ContentCopyIcon
              onClick={(e) =>
                clipboard(params?.row?.User_Details?.walletAddress)
              }
              index={params.row.id}
            />
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) =>
        `${params?.row?.Property_Details?.PropertyAddress}`,
    },

    {
      field: "monthlyRent",
      headerName: "Property Total Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare)}`;
      },
    },
    {
      field: "totalPropertyToken",
      headerName: "Total Property Tokens",
      width: 200,
      valueGetter: (params) => {
        return params?.row?.totalPropertyToken;
      },
    },
    {
      field: "userGrossMonthlyRentShare",
      headerName: "User Gross Monthly Rent",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userGrossMonthlyRentShare, 4)}`;
      },
    },
    {
      field: "platformFeeShare",
      headerName: "Plateform Fee",
      width: 150,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.platformFeeShare, 4)}`;
      },
    },
    {
      field: "propertyFeeShare",
      headerName: "Property Management Fee",
      width: 200,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.propertyFeeShare, 4)}`;
      },
    },
    {
      field: "userNetMonthlyRent",
      headerName: "User Net Monthly Rent",
      width: 170,
      valueGetter: (params) => {
        return `$${formatNumber(params?.row?.userNetMonthlyRent, 4)}`;
      },
    },
    {
      field: "rentOfDays",
      headerName: "Rent of Days",
      width: 120,
      valueGetter: (params) => {
        return params?.row?.rentOfDays;
      },
    },
    {
      field: "createdAt",
      headerName: "Rent Generated Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.createdAt);
      },
    },
    {
      field: "rentDate",
      headerName: "Rent of Month",
      width: 150,
      valueGetter: (params) => {
        return moment(params?.row.rentDate).format("MMMM, YYYY");
      },
    },
    {
      field: "rentSentDate",
      headerName: "Rent Transfer Date",
      width: 150,
      valueGetter: (params) => {
        return formatDate(params?.row.rentSentDate);
      },
    },
    {
      field: "Action",
      headerName: "Payment Status",
      width: 150,
      renderCell: (params) => {
        return (<>
          {
            params.row.hasTransferred ?
              (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Transferred"
                    color="success"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
              : (<><Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Pending"
                    color="error"
                    variant="outlined"
                  />
                </Stack>
              </Stack></>)
          }
        </>);
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchRentListing());
  }, [dispatch]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (

    <>

      <div className="list">
        {isProcessing ? (<> <Loader /></>) : (<></>)}

        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <Box>
            <Box>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Pending" />
                <Tab label="Completed" />
              </Tabs>
            </Box>
            <Box>
              {tabIndex === 0 && (
                <Box>
                  <Typography>
                    <div className="datatable">
                      <DataGrid
                        className="datagrid"
                        disableSelectionOnClick
                        rows={rentListing ? rentListing : []}
                        getRowId={(row) => row?.id || row?.data?.id}
                        columns={RentDistributeColumn}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                      />
                    </div>
                  </Typography>
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <Typography>
                    <div className="datatable">
                      <DataGrid
                        className="datagrid"
                        disableSelectionOnClick
                        rows={rentCompletedPayment ? rentCompletedPayment : []}
                        getRowId={(row) => row?.id || row?.data?.id}
                        columns={RentTransferedColumn}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                      />
                    </div>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>


        </div>
      </div>
    </>
  );
};

export default Agreements;
