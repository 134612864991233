import React from "react";
import { useNavigate } from "react-router-dom";
const TokenAndCrypto = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>
              What is a <span translate="no"> token </span>? / What are the
              differences between a '<span translate="no"> token </span>' and a
              'cryptocurrency'?
            </h2>
            <div className="content-inner">
              <p>
                The <span translate="no"> tokens </span> can represent any asset
                in the real world (real estate, fiat currencies, gold and
                commodities in general), so they can be traded, invested,
                delivered or borrowed. Generally, property rights over assets
                are represented by: titles, certificates and public deeds:
              </p>
              <ul>
                <li>
                  Real estate (houses, apartments, offices, land, warehouses,
                  parking lots): public deeds and property titles.
                </li>
                <li>
                  Bonds and debentures: Treasury notes and/or company placements
                </li>
                <li>Shares (stocks): certificates</li>
                <li>Cash: pesos, dollars, euros (paper money with stamps)</li>
                <li>Vehicles: titles</li>
              </ul>
              <p>&nbsp;</p>
              <p>
                A '<span translate="no"> tokens </span>' is the digital
                representation of ownership of an asset (just like public titles
                and deeds), but it only exists on the Internet and cannot be
                duplicated like most files on the Web &ndash; only there may be
                one &ndash; and all your transaction records bear this out.
              </p>
              <p>&nbsp;</p>
              <p>
                &ldquo;
                <em>
                  In a world as liquid as crypto, the borders between &lsquo;
                  <span translate="no"> token </span>&rsquo; and
                  &lsquo;cryptocurrency&rsquo; are very blurred. The '
                  <span translate="no"> token </span>' can be considered as the
                  basic <span translate="no">Brick </span> behind all digital
                  transactions, from the simplest to the most complex, while the
                  cryptocurrency is a '<span translate="no"> token </span>'
                  specifically oriented to serve as a means of payment and store
                  of value.{" "}
                </em>
                <em>
                  But in practice, there is no clear agreement and both concepts
                  are often interchanged or confused
                </em>
                &rdquo;. (Vanessa Pombo BBVA)
              </p>
              <p>&nbsp;</p>
              <p>
                <u>TECHNICAL EXPLANATION</u>: A '
                <span translate="no"> token </span>' in English, digital{" "}
                <span translate="no"> token </span>) is a unit of value based on
                cryptography and '<span translate="no"> blockchain </span>',
                which is issued by a private entity so that it has a specific
                functionality in the digital world, with the value that the
                entity establishes. It works in a similar way to when in the
                real world we exchange our money for chips that activate bumper
                cars, unlock the supermarket cart or allow us to play in a
                casino. Thus, a '<span translate="no"> token </span>' represents
                a utility or a digital asset that can have very diverse
                purposes: it can be used to pay for a job, participate in an
                'online' game, carry out a decentralized finance (DeFi)
                operation, accredit the possession of the shares of a company or
                the right to receive its dividends, give access to specific
                services on a platform or activate a reward in a loyalty
                program, among many other possibilities. A 'cryptocurrency', on
                the other hand, is a '<span translate="no"> token </span>' whose
                main purpose is to serve as a decentralized means of payment for
                products and services in the virtual environment, as well as a
                reserve of value and savings.
              </p>
              <p>&nbsp;</p>
              <p>
                Another way to distinguish them is by the properties that
                usually characterize cryptocurrencies, which do not have to
                comply with the '<span translate="no"> tokens </span>': being
                fungible, divisible and portable and having a limited supply.
                However, it is very common for concepts to be interchanged. For
                example, platforms such as CoinMarketsCap classify Bitcoin,
                Ether and Cardano as cryptocurrencies (native '
                <span translate="no"> tokens </span>' of the Bitcoin,{" "}
                <span translate="no"> Ethereum</span> and ADA platforms,
                respectively), but as '<span translate="no"> tokens </span>'
                Tether, Dai and USD Coin,{" "}
                <span translate="no"> 'stablecoins'</span> created in
                <span translate="no"> Ethereum</span>. And ether is considered a
                cryptocurrency but also a '<span translate="no"> token </span>',
                since it is used within <span translate="no"> Ethereum</span> to
                facilitate transactions between users. (Vanessa Pombo).
              </p>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate(-1)}
                    className="button-custom"
                  >
                    <p>Previous</p>
                    <i className="fas fa-arrow-left"></i>
                    Welcome to <span translate="no"> blockchain </span>?
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 xl:w-1/2 xs:w-1/1 px-2">
                <div className="button-bottom">
                  <button
                    onClick={() => navigate("/faq/tokenization")}
                    className="button-custom left-btn-last"
                  >
                    <p>Next FAQ</p>
                    <i className="fas fa-arrow-right"></i>
                    tokenization of real estate assets?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TokenAndCrypto;
