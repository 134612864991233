import React from "react";
const InvenTory = () => {
  return (
    <>
      <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/ md:w-100 sm:w-full w-full">
        <div className="main-tab-content">
          <div className="inner-content">
            <h2>How are properties chosen for inventory?</h2>
            <div className="content-inner">
              <p></p>
            </div>
            <div className="heading-text">
              <h4>
                What is a <span translate="no"> token </span>?
              </h4>
              <p>
                RealT sells real estate property through the internet, allowing
                real estate to be purchased from nearly anywhere in the world.
                RealT properties are fractionalizable, so suitable investors can
                invest any amount that fits their needs. Additionally, RealT has
                reduced the time to purchase real estate from a minimum of 30
                days and numerous steps with third parties, to just 30 minutes
                and on your phone or computer.
              </p>
              <p>
                With tokenization, real estate investments can be made
                affordable to nearly anyone. A single{" "}
                <span translate="no"> token </span> for RealT properties costs
                between $50 – $150 per <span translate="no"> token </span>,
                which are the lowest investment minimums the real estate
                industry is able to offer. Traditional pen+paper competitors to
                RealT have $5,000-10,000 investment minimums.
              </p>
              <p>
                Real estate is also a notoriously illiquid industry. By turning
                real estate into digital <span translate="no"> tokens </span>,
                real estate can be accessed by far larger numbers of potential
                buyers, both due to fractionalization of the{" "}
                <span translate="no"> tokens </span> and the potential reach of
                internet-based markets. The larger market participants that
                digital <span translate="no"> tokens </span> are able to reach
                increases the liquidity of real estate. Additionally, financial
                applications on <span translate="no"> Ethereum </span> (and
                xDai) offer solutions to accessing liquidity for valuable
                tokenized assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvenTory;
