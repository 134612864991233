import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createTerm = createAsyncThunk(
  "term/createTerm",
  async ({ navigate }, { rejectWithValue }) => {
    try {
      const response = await api.createTerm();
      navigate("/admin/term");
      
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTerm = createAsyncThunk(
  "term/fetchTerm",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchTerm();
      return response?.data?.tcDataAll;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchTermById = createAsyncThunk(
  "/term/fetchTermById",
  async (id, { rejectWithValue, navigate }) => {
    try {
      const response = await api.fetchTermById();
      return response.data.tcDataAll[0].pageDescription;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editTerm = createAsyncThunk(
  "term/editTerm",
  async ({ id, formData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.editTerm(formData, id);
      toast.success(response.data.message);
      navigate("/admin/terms");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const termSlice = createSlice({
  name: "term",
  initialState: {
    terms: [],
    term: {},
    error: "",
    loading: false,
  },
  extraReducers: {
    [createTerm.pending]: (state, action) => {
      state.loading = true;
    },
    [createTerm.fulfilled]: (state, action) => {
      state.loading = false;
      state.term = [action.payload];
    },
    [createTerm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [fetchTerm.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTerm.fulfilled]: (state, action) => {
      state.loading = false;
      state.terms = action.payload;
    },
    [fetchTerm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchTermById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTermById.fulfilled]: (state, action) => {
      state.loading = false;
      state.terms = action.payload;
    },
    // [fetchTermById.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
    [editTerm.pending]: (state, action) => {
      state.loading = true;
    },
    [editTerm.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.terms = state.terms.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editTerm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default termSlice.reducer;
