import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
function BlogsDetail() {
  const { blogs } = useSelector((state) => ({ ...state.blog }));
  const [data, setdata] = useState("");
  const dispatch = useDispatch();
  const { blogsId } = useParams();
  useEffect(() => {
    if (blogsId) {
      const singleBlog = blogs.filter(
        (blog) => parseInt(blog.id) === parseInt(blogsId)
      );
      setdata(singleBlog);
    }
  }, [blogsId, dispatch, blogs]);
  return (
    <div>
      <section className="py-5 bg-grey">
        <div className="container mx-auto">
          <div className="flex">
            <div className="w-full">
              <div className="blog-inner-box">
                <h2>{data?.[0]?.title}</h2>
                <h5 className="date">
                  {data?.[0]?.currentDate} <span> {data?.[0]?.tags}</span>
                </h5>
                <section
                  className="blogs-body"
                  style={{ fontSize: "20px" }}
                  dangerouslySetInnerHTML={{ __html: data?.[0]?.description }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogsDetail;
