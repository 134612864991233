import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createEmail = createAsyncThunk(
  "email/createEmail",
  async ({ formdata, dispatch, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createEmail(formdata);
      toast.success("Email Added Successfully");
      navigate("/admin/email");
      return response?.data?.saveEmail;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEmail = createAsyncThunk(
  "email/fetchEmail",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchEmail();
      return response?.data?.fetchEmail;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchPropertyEmail = createAsyncThunk(
  "email/fetchPropertyEmail",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getPropertyEmail();
      return response?.data?.propertyEmail;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deltedEmail = createAsyncThunk(
  "email/deltedEmail",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deltedEmail(id);
      toast.success("Email Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editEmail = createAsyncThunk(
  "email/editEmail",
  async ({ emailId, formdata, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.editEmail(formdata, emailId);
      toast.success("Email Content Updated Successfully");
      navigate("/admin/email");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEmailById = createAsyncThunk(
  "/email/fetchEmailById",
  async (emailId, { rejectWithValue, navigate }) => {
    try {
      const response = await api.getEmailById(emailId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    email: {},
    emails: [],
    error: "",
    loading: false,
  },
  extraReducers: {
    [createEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [createEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.emails = [action.payload];
    },
    [createEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.emails = action.payload;
    },
    [fetchEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchPropertyEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPropertyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.PropertyEmail = action.payload;
    },
    [fetchPropertyEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getEmailById.pending]: (state, action) => {
      state.loading = true;
    },
    [getEmailById.fulfilled]: (state, action) => {
      state.loading = false;
      state.email = action.payload;
    },
    [getEmailById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deltedEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [deltedEmail.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.emails = state.emails.filter((item) => item.id !== id);
      }
    },
    [deltedEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [editEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [editEmail.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.emails = state.emails.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [editEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default emailSlice.reducer;
