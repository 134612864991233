import React, { useEffect, useState } from "react";
import { Spinner, UserSideBar } from "../components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { editAccount, me, setLogout } from "../redux/features/authSlice";
import { useNavigate } from "react-router";

const initialState = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  newPassword: "",
  confirmNewPassword: "",
};

const EditAccount = () => {
  const [formValue, setFormValue] = useState(initialState);

  const dispatch = useDispatch();

  const { firstName, lastName } = formValue;

  const { user, loading } = useSelector((state) => ({ ...state.auth }));

  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  useEffect(() => {
    setFormValue({ ...user });
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName && lastName) {
      const updatedUserData = { ...formValue };
      dispatch(editAccount({ updatedUserData, toast })).then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") dispatch(setLogout());
        else toast.error(res.payload);
      });
    }
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <>
        <section id="inner-banner" className="bg-gray-700 h-132">
          <div className="container mx-auto">
            <div className="inner-flex">
              <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
                <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                  Edit <strong>Account</strong>{" "}
                </h2>
              </div>
              <div className="right-image">
                <img
                  src="assets/images/banner2.png"
                  className="h-full w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="myaccount-Factor" className="py-12 bg-gray-100">
          <div className="container mx-auto">
            <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
              <UserSideBar />
              <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
                <div id="tabContentExample">
                  <div
                    className=""
                    id="profile-example"
                    role="tabpanel"
                    aria-labelledby="profile-tab-example"
                  >
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-6 gap-4">
                        <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                          <div className="flex flex-col pt-4">
                            <label
                              htmlFor="name"
                              className="text-lg font-normal"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              value={firstName || ""}
                              name="firstName"
                              onChange={onInputChange}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                          <div className="flex flex-col pt-4">
                            <label
                              htmlFor="name"
                              className="text-lg font-normal"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              value={lastName || ""}
                              name="lastName"
                              onChange={onInputChange}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:gap-2">
                        <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                          <div className="flex flex-col pt-4">
                            <label
                              htmlFor="password"
                              className="text-lg font-normal"
                            >
                              Current password (leave blank to leave unchanged)*
                            </label>
                            <input
                              type="password"
                              id="password"
                              name="password"
                              onChange={onInputChange}
                              autoComplete="new-password"
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                          <div className="flex flex-col pt-4">
                            <label
                              htmlFor="confirm-password"
                              className="text-lg font-normal"
                            >
                              New password (leave blank to leave unchanged)*
                            </label>
                            <input
                              type="password"
                              id="newPassword"
                              name="newPassword"
                              onChange={onInputChange}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                            <div style={{ color: "red" }}>
                              {passwordValidationMessage}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3">
                          <div className="flex flex-col pt-4">
                            <label
                              htmlFor="confirm-password"
                              className="text-lg font-normal"
                            >
                              Confirm new password*
                            </label>
                            <input
                              type="password"
                              id="confirmNewPassword"
                              name="confirmNewPassword"
                              onChange={onInputChange}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        </div>
                      </div>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <input
                          type="submit"
                          defaultValue="Save"
                          className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 max-w-xs rounded-full m-auto w-full"
                        />
                      )}
                    </form>
                  </div>
                  <div
                    className="hidden p-4"
                    id="dashboard-example"
                    role="tabpanel"
                    aria-labelledby="dashboard-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Dashboard tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                  <div
                    className="hidden p-4"
                    id="settings-example"
                    role="tabpanel"
                    aria-labelledby="settings-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Settings tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                  <div
                    className="hidden p-4"
                    id="contacts-example"
                    role="tabpanel"
                    aria-labelledby="contacts-tab-example"
                  >
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This is some placeholder content the{" "}
                      <strong className="font-medium text-gray-800 dark:text-white">
                        Contacts tab's associated content
                      </strong>
                      . Clicking another tab will toggle the visibility of this
                      one for the next. The tab JavaScript swaps classes to
                      control the content visibility and styling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default EditAccount;
