import React, { useEffect, useState } from "react";
import { Spinner, UserSideBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPendingRent } from "../redux/features/rentSlice";
import ClaimRents from "../components/ClaimRents";
import { CheckBox } from "@mui/icons-material";
import { formatNumber } from "../utils/Formatter";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { claimUserRent } from "../redux/api";
import { toast } from "react-toastify";


const ClaimRent = () => {
 
  const dispatch = useDispatch();
  useEffect(() => {
    var Id = JSON.parse(window.localStorage.getItem("profile")).id;
    dispatch(getPendingRent());
  }, [dispatch]);
  const [open, setOpen] = useState(false);
  const [isClaimChecked, setisClaimChecked] = useState(false);
  const [totalRent, setTotalRent] = useState(0.00);
  const [loader, setLoader] = useState(false);
  const [payoutChoice, setPayoutChoice] = useState(false);
  const [payoutNetwork, setPayoutNetwork] = useState("");
  const [claimIds, setclaimIds] = useState([]);
  
  const handleChange = (e) => {
    setisClaimChecked(false);
    let addonCheckboxes = document.querySelectorAll(".rent-checkbox");
    let total = 0;
    for (let i = 0; i < addonCheckboxes.length; ++i)
      if (addonCheckboxes[i].checked == true){
        total += parseFloat(addonCheckboxes[i].value);
        setisClaimChecked(true);
      }
    setTotalRent(total.toFixed(6));
  };

  const handleClose = () => {
    setLoader(true);
    setOpen(false);
    setPayoutChoice(false);
    setTotalRent(0.00);
  };

  const payoutChain = (e) => {
    let addonCheckboxes = document.querySelectorAll(".payoutNetwork");
    for (let i = 0; i < addonCheckboxes.length; ++i)
      if (addonCheckboxes[i].checked == true) {
        setPayoutChoice(true);
        setPayoutNetwork(e?.target?.value);
      }
  };

  const choicePayoutChain =(e) =>{     
    setOpen(true);
    setLoader(false);
  };
  const claimMyRent = () => {
    setclaimIds([]);
    let addonCheckboxes = document.querySelectorAll(".rent-checkbox");
    for (let i = 0; i < addonCheckboxes.length; ++i)
      if (addonCheckboxes[i].checked == true)
        claimIds.push(addonCheckboxes[i].id);
    let formData = { ids: claimIds, payoutNetwork: payoutNetwork };

    claimUserRent(formData)
      .then((res) => {
        toast.success(res?.data?.message);
        setTotalRent(0.00);
        setPayoutChoice(false);
        setisClaimChecked(false);
        setPayoutNetwork("");
        dispatch(getPendingRent());
      }).catch((error) => {
        toast.error(error?.data?.message);
      });
    setOpen(false);
    setLoader(true);
  };

  const { userPendingRent, loading } = useSelector((state) => state.rent);
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <Dialog
          open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Kindly select rent payout blockchain network"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="flex-Wrap">
              <section
                dangerouslySetInnerHTML={{ __html: "" }}
                className="SearchResult-body"

              />

              <div className="popBox">
                <h3>Please verify how you want to receive your <em>rent</em>.</h3> <br></br>

                <div>
                  <div className="radioBox">
                    <input type="radio" name="payoutBlockchainNetwork" value={"Polygon"} className="payoutNetwork" onChange={(e) => payoutChain(e)}></input> &nbsp;
                    <span>Receive your rent on <strong>Polygon Chain (Recommended)</strong></span>
                    <span> Delivery to your wallet is fast and free.  <span>Spacios</span>
                      pays all transaction fees.</span></div>

                  <div className="radioBox">
                    <input type="radio" name="payoutBlockchainNetwork" value={"Ethereum"} className="payoutNetwork" onChange={(e) => payoutChain(e)}></input> &nbsp;
                    <span>Claim your rent on <strong>Ethereum
                      <span>(NOT Recommended!)</span></strong> </span>
                    <span>Tokens must be claimed from a Vault.
                      <span>You</span> pay all transaction fees.</span>
                  </div></div> <br></br>

                <p className="dialougBox">When you &apos; ve made your selection, click below:</p>
                <div className="mt-3 btnBox"> {payoutChoice ? (<>
                  <button className="rounded-full  bg-blue-900 text-white view px-8 py-2" onClick={claimMyRent}>Claim My Rent </button>
                </>) : (<button className="rounded-full bg-blue-400 text-white view px-8 py-2" disabled>Claim My Rent</button>)}
                <span style={{ padding: "10px" }}><button className="rounded-full bg-gray-800 text-white view px-8 py-2"
                  onClick={handleClose}>Cancle</button> </span>
                </div>

              </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>

      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                My <strong>Documents</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />

            <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">

              <div id="tabContentExample">
                <div className="table-layout tableBox">
                  <table className="table-fixed w-full">
                    <thead>
                      <tr className="bg-blue-900">
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Rent of Month 
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Rent Status
                        </th>
                        <th className="text-white 1xl:text-md xl:text:md text-md p-3">
                          Net Generated Rent US$
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3">
                          Action
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {userPendingRent.length === 0 && <h1>No Orders found</h1>}

                      {userPendingRent && userPendingRent.map((item) => (
                        <tr className="bg-white">

                          <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
                            {moment(item?.rentDate).format("MMMM Do YYYY")}
                          </td>
                          <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
                            {!item?.isRentClaimed ? "Rent Generated" : "Claim Pending"}
                          </td>
                          <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
                            {formatNumber(item?.userNetMonthlyRent,6)}
                          </td>
                          <td colSpan={5} className="border text-center 2xl:text-lg xl:text:md text-md p-3" >
                            <input type="checkbox" id={item?.id} className="rent-checkbox"
                              value={item?.userNetMonthlyRent} onChange={(e) => handleChange(e)}></input>
                          </td>
                        </tr>
                      ))}

                      <tr className="bg-white">

                        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3" colSpan={2}> </td>

                        <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
                          Total Amount $ : <span> {totalRent} </span>
                        </td>
                        <td colSpan={4} className="border text-center 2xl:text-lg xl:text:md text-md p-3" >
                          { isClaimChecked ? (
                            <button className="rounded-full bg-blue-900 text-white view px-8 py-2" onClick={(e) =>choicePayoutChain(e)} > Claim Rent </button>
                          ) : (<button className="rounded-full bg-blue-400 text-white view px-8 py-2"> Claim Rent </button>)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClaimRent;
