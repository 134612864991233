import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../redux/features/authSlice";
import { Spinner } from "../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  IResolveParams,
} from "reactjs-social-login";

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const Login = () => {
  const [provider, setProvider] = useState("");
  const [socialProfile, setSocialProfile] = useState("");

  let { loading, error } = useSelector((state) => ({ ...state.auth }));
  let navigate = useNavigate();
  const {
    register: _register,
    formState: { errors },
    handleSubmit: _handleSubmitData,
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();

  const profile = localStorage.getItem("profile");
  let parsedata = JSON.parse(profile);

  useEffect(() => {
    if (parsedata?.role == parseInt(2)) {
      navigate(`/myaccount`);
    } else if (parsedata?.role == parseInt(1)) {
      navigate(`/admin`);
    }
  }, [profile]);

  useEffect(() => {
    if (socialProfile) {
      let isSocial = true;
      socialProfile.provider = provider;
      let data = socialProfile;
      dispatch(login({ data, navigate, toast, isSocial }));
    }
  }, [socialProfile]);

  const handleSubmit = (data) => {
    dispatch(login({ data, navigate, toast }));
  };

  return (
    <>
      <section id="register-form">
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-center bg-gray-100">
            <div className="centered-div">
              <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-2 lg:px-24">
                <h2 className="text-center text-4xl font-bold text-blue-800 mb-5">
                  Log<span className="font-normal ml-2">In</span>
                </h2>
                <form
                  className="flex flex-col pt-3 md:pt-8 p-8 bg-gray-100 mt-5"
                  onSubmit={_handleSubmitData(handleSubmit)}
                >
                  <div className="flex flex-col pt-4">
                    <label htmlFor="email" className="text-lg font-normal">
                      Email<span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                      {..._register("email")}
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                    <p className="text-sm text-red">{errors.email?.message}</p>
                  </div>
                  <div className="flex flex-col pt-4">
                    <label htmlFor="password" className="text-lg font-normal">
                      Password <span className="text-red">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      {..._register("password")}
                      placeholder="Password"
                      className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                    />
                    <p className="text-sm text-red">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="inline-flex flex-row items-center pt-4 pt-4">
                    <Link className="ml-2 text-blue-800" to="/forgotpassword">
                      Forgot Password ?
                    </Link>
                  </div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="bg-blue-800 text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 max-w-xs rounded-full m-auto w-full"
                    />
                  )}
                </form>
                <div
                  className="text-center"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID}
                    typeResponse="idToken"
                    isOnlyGetToken="true"
                    onResolve={({ provider, data }: IResolveParams) => {
                      setProvider(provider);
                      setSocialProfile(data);
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <button type="button" className="social-login">
                      <i
                        className="fab fa-google"
                        style={{
                          background:
                            "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
                          WebkitBackgroundClip: "text",
                          backgroundClip: "text",
                          color: "transparent",
                          WebkitTextFillColor: "transparent",
                          "&:hover": {
                            background: "#e9e9e9",
                          },
                        }}
                      ></i>
                    </button>
                  </LoginSocialGoogle>
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID}
                    isOnlyGetToken="true"
                    scope="email,public_profile"
                    redirect_uri={process.env.REACT_APP_WEBSITE_URL}
                    onResolve={({ provider, data }: IResolveParams) => {
                      setProvider(provider);
                      setSocialProfile(data);
                      console.log(data);
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <button type="button" className="social-login">
                      <i
                        class="fab fa-facebook"
                        style={{ color: "#4267B2" }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  </LoginSocialFacebook>
                </div>
                <div className="text-center pt-8">
                  <p>
                    Don't have an account?{"  "}
                    {"  "}
                    <Link
                      to="/register"
                      className="underline font-semibold text-blue-800"
                      style={{ marginLeft: ".5rem" }}
                    >
                      Register here.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-1/2 shadow-2xl relative bg-black">
            <img
              className="object-cover w-full min-h-screen hidden lg:block xl:block  h-full"
              src="assets/images/bg.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
