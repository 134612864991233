import React, { useEffect } from "react";
import { Spinner, UserSideBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getOrderById } from "../redux/features/orderSlice";
import { formatPercentage, formatNumber } from "../utils/Formatter";
import moment from "moment";

const OrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderById(id));
  }, [dispatch, id]);

  const {
    order: { getOrder, userData },
    loading,
  } = useSelector((state) => state.order);
  let orderdetails = getOrder?.[0].order;
  // let orderdetails = getOrder?.[0]?.orderId;
  let userdetails = userData?.[0];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <section id="inner-banner" className="bg-gray-700 h-132">
        <div className="container mx-auto">
          <div className="inner-flex">
            <div className="left-content 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-12 flex items-center h-96">
              <h2 className="2xl:text-6xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-lg text-lg font-regular text-white mb-5">
                My <strong>Account</strong>{" "}
              </h2>
            </div>
            <div className="right-image">
              <img
                src="assets/images/banner2.png"
                alt="banner"
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />
            <div className="2xl:w-4/5 xl:w-4/5 lg:w-2/3 md:w-3/5 sm-w-full">
              <div id="tabContentExample">
                <div className="order-details">
                  Order #{orderdetails?.id} was placed on{" "}
                  <mark>
                    {" "}
                    {moment(orderdetails?.date).format("MMMM Do YYYY")}{" "}
                  </mark>{" "}
                  and is currently
                  <mark> #{orderdetails?.paymentStatus} </mark>
                  Dispense .
                </div>
                <div className="table-layout">
                  <h2 className="2xl:text-4xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-regular text-black mb-5 mt-5 font-bold">
                    Order details{" "}
                  </h2>
                  <table className="table-fixed w-full">
                    <thead>
                      <tr className="bg-blue-900">
                        <th className="text-left text-white 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Product
                        </th>
                        <th className="text-white 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getOrder?.length === 0 && <h1>No Orders found</h1>}

                      {getOrder &&
                        getOrder?.map((item) => (
                          <>
                            <tr className="bg-white">
                              <td className="text-left border 2xl:text-lg xl:text:md text-md p-3">
                                <Link
                                  className="product-id text-blue-900 underline"
                                  to={`/propertydetails/${item?.id}`}
                                >
                                  {item?.Order_Details?.PropertyAddress}
                                </Link>{" "}
                                *{" "}
                                <span className="font-bold">
                                  {formatNumber(item?.propertyToken)}
                                </span>
                              </td>
                              <td className="border text-center 2xl:text-lg xl:text:md text-md p-3">
                                $
                                {formatNumber(
                                  item?.perTokenPrice * item?.propertyToken
                                )}{" "}
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                    <tfoot>
                      {/* <tr className="bg-white">
                        <th className="text-left border text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Plateform Fee
                        </th>
                        <th className=" border text-center text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          {formatNumber(orderdetails?.plateFormFee)}%
                        </th>
                      </tr> */}
                      <tr className="bg-white">
                        <th className="text-left border text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Subtotal
                        </th>
                        <th className=" border text-center text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          $ {formatNumber(orderdetails?.totalAmount)}{" "}
                        </th>
                      </tr>
                      <tr className="bg-white">
                        <th className="text-left border text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Payment method
                        </th>
                        <th className=" border text-center text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          {orderdetails?.paymentMethod}
                        </th>
                      </tr>
                      <tr className="bg-white">
                        <th className="text-left border text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          blockchain Network
                        </th>
                        <th className=" border text-center text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          <span translate="no"> {orderdetails?.networkChain} </span>
                        </th>
                      </tr>
                      <tr className="bg-white">
                        <th className="text-left border text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          Total:
                        </th>
                        <th className=" border text-center text-black 2xl:text-lg xl:text:md text-md p-3 font-bold">
                          ${formatNumber(orderdetails?.totalAmount)}{" "}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="info-delivery">
                  <h2 className="2xl:text-4xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-regular text-black mb-0 mt-5 font-bold">
                    Metamask Transaction Hash
                  </h2>
                  <p className="m-0 font-normal 2xl:text-xl text-gray-500 text-justify m-0">
                    Metamask Transaction Reference #{" "}
                    {orderdetails?.transactionId}
                  </p>
                </div>
                <div className="info-delivery-address mt-10">
                  <h2 className="2xl:text-4xl lg:text-2xl xl:text-2xl md:text-xl sm:text-lg text-lg font-regular text-black mb-0 mt-5 font-bold">
                    Billing address
                  </h2>
                  <div className="card border-0 bg-white p-4 w-full max-w-screen-sm mt-5 border">
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0">
                      {userdetails?.firstName}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0">
                      {userdetails?.lastName}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0">
                      {userdetails?.billingStreet}{" "}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0">
                      {userdetails?.billingCity}, {userdetails?.billingState}{" "}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0">
                      {userdetails?.billingPostalCode}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0 flex">
                      <svg
                        className="w-7"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        enableBackground="new 0 0 100 100"
                        xmlSpace="preserve"
                      >
                        <path d="M77.7,63.9l-6.2-5c-2.1-1.7-5.1-1.801-7.3-0.2L58.3,63c-0.8,0.6-1.899,0.5-2.6-0.2L46,54l-8.9-9.8  c-0.7-0.7-0.8-1.8-0.2-2.6l4.3-5.9c1.6-2.2,1.5-5.2-0.2-7.3l-5-6.2c-2.2-2.8-6.4-3-8.9-0.5l-5.4,5.4c-1.2,1.2-1.8,2.9-1.8,4.5  c0.7,12.7,6.5,24.8,15,33.3s20.5,14.3,33.3,15c1.7,0.1,3.3-0.601,4.5-1.801L78.1,72.7C80.8,70.3,80.5,66.1,77.7,63.9z" />
                      </svg>
                      {userdetails?.phone}{" "}
                    </p>
                    <p className="m-0 font-normal 2xl:text-lg text-gray-500 text-justify m-0 flex">
                      <svg
                        className="w-5 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 330.001 330.001"
                        style={{ enableBackground: "new 0 0 330.001 330.001" }}
                        xmlSpace="preserve"
                      >
                        <g id="XMLID_348_">
                          <path
                            id="XMLID_350_"
                            d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602   L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                          />
                          <polygon
                            id="XMLID_351_"
                            points="165.001,146.4 310.087,40.001 19.911,40  "
                          />
                        </g>
                      </svg>
                      {userdetails?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetails;
