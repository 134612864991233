import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { UserSideBar } from "../components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { me, IdUser } from "../redux/features/authSlice";
import { Spinner } from "../components/";
import { getAllCountry } from "../redux/api";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SumsubWebSdk from "@sumsub/websdk-react";
import { sumSubAccessToken } from "../redux/api";

const IdentityVerification = () => {
  const [phoneCodeList, setphoneCodeList] = useState("");
  const [loadKYC, setLoadKYC] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    country: "",
    billingCity: "",
    billingStreet: "",
    billingState: "",
    phone: "",
    billingPostalCode: "",
    comment: "",
    phoneCode: "",
  });
  const [accessToken, setAccessToken] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, loading, error } = useSelector((state) => ({ ...state.auth }));

  useEffect(() => {
    if (loadKYC) {
      sumSubAccessToken()
        .then((res) => {
          setAccessToken(res.data.accessToken);
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          console.log(error);
        });
    }
  }, [loadKYC]);

  useEffect(() => {
    dispatch(me());
    getAllCountry().then((res) => {
      let phoneCodeList = res.data.allCountry.map(({ phoneCode, iso3 }) => (
        <option value={phoneCode}>
          {" "}
          {iso3} +{phoneCode}{" "}
        </option>
      ));
      setphoneCodeList(phoneCodeList);
    });
  }, [dispatch]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    setFormValue({ ...user });
  }, [user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...formValue },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required."),
      lastName: Yup.string().required("This field is required."),
      email: Yup.string()
        .email("Invalid email address")
        .required("This field is required."),
      dob: Yup.string().required("This field is required.").nullable(),
      country: Yup.string().required("Required.").nullable(),
      billingCity: Yup.string().required("This field is required.").nullable(),
      billingStreet: Yup.string()
        .required("This field is required.")
        .nullable(),
      billingState: Yup.string().required("This field is required.").nullable(),
      phone: Yup.string().required("This field is required.").nullable(),
      billingPostalCode: Yup.string()
        .required("This field is required.")
        .nullable(),
      phoneCode: Yup.string().required("This field is required.").nullable(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      for (var key in values) {
        formData.append(key, values[key]);
      }
      dispatch(IdUser({ formData, navigate, toast }));
      setLoadKYC(true);
    },
  });

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <section id="myaccount-Factor" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col">
            <UserSideBar />
            <div id="tabContentExample">
              <div
                className="hidden p-4"
                id="profile-example"
                role="tabpanel"
                aria-labelledby="profile-tab-example"
              ></div>
              <div
                className="hidden p-4"
                id="dashboard-example"
                role="tabpanel"
                aria-labelledby="dashboard-tab-example"
              >
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the{" "}
                  <strong className="font-medium text-gray-800 dark:text-white">
                    Dashboard tab's associated content
                  </strong>
                  . Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the
                  content visibility and styling.
                </p>
              </div>
              <div
                className="p-4"
                id="settings-example"
                role="tabpanel"
                aria-labelledby="settings-tab-example"
              >
                <form onSubmit={formik.handleSubmit}>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Identify
                    <span className="text-blue-700 font-bold">
                      {" "}
                      Verification
                    </span>
                  </h2>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-2 mb-0">
                    <span translate="no"> SPACIOS </span> platform, requires
                    verifying your identity. As part of international compliance
                    standards.
                  </p>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0">
                    Please make sure your verification goes as smoothly as
                    possible by accessing this form from an IP address in your
                    country of residence.
                  </p>
                  <p className="2xl:text-xl text-black dark:text-gray-400 mt-2 mb-12">
                    Thank you!
                  </p>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Personal
                    <span className="text-blue-700 font-bold">
                      {" "}
                      information
                    </span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="FirstName"
                          name="firstName"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="text-red-600">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="LastName"
                          name="lastName"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-red-600">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Birth Date
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Use month-day-year format
                        </p>
                        <input
                          type="date"
                          id="Dob"
                          name="dob"
                          max={moment().format("YYYY-MM-DD")}
                          onChange={formik.handleChange}
                          value={formik.values.dob}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="text-red-600">
                            {formik.errors.dob}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Email
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please enter the valid email
                        </p>
                        <input
                          type="email"
                          id="Email"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                          disabled
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-600">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Phone
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please include your phone
                        </p>
                        <div style={{ display: "flex" }}>
                          <select
                            name="phoneCode"
                            defaultValue={formik.values.phoneCode}
                            id="phoneCode"
                            onChange={formik.handleChange}
                            value={formik.values.phoneCode}
                            className="py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            style={{ width: "55%" }}
                          >
                            {phoneCodeList}
                          </select>{" "}
                          &nbsp;
                          <input
                            type="text"
                            id="Phone"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-red-600">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    Billing
                    <span className="text-blue-700 font-bold"> Address</span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Country
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please enter your billing country
                        </p>
                        <input
                          type="text"
                          id="country"
                          name="country"
                          onChange={formik.handleChange}
                          value={formik.values.country}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.country && formik.errors.country ? (
                          <div className="text-red-600">
                            {formik.errors.country}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          Street
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Numbre, street name
                        </p>
                        <input
                          type="text"
                          id="BillingStreet"
                          name="billingStreet"
                          onChange={formik.handleChange}
                          value={formik.values.billingStreet}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.billingStreet &&
                        formik.errors.billingStreet ? (
                          <div className="text-red-600">
                            {formik.errors.billingStreet}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          City
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Full name of the city{" "}
                        </p>
                        <input
                          type="text"
                          id="BillingCity"
                          name="billingCity"
                          onChange={formik.handleChange}
                          value={formik.values.billingCity}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.billingCity &&
                        formik.errors.billingCity ? (
                          <div className="text-red-600">
                            {formik.errors.billingCity}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          State/Region
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please selct your region
                        </p>
                        <input
                          type="text"
                          id="BillingState"
                          name="billingState"
                          onChange={formik.handleChange}
                          value={formik.values.billingState}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.billingState &&
                        formik.errors.billingState ? (
                          <div className="text-red-600">
                            {formik.errors.billingState}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-2">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          ZIP/Postal Code
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Your postal code, if you have
                        </p>
                        <input
                          type="text"
                          id="BillingPostalCode"
                          name="billingPostalCode"
                          onChange={formik.handleChange}
                          value={formik.values.billingPostalCode}
                          className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                        />
                        {formik.touched.billingPostalCode &&
                        formik.errors.billingPostalCode ? (
                          <div className="text-red-600">
                            {formik.errors.billingPostalCode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl font-light text-2xl mt-5 text-blue-700">
                    <span className="text-blue-700 font-bold">
                      {" "}
                      Identification
                    </span>
                  </h2>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                      <div className="flex flex-col pt-4">
                        <label htmlFor="name" className="text-lg font-normal">
                          What country issued your ID?
                        </label>
                        <p className="text-blue-800 font-regular text-md">
                          Please selct the country that issued your ID.
                        </p>
                        <select
                          className="form-select appearance-none
                              block
                              w-full
                              px-3
                              py-1.5
                              text-base
                              font-normal
                              text-gray-700
                              bg-white bg-clip-padding bg-no-repeat
                              mt-3
                              h-10
                              transition
                              ease-in-out
                              m-0
                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          aria-label="Default select example"
                          id="IdentificationCountry"
                          name="identificationCountry"
                          onChange={onInputChange}
                          value={identificationCountry || ""}
                        >
                          <option value="DEFAULT">Open this select menu</option>
                          <option value="Argentina">Argentina</option>
                          <option value="India">India</option>
                          <option value="Chile">Chile</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      type="submit"
                      style={{ float: "right", "margin-top": "30px" }}
                      variant="contained"
                    >
                      Next
                    </Button>
                  )}

                  {/* <div className="flex mt-12 gap-5 items-start lg:flex-nowrap flex-wrap">
                    <div className="lg:w-1/2 w-full">
                      <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                        <img
                          className="w-72"
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : "assets/images/file-upload.png"
                          }
                          alt=""
                        />
                        <input
                          type="file"
                          className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                          name="image"
                          accept="image/png , image/jpeg, image/webp"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        Selfie Image
                      </p>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        JPG/PNG
                      </p>
                      <p className="2xl:text-xl text-black dark:text-gray-400 mt-0 mb-0 text-center">
                        click to add
                      </p>
                    </div>
                    <div className="lg:w-1/2 w-full border flex-col border-gray-500 px-3 py-5 flex items-start justify-center relative h-auto">
                      <h2 className="2xl:text-xl xl:text-xl lg:text-xl md:text-xl sm:text-xl font-semibold text-2xl text-black mt-0 text-center w-full">
                        Please select a form of ID:
                      </h2>
                      <div className="flex justify-center items-center w-full mt-5">
                        <ul className=" w-96 text-gray-900">
                          <li
                            onClick={handlePass}
                            className="font-bold px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Passport
                          </li>
                          <li
                            onClick={handleDrive}
                            className="font-bold px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Driver´s Licence
                          </li>
                          <li
                            onClick={handleOther}
                            className="font-bold  px-6 py-2 border-b border-blue-200 hover:bg-blue-700 cursor-pointer w-full mb-5 bg-blue-800 rounded-full text-center text-gray-200"
                          >
                            Other ID{" "}
                          </li>
                        </ul>
                      </div>
                      <>
                        {show ? (
                          <div className="passport-doc upload-doc-item">
                            <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                              <img
                                src={
                                  front
                                    ? URL.createObjectURL(front)
                                    : "assets/images/file-upload.png"
                                }
                                className="w-72"
                                alt=""
                              />
                              <input
                                type="file"
                                className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                name="passport"
                                onChange={(e) => setFront(e.target.files[0])}
                              />
                            </div>
                            <div className="text-center">
                              <h5 className="font-black">
                                PASSPORT (LOWER PORTION)
                              </h5>
                              <span className="italic text:2xl text-center w-100 block">
                                JPG/PNG
                              </span>
                              <p className="text-blue-800 font-regular text-md">
                                Your Passport Number
                              </p>
                              <input
                                type="text"
                                id="PassportNumber"
                                name="passportNumber"
                                onChange={onInputChange}
                                value={passportNumber || ""}
                                className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                              />
                            </div>
                          </div>
                        ) : null}

                        {driv ? (
                          <div className="driver-doc upload-doc-item">
                            <div className="flex">
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      front
                                        ? URL.createObjectURL(front)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="driving_front"
                                    onChange={(e) =>
                                      setFront(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">
                                    DRIVER'S LICENSE FRONT
                                  </h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      back
                                        ? URL.createObjectURL(back)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="driving_back"
                                    onChange={(e) => setBack(e.target.files[0])}
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">
                                    DRIVER'S LICENSE BACK
                                  </h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p className="text-blue-800 font-regular text-md">
                              YOUR DRIVER'S LICENSE NUMBER
                            </p>
                            <input
                              type="text"
                              id="DrivingLicence"
                              name="drivingLicence"
                              onChange={onInputChange}
                              value={drivingLicence || ""}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        ) : null}
                        {other ? (
                          <div className="other-doc upload-doc-item">
                            <div className="flex">
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      front
                                        ? URL.createObjectURL(front)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="other_front"
                                    onChange={(e) =>
                                      setFront(e.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">ID FRONT</h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                              <div className="m-2">
                                <div className="w-full border border-gray-500 px-3 py-10 flex items-center justify-center relative mb-5">
                                  <img
                                    src={
                                      back
                                        ? URL.createObjectURL(back)
                                        : "assets/images/file-upload.png"
                                    }
                                    className="w-72"
                                    alt=""
                                  />
                                  <input
                                    type="file"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 z-50"
                                    name="other_back"
                                    onChange={(e) => setBack(e.target.files[0])}
                                  />
                                </div>
                                <div className="text-center">
                                  <h5 className="font-black">ID BACK</h5>
                                  <span className="italic text:2xl text-center w-100 block">
                                    JPG/PNG
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p className="text-blue-800 font-regular text-md">
                              Your Id Number
                            </p>
                            <input
                              type="text"
                              id="OtherIdNumber"
                              name="otherIdNumber"
                              onChange={onInputChange}
                              value={otherIdNumber || ""}
                              className="appearance-none   w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline h-10"
                            />
                          </div>
                        ) : null}
                      </>
                    </div>
                  </div> */}
                </form>
                <div>
                  {loadKYC && accessToken && (
                    <SumsubWebSdk
                      accessToken={accessToken}
                      updateAccessToken={() => console.log("updateAccessToken")}
                      expirationHandler={() => Promise.resolve(accessToken)}
                      config={{
                        lang: "ru-RU",
                        email: user.email,
                        phone: user.phone,
                        i18n: {
                          document: {
                            subTitles: {
                              IDENTITY:
                                "Upload a document that proves your identity",
                            },
                          },
                        },
                        onMessage: (type, payload) => {
                          console.log("WebSDK onMessage", type, payload);
                        },
                        uiConf: {
                          customCssStr:
                            ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",
                        },
                        onError: (error) => {
                          console.error("WebSDK onError", error);
                        },
                      }}
                      options={{
                        addViewportTag: false,
                        adaptIframeHeight: true,
                      }}
                      onMessage={(type, payload) => {
                        console.log("onMessage", type, payload);
                      }}
                      onError={(data) => console.log("onError", data)}
                    />
                  )}
                </div>
                {/* {comment ? (
                  <h3
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: 24,
                      marginTop: 100,
                    }}
                  >
                    Comment from the admin
                  </h3>
                ) : (
                  ""
                )}
                <p style={{ color: "blue", fontWeight: "bold" }}>{comment}</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default IdentityVerification;
