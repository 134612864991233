import React from "react";
import SideBar from "../SideBar/SideBar";
import { Outlet } from "react-router-dom";

const BlockChainTabs = () => {
  return (
    <>
      <section id="blockchain-main">
        <div className=" mx-auto">
          <div className="section-content flex md:flex-row sm:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col">
            <div className="2xl:w-1/4 xl:w-1/4 w-32 lg:w-1/4 md:w-1sm:w-full w-full bg-grey-cs">
              <div className="left-side-custom">
                <div className="mar-left-auto">
                  <a href="/#">
                    Welcome to <span translate="no"> SPACIOS </span> wiki
                  </a>
                </div>
                <SideBar />
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};
export default BlockChainTabs;
