import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../components/";
import { Link } from "react-router-dom";
import { fetchSocial } from "../../../redux/features/socialSlice";
import { fetchFaq } from "../../../redux/features/faqSlice";

const SideBar = () => {
  const { socials, loading } = useSelector((state) => ({
    ...state.social,
  }));

  const { faqs } = useSelector((state) => ({
    ...state.faq,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSocial());
    dispatch(fetchFaq());
  }, [dispatch]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <ul className="ul-left-side">
        {faqs.map((curElm, i) => {
          const { id, question } = curElm;
          return (
            <>
              <li key={i}>
                <Link to={`./${id}`}>{`${question}`}</Link>
              </li>
            </>
          );
        })}
        <li>
          <h2 className="official-link">Official Links</h2>
        </li>
        {socials.length === 0 && <h1>No social link found</h1>}

        {socials &&
          socials.map((social) => (
            <>
              <li className="social fb">
                <a href={social.Link}>{social.Title}</a>
              </li>
            </>
          ))}
      </ul>
    </>
  );
};

export default SideBar;
