import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFee, editFee } from "../../redux/features/feeSlice";
import { Spinner } from "../../components";
const initialState = {
  feeName: "",
  percent: "",
};

const NewFee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { feeId } = useParams();
  const [feeData, setFeeData] = useState(initialState);
  const { error, loading, fees } = useSelector((state) => ({ ...state.fee }));

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFeeData({ ...feeData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = { ...feeData };
    if (feeId) {
      dispatch(editFee({ feeId, formdata, navigate, toast }));
    } else {
      dispatch(createFee({ formdata, navigate, toast, dispatch }));
    }
  };

  useEffect(() => {
    let singleFee;
    if (feeId) {
      singleFee = fees?.find((fee) => parseInt(fee.id) === parseInt(feeId));

      setFeeData({ ...singleFee });
    }
  }, [feeId, fees]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />

          <div></div>

          <div className="bottom">
            <div className="right editprofile">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label>Type of Fee</label>
                  <input
                    type="text"
                    name="feeName"
                    id="feeName"
                    onChange={onInputChange}
                    value={feeData.feeName} readOnly
                  />
                </div>

                <div className="formInput">
                  <label>Percent</label>
                  <input
                    type="text"
                    name="percent"
                    id="percent"
                    onChange={onInputChange}
                    value={feeData.percent}
                  />
                </div>
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="btnsubmit">Update</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewFee;
