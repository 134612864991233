import React, { useState } from "react";

const TeamModel = ({ imageFile, description, name, profile }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="2xl:w-1/3 xl:w-1/3 xl:w-1/3 md:w-1/3 sm:w-1/2 w-full p-2 mb-4">
        <div className="card team-item p-0 shadow bg-white overflow-hidden">
          <div className="team-image">
            <img src={imageFile} className="w-full" alt="" />
          </div>
          <div className="team-content1 p-4 text-center">
            <h2 className="font-bold 2xl:text-2xl xl:text-2xl lg:text-lg md:text-lg mt-0">
              <span translate="no"> {name} </span>
            </h2>
            <p className="font-normal 2xl:text-xl lg:text-xl md:text-lg sm:text-lg text-sm">
              {profile}
            </p>
            <button
              type="button"
              className="px-2 py-1 bg-blue-800 text-white font-medium text-xs leading-tight 2xl:text-1xl lg:text-2xl uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full max-w-5xl openpopup"
              onClick={() => setIsOpen(true)}
            >
              View More
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className="relative z-10 fade"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-modal">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        <span translate="no" className="text-xl"> {name} </span>
                      </h3>
                      <div className="mt-2">
                        <p className="text-xl text-gray-500 mb-3">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm closemodal"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamModel;
