import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const login = createAsyncThunk(
  "auth/login",
  async ({ data, navigate, toast, isSocial = false }, { rejectWithValue }) => {
    try {
      const response = isSocial
        ? await api.verifySocialLogin(data)
        : await api.signIn(data);
      if (parseInt(response?.data?.role) !== 1) {
        navigate("/myaccount");
      } else {
        navigate("/admin");
      }

      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotpassword = createAsyncThunk(
  "auth/ForgotPassword",
  async ({ formValue, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.ForgotPassword(formValue);
      toast.success("Email Send successfully");
      navigate("/");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ data, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.signUp(data);
      toast.success("An email has been sent to your email id.Please verify");
      navigate("/");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "auth/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUsers();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const me = createAsyncThunk(
  "auth/me",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.me();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "auth/getUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getUser(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteUser(id);
      toast.success("User Deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({ id, formData, toast, navigate, dispatch }, { rejectWithValue }) => {
    try {
      const response = await api.updateUser(formData, id);
      toast.success("User Updated Successfully");
      navigate("/admin/users");
      dispatch(getUsers);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    { userId, tokenUrl, UserData, toast, navigate },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.resetPassword(UserData, userId, tokenUrl);
      toast.success("Password updated succes");
      navigate("/");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const IdUser = createAsyncThunk(
  "auth/IdUser",
  async ({ formData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.IdUser(formData);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editAccount = createAsyncThunk(
  "auth/editAccount",
  async ({ updatedUserData, toast }, { rejectWithValue }) => {
    try {
      const response = await api.editAccount(updatedUserData);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// Get user from localStorage
const user = JSON.parse(localStorage.getItem("profile"));

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: user ? user : null,
    me: null,
    users: [],
    error: "",
    loading: false,
    viewUser: null,
    fee: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setFee: (state, action) => {
      state.fee = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.user = null;
    },
  },
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [me.pending]: (state, action) => {
      state.loading = true;
    },
    [me.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [me.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
      state.user = action.payload;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [forgotpassword.pending]: (state, action) => {
      state.loading = true;
    },
    [forgotpassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [forgotpassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [register.pending]: (state, action) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.viewUser = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.users = state.users.filter((item) => item.id !== id);
      }
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.users = state.users.map((item) =>
          item.id === id ? action.payload : item
        );
      }
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [editAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [editAccount.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [editAccount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [resetPassword.pending]: (state, action) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { setUser, setLogout, setFee, setMe } = authSlice.actions;

export default authSlice.reducer;
