import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { fetchCompletedRent } from "../../redux/features/orderSlice";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "../../pages/list/list.scss";
import "../datatable/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { formatPercentage, formatNumber, formatDate } from "../../utils/Formatter";

const CompletedRent = () => {
  const dispatch = useDispatch();

  const { rentListing } = useSelector((state) => ({
    ...state.order,
  }));

  const RentDistributeColumn = [
    {
      field: "User Name",
      headerName: "User Name",
      width: 150,
      valueGetter: (params) =>
        `${params?.row?.User_Details?.firstName} ${params?.row?.User_Details?.lastName || ""
        }`,
    },
    {
      field: "Wallet Address ",
      headerName: "Wallet Address",
      width: 380,
      valueGetter: (params) => `${params?.row?.User_Details?.walletAddress}`,
    },
    {
      field: "PropertyAddress",
      headerName: "Property Address",
      width: 350,
      valueGetter: (params) =>
        `${params?.row?.Property_Details?.PropertyAddress}`,
    },
    {
      field: "transactionHash",
      headerName: "Transaction Id",
      width: 550,
      valueGetter: (params) => `${params?.row?.transactionHash}`,
    },
    {
      field: "distributed_amount",
      headerName: "Distributed Amount ($USDC)",
      width: 200,
      valueGetter: (params) => `${formatNumber(params?.row?.distributedAmount * 1)}`,
    },
    {
      field: "createdAt",
      headerName: "Calucate Date",
      width: 200,
      valueGetter: (params) => {
        return formatDate(params?.row.createdAt);
      },
    },
    {
      field: "distribution_date",
      headerName: "Distribution Date",
      width: 200,
      valueGetter: (params) => {
        return moment(params?.row.distribution_date).format("MM-DD-YY HH:mm");
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchCompletedRent());
  }, [dispatch]);

  return (
    <>
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />

          <div className="datatable">
            <DataGrid
              className="datagrid"
              disableSelectionOnClick
              rows={rentListing ? rentListing : []}
              getRowId={(row) => row?.id || row?.data?.id}
              columns={RentDistributeColumn}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedRent;
